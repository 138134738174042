import { selectCommonData } from '../selectors';

import { travelCitiesAdapter } from './adapter';

const selectors = travelCitiesAdapter.getSelectors(
  (state: AppState) => selectCommonData(state).travelCities,
);

export const selectTravelCities = (state: AppState) =>
  selectCommonData(state).travelCities;

export const selectTravelCityByAlias =
  (alias: TravelRegionType['alias']) => (state: AppState) =>
    selectors.selectById(state, alias);

export const selectAllTravelCities = (state: AppState) =>
  selectors.selectAll(state);

export const selectTravelCitiesByAliases =
  (aliases: TravelRegionType['alias'][]) => (state: AppState) =>
    aliases.map((alias) => selectTravelCityByAlias(alias)(state));
