import { createAsyncThunk } from '@reduxjs/toolkit';

import { getTagInfo, getTagNewsByProject } from 'api';
import { addManyEntries } from 'common/redux/commonData/entries';
import {
  selectApiConfig,
  selectDomainConfig,
  selectProjectId,
  selectVariables,
} from 'common/redux/runtime/selectors';
import { adaptClusterToCard } from 'utils/adapters';

import { getFilteredClusters } from '../utils';

import { selectTagClustersIds } from './selectors';

type FetchTagInfoPropsType = {
  tagAlias: string;
};

/**
 * Получение информации по ручному тегу.
 * @param props.tagAlias - alias тега.
 */
export const fetchTagInfo = createAsyncThunk(
  'fetchTagInfo',
  async ({ tagAlias }: FetchTagInfoPropsType, { getState }) => {
    const state = getState() as AppState;

    const apiConfig = selectApiConfig(state);

    const { error, data } = await getTagInfo(apiConfig, tagAlias);

    if (error || !data) {
      throw (
        error ||
        new Error(`Ошибка при получении информации по тегу: ${tagAlias}`)
      );
    }

    return data;
  },
);

type FetchTagClustersPropsType = {
  tagAlias: string;
  page: number;
};

/**
 * Получение списка новостей по ручному тегу.
 * @param props.tagAlias - alias тега;
 * @param props.page - номер страницы.
 */
export const fetchTagClusters = createAsyncThunk(
  'fetchTagClusters',
  async (
    { tagAlias, page }: FetchTagClustersPropsType,
    { dispatch, getState },
  ) => {
    const state = getState() as AppState;

    const apiConfig = selectApiConfig(state);
    const domainConfig = selectDomainConfig(state);
    const variables = selectVariables(state);
    const projectId = selectProjectId(state);
    const tagClustersIds = selectTagClustersIds(state);

    const { error, data } = await getTagNewsByProject(
      apiConfig,
      projectId,
      tagAlias,
      page,
    );

    if (error || !data) {
      throw (
        error ||
        new Error(`Ошибка при получении кластеров по тегу: ${tagAlias}`)
      );
    }

    const { pagination, clusters } = data;

    const modifyClusters = clusters?.map((cluster) =>
      adaptClusterToCard({ cluster, domainConfig, variables }),
    );

    const filteredClusters = getFilteredClusters({
      incomingClusters: modifyClusters,
      existingClustersIds: tagClustersIds,
    });

    dispatch(addManyEntries(filteredClusters));

    return {
      clusterIds: filteredClusters.map(({ id }) => id),
      currentPage: pagination.page,
      hasNextPage: Boolean(pagination.nextPage),
    };
  },
);
