import loadable from '@loadable/component';
import React from 'react';

import { setThemePuids } from 'common/redux/pages/theme';
import {
  fetchThemeClusters,
  fetchThemeInfo,
} from 'common/redux/pages/theme/asyncs';
import { selectProjectAlias } from 'common/redux/runtime/selectors';
import { appendPageDownloader } from 'common/routes/pageLoadable';
import { PAGE_TYPE } from 'config/common/router/typings';

import { IAppRoute } from '../typings';

const ThemeDesktop = loadable(() => import('desktop/pages/Theme'));
const ThemeMobile = loadable(() => import('mobile/pages/Theme'));

type ThemeRoutesMatchProps = {
  themeId: string;
  themeInfo: string;
};

/**
 * Роут сюжета.
 * @see http://news.rambler.ru/theme/1010-nezavisimost-katalonii/
 * @param isMobile - флаг мобильной версии
 */
export const themeRoutes = (
  isMobile: SettingsType['isMobile'],
): IAppRoute<ThemeRoutesMatchProps> => ({
  name: PAGE_TYPE.theme,
  exact: true,
  path: '/theme/:themeId(\\d{2,10})\\-:themeInfo?/',
  render: isMobile ? () => <ThemeMobile /> : () => <ThemeDesktop />,
  fetchData: async (
    { dispatch, getState },
    { params: { themeId } },
    queryParams,
  ) => {
    const page = Number(queryParams?.page) || 1;

    const projectAlias = selectProjectAlias(getState());

    dispatch(setThemePuids(projectAlias));

    const promiseList: PromiseListType = [
      dispatch(fetchThemeInfo({ themeId })),
      dispatch(fetchThemeClusters({ themeId, page })),
    ];

    await Promise.all(
      appendPageDownloader({
        promiseList,
        loadableComponent: isMobile ? ThemeMobile : ThemeDesktop,
        chunkName: Object.keys({ ThemeDesktop })[0],
      }),
    );
  },
});
