import { AUTOTAG_TYPE } from 'config/common/autotag/typings';
import { textCroppingByWord } from 'utils/textCroppingByWord';

import { AutotagPageType, AutotagSeo } from './typings';

const MAX_DESCRIPTION_LENGTH = 200;

type GetPageUrlProps = {
  type: AutotagPageType['type'];
  alias: AutotagPageType['alias'];
};

/**
 * Возвращает текущий url страницы для SEO
 * @param props - пропсы
 * @param props.type - тип автотега;
 * @param props.alias - латинизированное имя автотега;
 */
const getPageUrl = ({ type, alias }: GetPageUrlProps) => `/${type}/${alias}/`;

type PropsType = {
  runtime: Runtime;
  firstClusterTitle?: Card['title'];
  secondClusterTitle?: Card['title'];
  apiInfo: ATAutotagInfo;
  type: AutotagPageType['type'];
  alias: AutotagPageType['alias'];
};

/**
 * Возвращает SEO информацию по тегу персон
 * @param props - пропсы
 * @param props.runtime - общая информация по проекту
 * @param props.firstClusterTitle - заголовок первого кластера в списке
 * @param props.secondClusterTitle - заголовок первого кластера в списке
 * @param props.apiInfo - информация об автотеге
 */
const getPersonSeoInfo = (props: PropsType): AutotagSeo => {
  const { runtime, apiInfo, alias, type, firstClusterTitle } = props;
  const displayName = apiInfo?.displayName || '';
  const projectName = runtime.project.name;

  const seoInfo = {
    description: '',
    title: '',
    headline: '',
    keywords: '',
    url: getPageUrl({ alias, type }),
  };

  seoInfo.description = `${displayName} — все последние новости на сегодня, фото и видео на ${projectName}. ${firstClusterTitle}`;
  seoInfo.title = `${displayName} сегодня: свежие новости о персоне ${displayName}`;
  seoInfo.headline = `${displayName}. Главные новости, фото и видео`;

  return seoInfo;
};

/**
 * Возвращает SEO информацию по автотегу
 * @see https://confluence.rambler-co.ru/pages/viewpage.action?pageId=39501809
 * @param props - пропсы
 * @param props.runtime - общая информация по проекту
 * @param props.clusters - массив объектов кластеров
 * @param props.apiInfo - информация об автотеге
 * @param props.pageInfo - объект info страницы автотега
 */
export const getSeoInfo = (props: PropsType) => {
  const {
    runtime,
    firstClusterTitle = '',
    secondClusterTitle = '',
    apiInfo,
    alias,
    type,
  } = props;
  const displayName = apiInfo?.displayName || '';

  let seoInfo = {
    headline: `${displayName} — все новости`,
    description: '',
    title: '',
    keywords: '',
    url: getPageUrl({ alias, type }),
  };
  const defaultDescription = textCroppingByWord({
    str: `${displayName} — ${firstClusterTitle}, ${secondClusterTitle}`,
    maxLength: MAX_DESCRIPTION_LENGTH,
    charEnd: '',
  });
  const currentYear = new Date().getFullYear();

  switch (type) {
    case AUTOTAG_TYPE.person: {
      seoInfo = getPersonSeoInfo(props);
      break;
    }

    case AUTOTAG_TYPE.organization: {
      seoInfo.title = `${displayName} сегодня: свежие новости от ${displayName}`;
      seoInfo.description = `${displayName} — узнай первым последние новости по теме ${displayName} на сайте ${runtime.project.name}`;
      seoInfo.keywords = `${displayName}, новости сегодня'`;
      break;
    }

    case AUTOTAG_TYPE.auto: {
      seoInfo.title = `${displayName} сегодня: свежие новости от ${displayName}`;
      seoInfo.description = `${displayName} — узнай первым последние новости по теме ${displayName} на сайте ${runtime.project.name}`;
      break;
    }

    case AUTOTAG_TYPE.media: {
      seoInfo.title = `${displayName}: последние новости ${currentYear}`;
      seoInfo.description = textCroppingByWord({
        str: `Новости ${displayName} — ${firstClusterTitle}, ${secondClusterTitle}`,
        maxLength: MAX_DESCRIPTION_LENGTH,
        charEnd: '',
      });
      break;
    }

    // Данных для movie автотега нет. Используем дефолтные. По запросу SEO добавим какие-нибудь другие
    case AUTOTAG_TYPE.movie:

    // eslint-disable-next-line no-fallthrough
    default: {
      seoInfo.title = `${displayName}: свежие новости ${currentYear}`;
      seoInfo.description = defaultDescription;
    }
  }

  return seoInfo;
};

/**
 * Группирует отсортированные по ptime кластеры по дням, возвращает массив дней, в каждом дне
 * массив кластеров
 * @param clusters - кластеры для группировки, ОБЯЗАТЕЛЬНО отсортированные по ptime
 */
const groupByDay = (clusters: APICard[] | undefined) => {
  if (!clusters) return [];

  const grouped: APICard[][] = [];

  let lastGroupIndex: number = -1;
  let prevDay: string;

  clusters.forEach((cluster) => {
    const currentDay = new Date(cluster.publication_time).toDateString();

    if (currentDay !== prevDay) {
      lastGroupIndex += 1;
      grouped[lastGroupIndex] = [];
    }

    grouped[lastGroupIndex].push(cluster);
    prevDay = currentDay;
  });

  return grouped;
};

/**
 * Сортирует кластеры по упоминаниям, сначала идут кластера с упоминаниями в заголовке
 * @param clusters - кластера для сортировки, отсортированные по ptime
 */
const sortByMentions = (clusters: APICard[]) => {
  const newClusters = [...clusters];

  newClusters.sort((a, b) => {
    const titleMentionsA = Boolean(a.mentions?.title.length);
    const titleMentionsB = Boolean(b.mentions?.title.length);

    if (titleMentionsA === titleMentionsB) {
      return clusters.indexOf(a) - clusters.indexOf(b);
    }

    if (titleMentionsA) {
      return -1;
    }

    return 1;
  });

  return newClusters;
};

/**
 * Сортировка кластеров по дням и упоминаниям
 * @param clusters кластера для сортировки, отсортированные по ptime
 */
export const sortClusters = (clusters: APICard[] | undefined) => {
  const grouped = groupByDay(clusters);
  const sorted = grouped.map((group) => sortByMentions(group));

  return sorted.reduce((flat, group) => flat.concat(group), []);
};
