import { useSafeMediaQuery } from 'common/hooks/useSafeMediaQuery';

/**
 * Хук для определения высоты изображения на кластере
 * @param maxWidth - ширина вьюпорта
 * @param size - обычная высота
 * @param sizeCompact - компактная высота
 */
export const useImageHeight = (
  maxWidth: number,
  size: number,
  sizeCompact: number,
) => {
  const isCompact = useSafeMediaQuery({ maxWidth });

  return isCompact ? sizeCompact : size;
};
