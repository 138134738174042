import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Video } from 'common/components/VideoDesk';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import {
  selectClusterById,
  selectClusterPuids,
} from 'common/redux/commonData/entries/selectors';
import { selectClusterPageTextScale } from 'common/redux/pages/cluster/selectors';
import { selectTopicByClusterId } from 'common/redux/selectors';
import { CLUSTER_TYPE } from 'config/common/cluster/typings';
import { Image } from 'desktop/pages/Cluster/components/ImageDesk';

import stylesVideo from './components/Video/styles.module.css';

type ArticleMediaSectionPropsType = {
  clusterId: Cluster['id'];
  clusterIndex: number;
  hasGallery?: boolean;
  className?: string;
};

/**
 * Картинка/галерея/видео, в зависимости от типа кластера
 * @param props - пропсы
 * @param props.clusterId - id кластера;
 * @param props.clusterIndex - индекс кластера в бесконечном скролле;
 * @param props.hasGallery - флаг что кластер с галереей;
 * @param props.className - строка с классом для контейнера блока
 * @returns заглавный блок фото/видео кластера
 */
const ArticleMediaSectionComponent = function ArticleMediaSection({
  clusterId,
  clusterIndex,
  hasGallery = false,
  className,
}: ArticleMediaSectionPropsType) {
  const cluster = useSelector(selectClusterById(clusterId), shallowEqual);
  const textScaleValue = useSelector(selectClusterPageTextScale);
  const topic = useSelector(selectTopicByClusterId(clusterId), shallowEqual);
  const puids = useSelector(selectClusterPuids(clusterId), shallowEqual);

  if (cluster?.type === CLUSTER_TYPE.video && cluster?.video?.embedCode) {
    return (
      <Video
        cluster={cluster}
        topic={topic}
        clusterIndex={clusterIndex}
        styles={stylesVideo}
        puids={puids}
        className={className}
      />
    );
  }

  /**
   * Если картинки нет, то рисуем заглушку только для лонгрида.
   * На лонгриде без заглушки все совсем грустно.
   */
  if (!hasGallery && cluster && (cluster.image.url || cluster.isCommercial)) {
    return (
      <Image
        cluster={cluster}
        textScaleValue={textScaleValue}
        noImage={cluster?.displayType === 'digest'}
        className={className}
      />
    );
  }

  return null;
};

export const ArticleMediaSection = withErrorBoundary(
  ArticleMediaSectionComponent,
);
