import cn from 'classnames';
import React, { useSyncExternalStore } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { Link } from 'common/components/Link';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import {
  selectIsMobile,
  selectProjectAlias,
} from 'common/redux/runtime/selectors';
import { WITHOUT_SPA_TOPIC_IDS } from 'config/common/topic/constants';
import { MoreDropdown, ItemType } from 'desktop/components/MoreDropdown';

import { useTopicsMenuReachGoal } from '../metrics/utils';

import s from './styles.module.css';

type MenuLinksPropsType = {
  menuItems: ItemType[];
  top100Value?: string;
  isCompactLineWidth?: boolean;
};

/**
 * Компонент с ссылками
 * @see https://www.figma.com/file/TtlloSQOJ6pFTlQ0Y9r272/Index?node-id=5467%3A35425 – дизайн
 * @param props - пропсы
 * @param props.menuItems - список рубрик вертикали
 * @param props.top100Value - значение для топ100
 * @param props.isCompactLineWidth - флаг чтобы не растягивать линию под меню на всю ширину экрана (используется в путеводителях)
 */
const MenuLinksComponent = function MenuLinks({
  menuItems,
  top100Value,
  isCompactLineWidth,
}: MenuLinksPropsType) {
  const top100AttributeMenu = useTop100AttributeWithValue(top100Value);
  const top100AttributeDefault = useTop100AttributeWithValue('item');
  const projectAlias = useSelector(selectProjectAlias);
  const isMobile = useSelector(selectIsMobile);
  const { pathname } = useLocation();

  const subscribe = (callback: () => void) => {
    window.addEventListener('beforeunload', callback);

    return () => {
      window.removeEventListener('beforeunload', callback);
    };
  };

  const removeFocus = () => {
    if (
      document.activeElement instanceof HTMLElement &&
      document.activeElement.closest(`.${s.container}`)
    ) {
      document.activeElement.blur();
    }
  };

  // удаляем фокус при нажатии на кнопку назад, иначе подсвечивает 2 активных элемента в меню рубрик
  useSyncExternalStore(subscribe, removeFocus, () => {});

  const { menuWrapperRef, reachGoalClickCallback } = useTopicsMenuReachGoal();

  return (
    <nav
      className={cn(
        s.container,
        isCompactLineWidth && s.compact,
        isMobile && s.mobile,
      )}
      ref={menuWrapperRef}
      {...top100AttributeMenu}
    >
      <ul
        className={cn(
          s.tabs,
          isMobile ? s.mobile : s[`tabs-${projectAlias}`] || s['tabs-default'],
          isCompactLineWidth && s.compact,
          isMobile && isCompactLineWidth && s['mobile-guide'],
        )}
      >
        {menuItems.map(({ url, id, title, isActive }, index) => {
          const top100Key = Object.keys(top100AttributeDefault)[0];
          const top100Attribute = {
            [top100Key]: isCompactLineWidth
              ? id
              : `${top100AttributeDefault[top100Key]}::${index}`,
          };
          const isWithoutSPAPage = WITHOUT_SPA_TOPIC_IDS.includes(Number(id));

          return (
            <li key={id} className={s.tabItem} {...top100Attribute}>
              <Link
                ariaLabel={`Перейти к рубрике ${title}`}
                className={cn(s.tabLink, isActive && s.tabLinkActive)}
                href={`/${url}/`}
                force={!isWithoutSPAPage}
                forceSPA
                aria-current={isActive ? 'page' : false}
                onClick={() => reachGoalClickCallback(url)}
                testId={`link:${title}`}
              >
                {title}
              </Link>
            </li>
          );
        })}
        {!isMobile && (
          <MoreDropdown
            key={pathname}
            items={menuItems}
            reachGoalClickCallback={reachGoalClickCallback}
          />
        )}
      </ul>
    </nav>
  );
};

export const MenuLinks = withErrorBoundary(MenuLinksComponent);
