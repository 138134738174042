import { combineReducers } from '@reduxjs/toolkit';

import { banksRatesReducer } from './banksRatesWidget';
import { breakingNewsReducer } from './breakingNewsWidget';
import { chartWidgetReducer } from './chartWidget';
import { chartWidgetPeriodsReducer } from './chartWidgetPeriods';
import { consensusReducer } from './consensusWidget';
import { crossReducer } from './crossWidget';
import { exchangeRateDynamicsReducer } from './exchangeRateDynamicsWidget';
import { rateExchangeForecastReducer } from './exchangeRatesWidget';
import { exchangeReducer } from './exchangeWidget';
import { horoscopeNameReducer } from './horoscopeNameWidget';
import { indicesAndQuotesReducer } from './indicesAndQuotesWidget';
import { recommendationTeaserReducer } from './recommendationTeaser';
import { recommendationReducer } from './recommendationWidget';
import { tabsReducer } from './tabsWidget';
import { topicNewsReducer } from './topicNewsWidget';
import { topMainReducer } from './topMainWidget';
import { topProjectReducer } from './topProjectWidget';
import { travelWidgetReducer } from './travelWidget';
import { videoWidgetReducer } from './videoWidget';

export const widgetsReducer = combineReducers({
  banksRatesWidget: banksRatesReducer,
  breakingNewsWidget: breakingNewsReducer,
  chartWidget: chartWidgetReducer,
  chartWidgetPeriods: chartWidgetPeriodsReducer,
  consensusWidget: consensusReducer,
  crossWidget: crossReducer,
  exchangeRateDynamicsWidget: exchangeRateDynamicsReducer,
  exchangeWidget: exchangeReducer,
  horoscopeName: horoscopeNameReducer,
  indicesAndQuotesWidget: indicesAndQuotesReducer,
  rateExchangeForecastWidget: rateExchangeForecastReducer,
  recommendationWidget: recommendationReducer,
  recommendationTeaser: recommendationTeaserReducer,
  tabsWidget: tabsReducer,
  topicNewsWidget: topicNewsReducer,
  topMainWidget: topMainReducer,
  topProjectWidget: topProjectReducer,
  videoWidget: videoWidgetReducer,
  travelWidget: travelWidgetReducer,
});
