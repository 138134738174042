import React, { ReactElement } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import {
  selectCommercialS2S,
  selectRuntime,
} from 'common/redux/runtime/selectors';
import { yaReachGoal } from 'utils/counters/yaMetrika';
import { COUNTER_ID } from 'utils/counters/yaMetrika/constants';

import { ScrollToSite } from '../../index';
import { SlotLabelPropsType } from '../../typings';

import { getSlot } from './utils/getSlot';
import { getSlotLabel } from './utils/getSlotLabel';

type CommercialScrollToSitePropsType = {
  clusterId: Card['id'];
  children: ReactElement;
  commercialClassName?: string;
  onRedirect?: () => void;
};

/**
 * Механика ScrollToSite для коммерческих кластеров
 * @param props - пропсы
 * @param props.clusterId - ID кластера для проверки, есть ли он в списке коммерческих;
 * @param props.children - оборачиваемый компонент (тело кластера);
 * @param props.commercialClassName - дополнительный класс;
 * @param props.onRedirect - callback, срабатывающий перед переходом по скроллу.
 */
const CommercialScrollToSiteComponent = function CommercialScrollToSite({
  children,
  clusterId,
  commercialClassName,
  onRedirect,
}: CommercialScrollToSitePropsType) {
  const runtime = useSelector(selectRuntime, shallowEqual);
  const commercialS2SConfig = useSelector(
    selectCommercialS2S(Number(clusterId)),
    shallowEqual,
  );

  const redirectCallback = (ym: CommercialClusterS2S['ym']) => () => {
    if (ym?.transition)
      yaReachGoal(runtime, COUNTER_ID.WebMobile, ym.transition);

    if (onRedirect) onRedirect();
  };
  const labelSwipeCallback = (ym: CommercialClusterS2S['ym']) => () => {
    if (ym?.labelSwipe)
      yaReachGoal(runtime, COUNTER_ID.WebMobile, ym.labelSwipe);
  };
  const labelClickCallback = (ym: CommercialClusterS2S['ym']) => () => {
    if (ym?.labelClick)
      yaReachGoal(runtime, COUNTER_ID.WebMobile, ym.labelClick);
  };

  if (!commercialS2SConfig) {
    return children;
  }

  const { ym, color, alt, text, labelImg, labelType, img } =
    commercialS2SConfig;

  const screenshotCallback = () => {
    if (ym?.textShow) yaReachGoal(runtime, COUNTER_ID.WebMobile, ym.textShow);
  };

  return (
    <ScrollToSite
      commercialClassName={commercialClassName}
      isSwipe
      link={commercialS2SConfig.scrollLink}
      labelSwipeLink={commercialS2SConfig.labelSwipeLink}
      labelClickLink={commercialS2SConfig.labelClickLink}
      labelSwipeCallback={labelSwipeCallback(ym)}
      labelClickCallback={labelClickCallback(ym)}
      redirectCallback={redirectCallback(ym)}
      withoutLoader={false}
      slotLabel={
        getSlotLabel({
          alt,
          color,
          text,
          labelImg,
          labelType,
          screenshotCallback,
        }) as (props: SlotLabelPropsType) => React.ReactNode
      }
      slot={getSlot({ alt, img })}
    >
      {children}
    </ScrollToSite>
  );
};

export const CommercialScrollToSite = withErrorBoundary(
  CommercialScrollToSiteComponent,
);
