import { shallowEqual } from 'react-redux';
import { useParams } from 'react-router';

import { selectClusterById } from 'common/redux/commonData/entries/selectors';
import { useAppSelector } from 'store/hooks';

/**
 * Хук отдающий флаг, что текущий url совпадает с текущим кластером
 * @param clusterId - id кластера
 */
export const useCurrentCluster = (clusterId: Card['id'] | string) => {
  const { clusterId: routerClusterID }: { clusterId: string } = useParams();
  const cluster = useAppSelector(selectClusterById(clusterId), shallowEqual);
  const { isCommercial: isLongread = false } = cluster ?? {};

  /**
   * Так как весь лонгрид состоит буквально из одной спец страницы,
   *  то useParams у него неактуален - у него банально нет Router обертки.
   * Чтобы использующие этот хук компоненты могли стабильно работать, я добавил
   *  хак с прямой проверкой на лонгридность.
   * Если в будущем список проверяемых свойств расширится, то хук или его применения
   *  можно будет переосмыслить. Пока что я хочу оставить его согласно принципу KISS.
   */
  return {
    isCurrentCluster: routerClusterID === String(clusterId) || isLongread,
  };
};
