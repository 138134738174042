import { useSelector } from 'react-redux';
import {
  MediaQueryAllQueryable,
  MediaQueryMatchers,
  useMediaQuery,
} from 'react-responsive';

import { useMounted } from 'common/hooks/useIsMounted';
import { selectViewportType } from 'common/redux/appController/selectors';
import { VIEWPORT_TYPE } from 'config/common/viewport';

/**
 * ИСПОЛЬЗОВАТЬ ЭТОТ ХУК В ПРИОРИТЕТЕ.
 *
 * Хук useMediaQuery, адаптированный под запуск на стороне SSR.
 * Типы параметров тупо взяты из типизации самого компонента, так что
 * если при обновлении пакета что-то сломается - можно сделать также.
 * @param settings - настройки квери параметров, которые реагируют на изменение размеров экрана;
 * @param device - доп настройки для принудительной установки размеров устройства;
 * @param callback - коллбек, реагирующий на изменение размеров экрана.
 */
export const useSafeMediaQuery = (
  settings: Partial<
    MediaQueryAllQueryable & {
      query?: string;
    }
  >,
  device?: MediaQueryMatchers,
  callback?: (matches: boolean) => void,
) => {
  const type = useSelector(selectViewportType);
  // Планшетная, она же узкая версия заканчивается на 1299 пикселях включительно
  const width = type === VIEWPORT_TYPE.tablet ? 1299 : 1600;
  const isMounted = useMounted();

  return useMediaQuery(
    settings,

    /**
     *  Баг с SSR требует отключения хука (принудительной установки размера экрана)
     *    на стороне сервера до момента полной гидрации.
     */
    isMounted ? device : { ...device, width },
    callback,
  );
};
