import React, { ReactNode } from 'react';

import { S2SLabel } from 'common/components/ScrollToSite/components/Label';
import { SlotLabelPropsType } from 'common/components/ScrollToSite/typings';

type GetSlotLabelPropsType = {
  alt: CommercialClusterS2S['alt'];
  color: CommercialClusterS2S['color'];
  labelImg: CommercialClusterS2S['labelImg'];
  labelType: CommercialClusterS2S['labelType'];
  text: CommercialClusterS2S['text'];
  screenshotCallback: () => void;
};

/**
 * Функция для получения ярлычка для коммерческой вариации scroll to site.
 * @param props - пропсы
 * @param props.alt - alt атрибут картинки ярлычка
 * @param props.color - цвет ярлычка
 * @param props.labelImg - адрес изображения ярлычка
 * @param props.labelType - внешний вид отображаемого ярлычка
 * @param props.text - текст ярлычка
 * @param props.screenshotCallback - callback, срабатывающий один раз при показе текста
 */
export const getSlotLabel =
  ({
    alt,
    color,
    labelImg,
    labelType,
    text,
    screenshotCallback,
  }: GetSlotLabelPropsType) =>
  ({
    link,
    isSwipe,
    onClick,
    onMouseDown,
    runtime,
    labelRef,
    active,
  }: SlotLabelPropsType): ReactNode => (
    <S2SLabel
      color={color}
      type={labelType}
      text={text}
      textShowCallback={screenshotCallback}
      link={link}
      isSwipe={isSwipe}
      onClick={onClick}
      onMouseDown={onMouseDown}
      runtime={runtime}
      labelRef={labelRef}
      active={active}
    >
      {labelImg && <img src={labelImg} alt={alt} draggable="false" />}
    </S2SLabel>
  );
