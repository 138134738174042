import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { PAGE_TYPE } from 'config/common/router/typings';

import { fetchTopicNews, fetchTopicRecs } from './asyncs';

const similarActions = {
  pending: [fetchTopicNews.pending.type, fetchTopicRecs.pending.type],
  fullfilled: [fetchTopicNews.fulfilled.type, fetchTopicRecs.fulfilled.type],
  rejected: [fetchTopicNews.rejected.type, fetchTopicRecs.rejected.type],
};

type StatusTypes = 'pending' | 'fullfilled' | 'rejected';

const matchType = (type: StatusTypes) => (action: PayloadAction) =>
  similarActions[type].includes(action.type);

type TopicState = {
  clusterIds: Card['id'][];
  recommendClusterIds: Card['id'][];
  hasNextPage: boolean;
  topicId: TopicType['id'] | undefined;
  puids: PuidsType;
  isFormatTopic: boolean;
} & InitialState;

type ActionTopicPuidsType = PayloadAction<{
  topicAlias: TopicType['alias'];
  projectAlias: ProjectType['alias'];
}>;

type ActionTopicInfoType = PayloadAction<{
  topicId: TopicType['id'] | undefined;
  isFormatTopic: boolean;
}>;

const initialState: TopicState = {
  // Массив id кластеров, отображемых на странице.
  clusterIds: [],
  // Массив id рекоммендов, отображаемых на странице. Этот массив позволяет однозначно определить к какому типу относится тот или иной кластер.
  recommendClusterIds: [],
  // Флаг, что есть кластера для загрузки.
  hasNextPage: true,
  topicId: undefined,
  puids: {},
  isFormatTopic: false,
  fetching: false,
  error: '',
};

const topicSlice = createSlice({
  name: PAGE_TYPE.topic,
  initialState,
  reducers: {
    /**
     * Генерация puids для страницы рубрики
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload - пуиды топика
     */
    setTopicPuids: (state, { payload }: ActionTopicPuidsType) => {
      const { topicAlias, projectAlias } = payload;

      const puidName = getPuidName(projectAlias);
      const formatTopicAlias = topicAlias?.replace(/_/g, '').replace(/-/g, '');

      state.puids = {
        puid6: `${puidName}_${formatTopicAlias}`.toUpperCase(),
        puid18: `${puidName}_${formatTopicAlias}_main`.toUpperCase(),
      };
    },

    /**
     * Метод установки данных о рубрике
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload - данные топика
     */
    setTopicInfo: (state, { payload }: ActionTopicInfoType) => {
      const { topicId, isFormatTopic } = payload;

      if (!topicId) return;

      state.topicId = topicId;
      state.isFormatTopic = isFormatTopic;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchTopicNews.fulfilled.type,
        (state, { payload }: PayloadAction<Card['id'][]>) => {
          state.fetching = false;

          /**
           * Если уже есть что-нибудь в стейте, то проверяем, пришло ли что-либо от сервера.
           * Если ничего не пришло, то помечаем, что грузить больше нечего.
           */
          state.hasNextPage =
            state.clusterIds.length === 0 || payload.length !== 0;

          state.clusterIds = payload;
        },
      )
      .addCase(
        fetchTopicRecs.fulfilled.type,
        (state, { payload }: PayloadAction<Card['id'][]>) => {
          state.fetching = false;

          /**
           * Если уже есть что-нибудь в стейте, то проверяем, пришло ли что-либо от сервера.
           * Если ничего не пришло, то помечаем, что грузить больше нечего.
           */
          state.hasNextPage = payload.length !== 0;

          state.recommendClusterIds = [
            ...state.recommendClusterIds,
            ...payload,
          ];
        },
      )
      .addMatcher(matchType('pending'), (state) => {
        state.fetching = true;
      })
      .addMatcher(
        matchType('rejected'),
        (state, { error }: { error: Error }) => {
          state.fetching = false;
          state.error = error.message;
        },
      );
  },
});

export const topicReducer = topicSlice.reducer;
export const { setTopicPuids, setTopicInfo } = topicSlice.actions;

export { fetchTopicNews, fetchTopicRecs };
