import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TABS_TYPE } from './typings';

type TabWidgetType = {
  activeTab: TABS_TYPE;
} & InitialState;

/**
 * Слайс виджета переключения контента на финансах.
 */
const tabsSlice = createSlice({
  name: 'tab',
  initialState: {
    /**
     * Тип активного таба.
     */
    activeTab: TABS_TYPE.dynamics,
    fetching: false,
    error: '',
  } as TabWidgetType,
  reducers: {
    /**
     * Метод обновления активного таба
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload - тип таба.
     */
    setActiveTab: (state, { payload }: PayloadAction<TABS_TYPE>) => {
      state.activeTab = payload;
    },
  },
});

export const tabsReducer = tabsSlice.reducer;

export const { setActiveTab } = tabsSlice.actions;
