import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { incrementReloadKey } from 'common/redux/appController';
import { selectRuntime } from 'common/redux/runtime/selectors';
import { yaReachGoal } from 'utils/counters/yaMetrika';
import { COUNTER_ID } from 'utils/counters/yaMetrika/constants';

/**
 * Хук, осуществляющий перезагрузку рекламы при входе/выходе
 */
export const useAuthListener = () => {
  const dispatch = useDispatch();

  const runtime = useSelector(selectRuntime, shallowEqual);

  useEffect(() => {
    const register = () => {
      yaReachGoal(runtime, COUNTER_ID.Portal, 'ID_registration');
      yaReachGoal(runtime, COUNTER_ID.Common, 'ID_registration');
    };

    const authListener = () => {
      dispatch(incrementReloadKey());
    };

    if (typeof window !== 'undefined') {
      window.ramblerIdHelper = window.ramblerIdHelper || [];

      window.ramblerIdHelper.push(() => {
        window.ramblerIdHelper.addListener('register', register);
        window.ramblerIdHelper.addListener('login', authListener);
        window.ramblerIdHelper.addListener('oauthlogin', authListener);
        window.ramblerIdHelper.addListener('logout', authListener);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
