import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { selectClusterById } from 'common/redux/commonData/entries/selectors';
import {
  clusterChangeSpeech,
  clusterDecrementTextScale,
  clusterIncrementTextScale,
} from 'common/redux/pages/cluster';
import {
  selectClusterPageSpeaking,
  selectClusterPageTextScale,
} from 'common/redux/pages/cluster/selectors';
import { selectIsMobile } from 'common/redux/runtime/selectors';

import { TextScale } from './components/TextScale';
import { TextToSpeech } from './components/TextToSpeech';

import s from './styles.module.css';

type ClusterControlPropsType = {
  clusterId: Cluster['id'] | null;
  textToSpeechVisible?: boolean;
  customStyles?: StylesType;
  iconMinus?: string;
  iconPlus?: string;
  textScaleMax?: number;
  textScaleMin?: number;
};

/**
 * Панель управления кластером (скейл текста, воспроизведение).
 * @param props - пропсы
 * @param props.clusterId - Данные кластера;
 * @param props.textToSpeechVisible - флаг отображения кнопки озвучки текста;
 * @param props.customStyles - объект стилизации компонента;
 * @param props.textScaleMax - максимальное увеличение текста;
 * @param props.textScaleMin - минимальное увеличение текста.
 */
const ClusterControlComponent = function ClusterControl({
  clusterId,
  textToSpeechVisible = true,
  customStyles = s,
  textScaleMax = 2,
  textScaleMin,
}: ClusterControlPropsType) {
  const dispatch = useDispatch();

  const isMobile = useSelector(selectIsMobile);
  const textScaleValue = useSelector(selectClusterPageTextScale);
  const speaking = useSelector(selectClusterPageSpeaking, shallowEqual);
  const cluster = useSelector(selectClusterById(clusterId), shallowEqual);

  const clusterTitle = cluster?.longTitle ?? '';
  const clusterBody = cluster?.body ?? '';

  const textScaleDown = useCallback(() => {
    dispatch(clusterDecrementTextScale());
  }, [dispatch]);

  const textScaleUp = useCallback(() => {
    dispatch(clusterIncrementTextScale());
  }, [dispatch]);

  const changeSpeak = useCallback(
    (speech: ClusterSpeakingType) => {
      dispatch(clusterChangeSpeech(speech));
    },
    [dispatch],
  );

  return (
    <div className={customStyles?.wrapper}>
      <TextScale
        textScaleValue={textScaleValue}
        textScaleUp={textScaleUp}
        textScaleDown={textScaleDown}
        customStyles={customStyles}
        textScaleMax={textScaleMax}
        textScaleMin={textScaleMin}
        isMobile={isMobile}
      />
      {textToSpeechVisible ? (
        <TextToSpeech
          clusterId={`${clusterId}`}
          clusterTitle={clusterTitle}
          clusterBody={clusterBody}
          speaking={speaking}
          changeSpeak={changeSpeak}
          customStyles={customStyles}
          isMobile={isMobile}
        />
      ) : null}
    </div>
  );
};

export const ClusterControl = withErrorBoundary(ClusterControlComponent);
