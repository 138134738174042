import { createAsyncThunk } from '@reduxjs/toolkit';

import { getHoroscopeName } from 'api';
import { selectApiConfig } from 'common/redux/runtime/selectors';

type FetchHoroscopeNamePropsType = {
  nameAlias: string;
};

/**
 * Функция загрузки данных для виджета Что значит имя.
 * @param nameAlias - имя на латинице.
 */
export const fetchHoroscopeName = createAsyncThunk(
  'widgets/fetchHoroscopeName',
  async ({ nameAlias }: FetchHoroscopeNamePropsType, { getState }) => {
    const apiConfig = selectApiConfig(getState() as AppState);

    const { data, error } = await getHoroscopeName(apiConfig, nameAlias);

    if (error || !data) {
      throw (
        error ||
        new Error(
          `Ошибка при получении данных для виджета Что значит имя: ${nameAlias}`,
        )
      );
    }

    return data;
  },
);
