import { InjectorType } from '@media-components/draft-parser';

import {
  BLOCK_TYPES,
  CUSTOM_BLOCK_TYPE,
} from 'common/components/ClusterContentOnDraft/typings';

const HEADINGS_BLOCKS = [BLOCK_TYPES.H2, BLOCK_TYPES.H3];

/**
 * Инжектор id для заголовков кластера.
 * Чтобы якоря в коротком описании кластера работали, добавляем каждому заголовку id с его порядковым номером.
 * @param props - пропсы
 * @param props.blocks - блоки драфта.
 */
export const injectHeadingIds: InjectorType<{}> = ({ blocks }) => {
  const withShortSummaryBlock = blocks.some(
    (block) => block.type === CUSTOM_BLOCK_TYPE.ShortSummary,
  );

  if (!withShortSummaryBlock) {
    return blocks;
  }

  let headingsCounter = 0;

  return blocks.map((block) => {
    if (HEADINGS_BLOCKS.includes(block.type as BLOCK_TYPES)) {
      headingsCounter = headingsCounter + 1;

      return {
        ...block,
        data: { ...block.data, id: headingsCounter },
      };
    }

    return block;
  });
};
