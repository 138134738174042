import { useState, useCallback, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useObserver } from 'common/hooks/useObserver';
import { selectIsBrandingByBannerName } from 'common/redux/appController/selectors';
import { BANNERS } from 'config/common/banners/typings';
import { BRANDING_CLASS_NAME } from 'config/common/common';

const observerConfig = { threshold: 0 };
const entryCheck = false;

// Высчитывание сдвига блока S2S при прилетании брендинга
const useBrandingMargin = () => {
  const [brandingMargin, setBrandingMargin] = useState<number>(0);
  const isBranding = useSelector(
    selectIsBrandingByBannerName(BANNERS.Billboard),
  );

  useEffect(() => {
    const brandingDiv = document.body.getElementsByClassName(
      BRANDING_CLASS_NAME,
    )[0] as HTMLElement;

    /**
     * Сдвига блока происходит если навешан класс 'commercial-branding'
     * и пришёл биллборд с viewTypes = Branding
     */
    if (brandingDiv && isBranding) {
      const interval = setInterval(() => {
        const brandingDivMarginTop = brandingDiv.style.marginTop;
        const marginTop = Number(brandingDivMarginTop.replace('px', ''));

        setBrandingMargin(marginTop);

        if (brandingMargin) {
          clearInterval(interval);
        }
      }, 200);
    }
  }, [brandingMargin, isBranding]);

  return brandingMargin;
};

/**
 * Хук отработки механизма s2s
 * @param active - активность s2s
 */
export const useScrollToSite = <T extends HTMLElement>(
  active: boolean = true,
) => {
  /** Ref наблюдения за контентом, оборачиваемым в s2s */
  const stickyRef = useRef<T | null>(null);

  /** Ref для распорки под контент, оборачиваемый в s2s */
  const spacerStickyRef = useRef<T | null>(null);

  /** Флаг доскролла до s2s */
  const [activeS2S, setActiveS2S] = useState<boolean>(false);

  /** Число, на которое сдвинут блок при брендинге */
  const brandingMargin = useBrandingMargin();

  /** callback наблюдателя, активирует s2s анимацию */
  const observerCallback = useCallback(
    (entrie: IntersectionObserverEntry) => {
      const stickyNode = stickyRef.current;
      const spacerNode = spacerStickyRef.current;

      if (!spacerNode || !stickyNode) return;

      spacerNode.style.height = `${stickyNode.offsetHeight + brandingMargin}px`;

      setActiveS2S(entrie.isIntersecting);
    },
    [brandingMargin],
  );
  const s2sRef = useObserver<T>({
    callback: active ? observerCallback : null,
    entryCheck,
    observerConfig,
  });

  return {
    activeS2S,
    s2sRef,
    stickyRef,
    spacerStickyRef,
  };
};
