import { selectCardById } from 'common/redux/commonData/entries/selectors';
import {
  selectTopicById,
  selectTopicIsGreen,
} from 'common/redux/commonData/topics/selectors';
import { selectWidgetsTopMainClusterIDs } from 'common/redux/commonData/widgets/topMainWidget/selectors';
import { selectWidgetsTopProjectClusterIDs } from 'common/redux/commonData/widgets/topProjectWidget/selectors';
import { selectProjectId } from 'common/redux/runtime/selectors';

import { selectPages } from '../selectors';

export const selectTopicPage = (state: AppState) => selectPages(state).topic;

export const selectTopicPageTopicId = (state: AppState) =>
  selectTopicPage(state).topicId;

export const selectTopicPageItem = (state: AppState) =>
  selectTopicById(selectTopicPageTopicId(state))(state);

export const selectTopicPageTopicAlias = (state: AppState) =>
  selectTopicById(selectTopicPageTopicId(state))(state)?.alias;

export const selectTopicPagePuids = (state: AppState) =>
  selectTopicPage(state).puids;

export const selectTopicPageIsFormatTopic = (state: AppState) =>
  selectTopicPage(state).isFormatTopic;

export const selectTopicPageClusterIds = (state: AppState) =>
  selectTopicPage(state).clusterIds;

export const selectTopicPageRecommendClusterIds = (state: AppState) =>
  selectTopicPage(state).recommendClusterIds;

export const selectTopicPageIsGreen = (state: AppState) => {
  const topicId = selectTopicPageTopicId(state);

  if (!topicId) return false;

  return selectTopicIsGreen(topicId)(state);
};

export const selectTopicPageCluster =
  (clusterIds: Card['id'][]) => (state: AppState) =>
    clusterIds?.map((clusterId) => selectCardById(clusterId)(state)) || [];

export const selectTopicPageClusterInCardData = (state: AppState) =>
  selectTopicPageCluster(selectTopicPageClusterIds(state))(state);

export const selectTopicPageLoadingState = (state: AppState) =>
  selectTopicPage(state).fetching;

export const selectTopicPageErrorState = (state: AppState) =>
  selectTopicPage(state).error;

export const selectTopicPageHasNextPage = (state: AppState) =>
  selectTopicPage(state).hasNextPage;

export const selectTopicExcludedClustersIds = (state: AppState) => {
  const projectId = selectProjectId(state);
  const topicClustersIds = selectTopicPageClusterIds(state);
  const topProjectWidgetsClusterIds =
    selectWidgetsTopProjectClusterIDs(projectId)(state);
  const topMainWidgetsClustersIds = selectWidgetsTopMainClusterIDs(state);
  const topicRecommendClustersIds = selectTopicPageRecommendClusterIds(state);

  return [
    ...topicClustersIds,
    ...topProjectWidgetsClusterIds,
    ...topMainWidgetsClustersIds,
    ...topicRecommendClustersIds,
  ];
};
