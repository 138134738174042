import loadable from '@loadable/component';
import React from 'react';

import { fetchTravelCountries } from 'common/redux/commonData/travelCountries';
import {
  setCountryAlias,
  setGuideContentPagePuids,
} from 'common/redux/pages/guideContentPage';
import { getPageDownloader } from 'common/routes/pageLoadable';
import { PAGE_TYPE } from 'config/common/router/typings';

import { IAppRoute } from '../typings';

const GuideCountryPageDesktop = loadable(
  () => import('desktop/pages/GuideCountry'),
);
const GuideCountryPageMobile = loadable(
  () => import('mobile/pages/GuideCountry'),
);

type GuideCountryMatchRoute = {
  countryAlias: string;
};

/**
 * Роут контентной страницы Страны.
 * @see https://travel.rambler.ru/guide/Russia/#1
 * @param isMobile - флаг мобильной версии
 */
export const guideCountryRoutes = (
  isMobile: SettingsType['isMobile'],
): IAppRoute<GuideCountryMatchRoute> => ({
  name: PAGE_TYPE.guideCountry,
  exact: true,
  path: '/guide/:countryAlias([-A-Za-z]+)/',
  render: isMobile
    ? () => <GuideCountryPageMobile />
    : () => <GuideCountryPageDesktop />,
  fetchData: async ({ dispatch }, { params }) => {
    const pageDownloaderParams = isMobile
      ? ([GuideCountryPageMobile, 'GuideCountryPageMobile'] as const)
      : ([GuideCountryPageDesktop, 'GuideCountryPageDesktop'] as const);
    const { countryAlias } = params;

    dispatch(setCountryAlias(countryAlias));
    dispatch(setGuideContentPagePuids(PAGE_TYPE.guideCountry));

    await dispatch(fetchTravelCountries());
    await getPageDownloader(pageDownloaderParams[0], pageDownloaderParams[1]);
  },
});
