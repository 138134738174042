import loadable from '@loadable/component';
import React from 'react';

import { setDateInfo, setDatePuids } from 'common/redux/pages/date';
import { fetchDateClusters } from 'common/redux/pages/date/asyncs';
import { selectProjectAlias } from 'common/redux/runtime/selectors';
import { appendPageDownloader } from 'common/routes/pageLoadable';
import { PAGE_TYPE } from 'config/common/router/typings';
import { createGenetiveDate } from 'utils/date/dtime';

import { IAppRoute } from '../typings';

const DateDesktop = loadable(() => import('desktop/pages/Date'));
const DateMobile = loadable(() => import('mobile/pages/Date'));

let abortFetch: (() => void) | undefined;

type DateMatchRoute = {
  year: string;
  month: string;
  day: string;
};

/**
 * Роут новостей по дате.
 * @see https://news.rambler.ru/2023/08/07/
 * @param isMobile - флаг мобильной версии
 */
export const dateRoutes = (
  isMobile: SettingsType['isMobile'],
): IAppRoute<DateMatchRoute> => ({
  name: PAGE_TYPE.date,
  exact: true,
  path: '/:year(\\d{4})/:month(\\d{2})/:day(\\d{2})/',
  render: isMobile ? () => <DateMobile /> : () => <DateDesktop />,
  fetchData: async (
    { dispatch, getState },
    { params: { year, month, day } },
    queryParams,
  ) => {
    const page = Number(queryParams?.page || 1);

    const projectAlias = selectProjectAlias(getState());

    dispatch(setDatePuids(projectAlias));

    const title = `Новости за ${createGenetiveDate(year, month, day)}`;
    const fullDate = `${year}-${month}-${day}`;

    // чтобы при смене дат в календаре старый запрос переставал выполняться
    abortFetch?.();

    const fetchDateClustersPromise = dispatch(
      fetchDateClusters({ fullDate, page }),
    );

    abortFetch = () => fetchDateClustersPromise.abort();

    const promiseList: PromiseListType = [fetchDateClustersPromise];

    dispatch(
      setDateInfo({
        title,
        year,
        month,
        day,
        fullDate,
      }),
    );

    await Promise.all(
      appendPageDownloader({
        promiseList,
        loadableComponent: isMobile ? DateMobile : DateDesktop,
        chunkName: Object.keys({ DateDesktop })[0],
      }),
    );

    abortFetch = undefined;
  },
});
