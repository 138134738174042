/**
 * Функция для редьюсера, который создает объект aliasToId.
 * @param acc - аккумулятор для редьюсера;
 * @param editor - обект редактора.
 * @param editor.alias - alias редактора.
 * @param editor.id - id редактора.
 */
export const createAliasToId = (
  acc: AliasToIdAble['aliasToId'],
  { alias, id }: FullEditorType,
) => {
  if (acc[alias]) return acc;

  acc[alias] = id;

  return acc;
};
