import React, { memo } from 'react';

import { TagListLayout } from './components/TagListLayout';
import { useTagList } from './utils/useTagList';
import { useTagsButtonVisibilityChanger } from './utils/useTagsButtonVisibilityChanger';

type TagListPropsType = {
  clusterId: Card['id'];
  styles?: StylesType;
};

/**
 * Обертка над шаблоном тегов для редизайна десктопного кластера
 * @param props - пропсы
 * @param props.clusterId - id кластера для поиска тегов;
 * @param props.styles - стили.
 */
const TagListComponent = function TagList({
  clusterId,
  styles,
}: TagListPropsType) {
  const tags = useTagList(clusterId);

  const { outerTagWrapperRef, innerTagWrapperRef, isTagsButtonVisible } =
    useTagsButtonVisibilityChanger();

  return (
    <TagListLayout
      markers={tags}
      styles={styles}
      outerTagWrapperRef={outerTagWrapperRef}
      isTagsButtonVisible={isTagsButtonVisible}
      innerTagWrapperRef={innerTagWrapperRef}
      clusterId={`${clusterId}`}
    />
  );
};

export const TagList = memo(TagListComponent);
