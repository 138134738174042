import { EntityPropsType } from '@media-components/draft-parser';
import React, { memo } from 'react';

import { ExpertBase } from 'common/components/ExpertQuote/ExpertBase';

type ExpertDataPropsType = {
  id: ATExpertType['id'];
  quote: string;
};

/**
 * Формирование эмбеда эксперта.
 * @param props - пропсы
 * @param props.data - объект со специфичными данными для блока:
 * - id - id эксперта;
 * - height - высота формы.
 */
const ExpertComponent = function Expert({
  data,
}: EntityPropsType<ExpertDataPropsType, unknown>) {
  if (!data || !data.id || !data.quote) {
    return null;
  }

  const { id, quote } = data;

  return (
    <ExpertBase expertId={id}>
      <p>{quote}</p>
    </ExpertBase>
  );
};

export const Expert = memo(ExpertComponent);
