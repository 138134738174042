import { ENTITY_TYPES } from '@media-components/draft-parser';
import { RawDraftEntity } from 'draft-js';

import { EntityModifierGeneratorType } from 'common/components/ClusterContentOnDraft/typings';

/**
 * Возможные варианты
 * https://img01.rl0.ru/ - вероятно больше мертв, чем жив
 * https://img04.rl0.ru/ - тоже почти не используется, но можно встретить в старых кластерах
 */
const oldCDNUrl = 'rl0.ru';

type ImageDataPropsType = {
  src: string;
};

/**
 * Функция для подмены ссылок со старой кропалки на новую.
 */
export const updateImageLinks: EntityModifierGeneratorType<{}> =
  () => (entityMap) =>
    Object.fromEntries(
      Object.entries(entityMap).map(([key, entity]) => {
        if (entity.type !== ENTITY_TYPES.Image) {
          return [key, entity];
        }

        const src = (entity as RawDraftEntity<ImageDataPropsType>)?.data?.src;

        if (!src?.includes(oldCDNUrl)) {
          return [key, entity];
        }

        return [
          key,
          {
            ...entity,
            data: {
              ...entity.data,
              src: src.replace(/.+?\/https\//, 'https://'),
            },
          },
        ];
      }),
    );
