import cn from 'classnames';
import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { ClusterImageObject } from 'common/components/schemaOrg/ImageObject/ClusterImageObject';
import { VideoObjectSchema } from 'common/components/schemaOrg/VideoObject';
import { VideoInline } from 'common/components/VideoInline';
import { VideoPlayer, VIDEO_DATA_TYPE } from 'common/components/VideoPlayer';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { StateTopicType } from 'common/redux/commonData/topics/typings';
import { selectDomainConfig } from 'common/redux/runtime/selectors';
import { checkIsRamblerPlayer } from 'common/utils/checkIsVideoPlatform';
import { generateClusterUrl } from 'utils/urlGenerator';

import s from './styles.module.css';

type VideoPropsType = {
  cluster: Cluster;
  topic?: StateTopicType;
  clusterIndex?: number;
  styles?: StylesType;
  type?: 'default' | 'multi';
  puids: PuidsType | undefined;
  height?: number;
  className?: string;
};

/**
 * Видео кластера
 * @param props - пропсы
 * @param props.cluster - кластер;
 * @param props.topic - топик кластера;
 * @param props.clusterIndex - индекс кластера в бесконечном скролле;
 * @param props.styles - стили;
 * @param props.puids - рекламные пуиды;
 * @param props.height - фиксированная высота видео;
 * @param props.className - строка с классом для контейнера блока.
 */
const VideoComponent = function Video({
  cluster,
  topic,
  clusterIndex = 0,
  styles = s,
  puids,
  height,
  className,
}: VideoPropsType) {
  const domainConfig = useSelector(selectDomainConfig, shallowEqual);

  const {
    id: clusterId,
    normalizedTitle,
    video: { embedCode, videoData, recordId },
  } = cluster;

  const clusterUrl = useMemo(
    () =>
      generateClusterUrl({
        clusterId,
        normalizedTitle,
        domainConfig,
        topic,
      }),
    [clusterId, normalizedTitle, domainConfig, topic],
  );

  const isRamblerPlayerVideo = checkIsRamblerPlayer(embedCode);

  const isBroadcast = videoData?.type === VIDEO_DATA_TYPE.broadcast;

  return (
    <div className={cn(s.container, className)}>
      <ClusterImageObject cluster={cluster} />
      <VideoObjectSchema cluster={cluster} />
      {isRamblerPlayerVideo || recordId ? (
        <VideoPlayer
          clusterUrl={clusterUrl}
          embedCode={embedCode}
          recordId={recordId}
          puids={puids}
          styles={styles}
          isBroadcast={isBroadcast}
          height={height}
          clusterId={clusterId}
        />
      ) : (
        <VideoInline
          clusterIndex={clusterIndex}
          styles={styles}
          embedCode={embedCode}
          clusterUrl={clusterUrl}
        />
      )}
    </div>
  );
};

export const Video = withErrorBoundary(VideoComponent);
