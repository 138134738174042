import loadable from '@loadable/component';
import React from 'react';
import { useSelector } from 'react-redux';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { selectIsDebug } from 'common/redux/runtime/selectors';

const Debugger = loadable(() => import('common/components/Debugger'), {
  resolveComponent: (components) => components.Debugger,
});

/**
 * Компонент опционального рендера дебаггера.
 */
export const OptionalDebuggerComponent = function OptionalDebugger() {
  const isDebug = useSelector(selectIsDebug);

  if (!isDebug) return null;

  return <Debugger />;
};

export const OptionalDebugger = withErrorBoundary(OptionalDebuggerComponent);
