import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { PROJECT_ALIASES } from 'config/common/project/typings';
import { PAGE_TYPE } from 'config/common/router/typings';

type StateGuideType = {
  // Алиас страны
  countryAlias: string;
  // Алиас города
  cityAlias: string;
  // Id места
  placeId: string;
  // Пуиды настройки для рекламы
  puids: PuidsType;
};

const guideContentPageSlice = createSlice({
  name: 'guideContentPage',
  initialState: {
    countryAlias: '',
    cityAlias: '',
    placeId: '',
    puids: {},
  } as StateGuideType,
  reducers: (create) => ({
    /**
     * Генерация puids для контентной страницы в зависимости от типа региона
     * @param action.payload - тип региона.
     */
    setGuideContentPagePuids: create.reducer(
      (state, { payload }: PayloadAction<PAGE_TYPE>) => {
        const puidName = getPuidName(PROJECT_ALIASES.Travel);

        if (payload === PAGE_TYPE.guideCity) {
          state.puids = {
            puid6: `${puidName}_country`.toUpperCase(),
            puid18: `${puidName}_country_city`.toUpperCase(),
          };

          return;
        }

        if (payload === PAGE_TYPE.guidePlace) {
          state.puids = {
            puid6: `${puidName}_country`.toUpperCase(),
            puid18: `${puidName}_country_place`.toUpperCase(),
          };

          return;
        }

        state.puids = {
          puid6: `${puidName}_country`.toUpperCase(),
          puid18: `${puidName}_country_main`.toUpperCase(),
        };
      },
    ),

    /**
     * Установление алиаса страны
     * @param action.payload - алиас страны.
     */
    setCountryAlias: create.reducer(
      (state, { payload }: PayloadAction<StateGuideType['countryAlias']>) => {
        state.countryAlias = payload;
      },
    ),

    /**
     * Установление алиаса города
     * @param action.payload - алиас страны.
     */
    setCityAlias: create.reducer(
      (state, { payload }: PayloadAction<StateGuideType['cityAlias']>) => {
        state.cityAlias = payload;
      },
    ),

    /**
     * Установление id места
     * @param action.payload - алиас страны.
     */
    setPlaceId: create.reducer(
      (state, { payload }: PayloadAction<StateGuideType['placeId']>) => {
        state.placeId = payload;
      },
    ),
  }),
});

export const {
  setGuideContentPagePuids,
  setCountryAlias,
  setCityAlias,
  setPlaceId,
} = guideContentPageSlice.actions;

export const guideContentPageReducer = guideContentPageSlice.reducer;
