import Cookies from 'js-cookie';

import { TEXT_SCALE_COOKIE_LIFE, COOKIE_DOMAIN } from 'config/common/cookies';

const TEXT_SCALE_LABEL_NAME = 'text-scale';

/**
 * Функция сохранения данных о размере текста в cookie.
 * @param scaleValue - текущий размер текста.
 */
export const setTextScaleToCookie = (scaleValue: number | string) => {
  if (__SERVER__) return;

  if (__DEV__ && Number.isNaN(Number(scaleValue))) {
    console.error(`${setTextScaleToCookie.name}: Некорректное значение`);
  }

  Cookies.set(TEXT_SCALE_LABEL_NAME, String(scaleValue), {
    domain: COOKIE_DOMAIN,
    expires: new Date(Date.now() + TEXT_SCALE_COOKIE_LIFE),
    secure: true,
    sameSite: 'strict',
  });
};

type GetTextScaleFromCookieType = (props?: {
  minValue?: number;
  maxValue?: number;
}) => number | null;

/**
 * Функция получения данных о размере текста, сохраненного в cookie.
 * @param props - пропсы
 * @param props.minValue - минимальная граница для размера текста;
 * @param props.maxValue - максимальная граница для размера текста.
 */
export const getTextScaleFromCookies: GetTextScaleFromCookieType = ({
  minValue,
  maxValue,
} = {}) => {
  if (__DEV__ && maxValue && minValue && maxValue < minValue) {
    console.error(
      `${getTextScaleFromCookies.name}: Максимальное значение не может быть меньше минимального`,
    );

    return null;
  }

  const labelValue = Number(Cookies.get(TEXT_SCALE_LABEL_NAME));

  if (Number.isNaN(labelValue)) return null;
  if (maxValue && labelValue > maxValue) return maxValue;
  if (minValue && labelValue < minValue) return minValue;

  return labelValue;
};

/**
 * Функция отключения заглушки измененного размера шрифта на клиенте.
 * Используется в паре с инициализацией значения размера шрифта кластера.
 */
export const clearTextScalePlug = () => {
  if (__SERVER__) {
    console.error('Нельзя использовать эту функцию на сервере.');

    return;
  }

  const textScale = getTextScaleFromCookies();

  document.body.classList.remove(`${TEXT_SCALE_LABEL_NAME}-${textScale}`);
};

const getTextScaleValueInPureJS = (textSplitCookieName: string) => {
  if (!window) return null;

  const EQULATION_SING_AND_VALUE_PADDING = 2;

  const textScaleIndex = document.cookie.search(textSplitCookieName);

  if (textScaleIndex === -1) return null;

  const textScaleCookieValue = document.cookie.slice(
    textScaleIndex,
    textScaleIndex +
      textSplitCookieName.length +
      EQULATION_SING_AND_VALUE_PADDING,
  );
  const [, cookieValue] = textScaleCookieValue.split('=');

  if (!Number(cookieValue)) return null;

  return Number(cookieValue);
};

/**
 * Функция иницализации заглушки измененного размера шрифта на клиенте.
 * Нужна до того момента, пока настоящее значение размера шрифта не инициализируется в редаксе.
 * @param textScaleName - название размера шрифта, если такой есть.
 * @param textScaleValue - текущий размер шрифта, если такой есть.
 */
const initTextScalePlug = (textScaleName: string, textScaleValue: number) => {
  if (!textScaleValue) return;
  document.body.classList.add(`${textScaleName}-${textScaleValue}`);
};

/**
 * Строчка со скриптом чистого js
 *  для ранней вставки размера шрифта в верстку страницы.
 */
export const initTextScalePlugInJS = () => `
  const tSV = (${getTextScaleValueInPureJS.toString()})('${TEXT_SCALE_LABEL_NAME}');
  (${initTextScalePlug})('${TEXT_SCALE_LABEL_NAME}', tSV);
`;
