import { AliasToIdType, StateTopicType } from './typings';

/**
 * Функция адаптирования топика к расширенной версии.
 * @param topic - единый объект топика.
 */
export const createTopicToState = (topic: ATTopic): StateTopicType => ({
  ...topic,
  hasNextPage: true,
  clusterIds: [],
});

/**
 * Функция для редьюсера, который создает объект aliasToId.
 * @param acc - аккумулятор для редьюсера;
 * @param topic - обект топика.
 * @param topic.alias - alias топика.
 * @param topic.id - is топика.
 * @param topic.project_id - id проекта у топика.
 */
export const createAliasToId = (
  acc: AliasToIdType,
  { alias, id, project_id: projectId }: ATTopic,
) => {
  if (acc[alias]) {
    acc[alias] = {
      ...acc[alias],
      [projectId]: id,
    };
  } else {
    acc[alias] = { [projectId]: id };
  }

  return acc;
};
