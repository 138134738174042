import React from 'react';

import { Link } from 'common/components/Link';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';

import { createMarkerUrl } from '../../utils/createMarkerUrl';

type TagLinkPropsType = MarkerType & {
  position: number;
  styles: StylesType;
};

/**
 * Компонент ссылки для тэгов. Защищает от роутинга на ещё не переписанные страницы.
 * @param props - пропсы
 * @param props.name - текст ссылки страницы;
 * @param props.position - позиция страницы;
 * @param props.type - тип страницы;
 * @param props.alias - алиас страницы, на которую будет совершен переход;
 * @param props.id - id страницы;
 * @param props.styles - стили компонента.
 */
const TagLinkComponent = function TagLink({
  name,
  id,
  type,
  alias,
  position,
  styles,
}: TagLinkPropsType) {
  const top100Attribute = useTop100AttributeWithValue(type || alias);
  const label = `Перейти к новостям по теме ${name}`;

  const href = createMarkerUrl({ id, type, alias });

  // пример https://finance.rambler.ru/markets/47651056-kurs-rublya-ruhnul/
  if (type === 'tag') {
    return (
      <a
        href={href}
        aria-label={label}
        data-index={position}
        data-test="tag-link"
        className={styles?.tagListItem}
        {...top100Attribute}
      >
        {name}
      </a>
    );
  }

  return (
    <Link
      href={href}
      ariaLabel={label}
      data-index={position}
      data-test="tag-link"
      forceSPA
      className={styles?.tagListItem}
      {...top100Attribute}
    >
      {name}
    </Link>
  );
};

export const TagLink = withErrorBoundary(TagLinkComponent);
