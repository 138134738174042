import React, {
  FocusEventHandler,
  MouseEventHandler,
  memo,
  useCallback,
} from 'react';
import { shallowEqual } from 'react-redux';
import { useHistory } from 'react-router';

import { selectPageName } from 'common/redux/appController/selectors';
import {
  selectDomainConfig,
  selectIsMobile,
} from 'common/redux/runtime/selectors';
import { useAppSelector } from 'store/hooks';

import { LinkType, replaceDomain, checkDomain } from './utils';

type LinkPropsType = {
  href: string;
  children: React.ReactNode;
  force?: boolean;
  shouldUseSPA?: boolean;
  forceSPA?: boolean;
  className?: string;
  rel?: string;
  target?: string;
  onClick?: MouseEventHandler;
  onFocus?: FocusEventHandler<HTMLAnchorElement>;
  onBlur?: FocusEventHandler<HTMLAnchorElement>;
  ariaLabel?: string;
  testId?: string;
};

/**
 * Компонент рендера ссылки для SPA, замена react-router Link
 * (SPA используется для mobile, для desktop возвращает обычную ссылку
 * @param props - пропсы
 * @param props.href - url перехода;
 * @param props.force - принудительный рендер react-router Link;
 * @param props.shouldUseSPA - флаг, что надо использовать SPA;
 * @param props.forceSPA - флаг, что надо использовать SPA в любом случае;
 * @param props.restProps - остальные свойства добавляются <a>;
 * @param props.onClick - слушатель на клик элемента;
 * @param props.onFocus - слушатель на фокус элемента;
 * @param props.onBlur - слушатель на расфокус элемента;
 * @param props.ariaLabel - aria-label для ссылки;
 * @param props.children - дочерний компонент;
 * @param props.testId - id для теста (?).
 */
const LinkComponent = function Link({
  href,
  force = false,
  shouldUseSPA = false,
  // TODO(NEWS-11857): придумать что нибудь лучше
  forceSPA = false,
  children,
  onClick,
  onFocus,
  onBlur,
  ariaLabel,
  testId,
  ...restProps
}: LinkPropsType) {
  const history = useHistory();

  const domainConfig = useAppSelector(selectDomainConfig, shallowEqual);
  const pageName = useAppSelector(selectPageName);
  const isMobile = useAppSelector(selectIsMobile);

  /**
   * Обработчик ссылки. Использует react-router при подходящем урле
   * @param event - объект события click
   */
  const handleMobileClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      const linkType = checkDomain(domainConfig, href);

      if (linkType === LinkType.reactRouter || force) {
        event.preventDefault();

        const url = force ? replaceDomain(domainConfig, href) : href;

        history.push(url);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageName, href, force],
  );

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if ((isMobile && !shouldUseSPA) || forceSPA) {
        handleMobileClick(e);
      }

      if (onClick) {
        onClick(e);
      }
    },
    [forceSPA, handleMobileClick, isMobile, onClick, shouldUseSPA],
  );

  return (
    <a
      aria-label={ariaLabel}
      href={href}
      onClick={handleClick}
      onFocus={onFocus}
      onBlur={onBlur}
      data-test="link"
      data-testid={testId}
      {...restProps}
    >
      {children}
    </a>
  );
};

export const Link = memo(LinkComponent);
