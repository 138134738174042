import { BlockElementPropsType } from '@media-components/draft-parser';
import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { ArticleMediaSection } from 'common/components/ArticleMediaSection';
import { useDraftContext } from 'common/components/ClusterContentOnDraft/context';
import { ArticleMediaSectionDataType } from 'common/components/ClusterContentOnDraft/draftModifier/generators/articleMediaSectionBlockGenerator';
import { IncrementCountersTrigger } from 'common/components/IncrementCountersTrigger';
import { selectClusterPageClusterFeed } from 'common/redux/pages/cluster/selectors';
import { selectTopicByClusterId } from 'common/redux/selectors';

/**
 * Компонент рендера блока драфта для медиа секции.
 * @param props - пропсы
 * @param props.data - объект со специфичными данными для блока:
 * - clusterFeedListIndex - индекс кластера;
 * - hasGallery - флаг, что есть галерея.
 */
const ArticleMediaSectionBlockComponent = function ArticleMediaSectionBlock({
  data,
}: BlockElementPropsType<ArticleMediaSectionDataType, unknown>) {
  const { pageId } = useDraftContext();

  const feedList = useSelector(selectClusterPageClusterFeed, shallowEqual);
  const clusterTopic = useSelector(
    selectTopicByClusterId(pageId),
    shallowEqual,
  );

  if (Number.isNaN(Number(data?.[0]?.clusterFeedListIndex)) || !pageId) {
    return null;
  }

  const { clusterFeedListIndex, hasGallery } = data![0];

  return (
    <>
      <IncrementCountersTrigger
        firstCluster={feedList?.[0] === pageId}
        clusterId={pageId}
        projectId={clusterTopic?.project_id}
      />
      <ArticleMediaSection
        clusterIndex={clusterFeedListIndex}
        clusterId={pageId}
        hasGallery={hasGallery}
      />
    </>
  );
};

export const ArticleMediaSectionBlock = memo(
  ArticleMediaSectionBlockComponent,
  (prev, next) =>
    prev?.data?.[0]?.clusterFeedListIndex ===
    next?.data?.[0]?.clusterFeedListIndex,
);
