import { selectCurrencyById } from 'common/redux/commonData/currencies/selectors';
import { CONSENSUS_CURRENCIES } from 'common/redux/commonData/widgets/consensusWidget/constants';
import { EUR_ID, USD_ID } from 'config/common/finance/constants';

import { selectPages } from '../selectors';

export const selectCurrencyPage = (state: AppState) =>
  selectPages(state).currency;

export const selectCurrencyPageSource = (state: AppState) =>
  selectCurrencyPage(state).source;

export const selectCurrencyPageCurrentCurrencyId = (state: AppState) =>
  selectCurrencyPage(state).currentCurrencyId;

export const selectCurrencyPageCurrentCurrencyData = (state: AppState) => {
  const currId = selectCurrencyPageCurrentCurrencyId(state);
  if (!currId) return undefined;

  return selectCurrencyById(currId)(state);
};

export const selectCurrencyPageCurrentCurrencyDataCharCode = (
  state: AppState,
) => selectCurrencyPageCurrentCurrencyData(state)?.charCode ?? '';

export const selectCurrencyPagePuids = (state: AppState) =>
  selectCurrencyPage(state).puids;

export const selectCurrecyPageIsLoading = (state: AppState) =>
  selectCurrencyPage(state).isLoading;

export const selectIsEurOrUsdPageCurrency = (state: AppState) => {
  const currentCurrencyId = selectCurrencyPageCurrentCurrencyId(state);

  return currentCurrencyId === USD_ID || currentCurrencyId === EUR_ID;
};

export const selectIsConsensusCurrency = (state: AppState) => {
  const currentCurrencyCharCode =
    selectCurrencyPageCurrentCurrencyDataCharCode(state);

  return CONSENSUS_CURRENCIES.some(
    (charCode) => charCode === currentCurrencyCharCode,
  );
};
