import { selectManyCluster } from 'common/redux/commonData/entries/selectors';

import { selectPages } from '../selectors';

export const selectAutotag = (state: AppState) => selectPages(state).autotag;

export const selectAutotagDate = (state: AppState) => selectAutotag(state).date;

export const selectAutotagFetchingState = (state: AppState) =>
  selectAutotag(state).fetching;

export const selectAutotagAlias = (state: AppState) =>
  selectAutotag(state).alias;

export const selectAutotagType = (state: AppState) => selectAutotag(state).type;

export const selectAutotagSeo = (state: AppState) => selectAutotag(state).seo;

export const selectAutotagPuids = (state: AppState) =>
  selectAutotag(state).puids;

export const selectAutotagId = (state: AppState) => selectAutotag(state).id;

export const selectAutotagRating = (state: AppState) =>
  selectAutotag(state).rating;

export const selectAutotagClusterIds = (state: AppState) =>
  selectAutotag(state).clusterIds;

export const selectAutotagCurrentPage = (state: AppState) =>
  selectAutotag(state).currentPage;

export const selectAutotagHasNextPage = (state: AppState) =>
  selectAutotag(state).hasNextPage;

export const selectAutotagClusters = (state: AppState) =>
  (selectManyCluster(selectAutotagClusterIds(state))(state).filter(Boolean) as (
    | Card
    | Cluster
  )[]) || [];
