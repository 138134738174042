import {
  useState,
  useEffect,
  useCallback,
  useRef,
  MutableRefObject,
} from 'react';

import { useScrollResize } from 'common/hooks/useScrollResize';

const ANIMATION_TIME = 300;
const INITIAL_REDIRECT_OPTIONS = {
  redirectPercent: 50,
  redirectCallback: () => true,
  redirectDelay: 0,
};

type UseRedirectPropsType<T> = {
  activeS2S: boolean;
  s2sRef: MutableRefObject<T | null> | null;
  link: string;
  redirectOptions?: {
    redirectPercent: number;
    redirectCallback?: () => void;
    redirectDelay?: number;
  };
};

/**
 * Хук анимации и редиректа
 * @param props - пропсы
 * @param props.activeS2S - флаг, доскролили ли до s2s и вылез ли он на нужный процент (redirectPercent).
 *  При доскролле происходи переход на урл (link)
 * @param props.s2sRef - ref на блок перед s2s, флаг для срабатывания s2s
 * @param props.link - ссылка для редиректа при s2s переходе по доскроллу
 * @param props.redirectOptions - опции редиректа
 * @param props.redirectOptions.redirectPercent - процент, после которого происходит s2s переход по доскроллу
 * @param props.redirectOptions.redirectCallback - callback, срабатывающий перед s2s переходом по доскроллу
 * @param props.redirectOptions.redirectDelay - время, на которое будет осуществлена задержка перед переходом на страницу,
 *  Если в момент задержки пользователь прокрутил страницу обратно, перехода не произойдет.
 */
export const useRedirect = <T extends Element>({
  activeS2S,
  s2sRef,
  link,
  redirectOptions: {
    redirectPercent = 50,
    redirectCallback = () => true,
    redirectDelay = 0,
  } = INITIAL_REDIRECT_OPTIONS,
}: UseRedirectPropsType<T>) => {
  const [activeRedirect, setActiveRedirect] = useState<boolean>(false);
  const [animation, setAnimation] = useState<boolean>(false);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (animation) {
      setTimeout(() => {
        setActiveRedirect(true);
      }, ANIMATION_TIME);
    }
  }, [animation]);

  useEffect(() => {
    if (activeRedirect) {
      if (redirectCallback) redirectCallback();
      window.location.href = link;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRedirect, redirectCallback]);

  const scrollResizeCallback = useCallback(() => {
    const { top = 0 } = s2sRef?.current?.getBoundingClientRect() || {};
    const clientHeight = window.innerHeight;
    const scrollPercent = (top * 100) / clientHeight;

    if (scrollPercent < redirectPercent && !timeoutIdRef.current) {
      timeoutIdRef.current = setTimeout(() => {
        const bodyNode = document.body;

        bodyNode.style.overflow = 'hidden';
        setAnimation(true);
      }, redirectDelay);

      return;
    }

    if (scrollPercent >= redirectPercent && timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
  }, [redirectDelay, redirectPercent, s2sRef]);

  useScrollResize(activeS2S ? scrollResizeCallback : null, { ms: 50 });

  return { animation, activeRedirect };
};
