import React, { memo } from 'react';
import Helmet from 'react-helmet';

import { MAX_VIDEO_PREVIEW } from 'config/common/maxVideoPreview';

type MetaMaxVideoPreviewPropTypes = {
  maxVideoPreview?: number;
};

/**
 * Компонент мета-информации для роботов по максимальной длине предпоказа видео
 * @param props - пропсы
 * @param props.maxVideoPreview - длина предпоказа видео.
 */
const MetaMaxVideoPreviewComponent = function MetaMaxVideoPreview({
  maxVideoPreview = MAX_VIDEO_PREVIEW,
}: MetaMaxVideoPreviewPropTypes) {
  return (
    <Helmet>
      <meta name="robots" content={`max-video-preview:${maxVideoPreview}`} />
    </Helmet>
  );
};

export const MetaMaxVideoPreview = memo(MetaMaxVideoPreviewComponent);
