import loadable from '@loadable/component';
import React from 'react';

import { setClusterItemsPuids } from 'common/redux/pages/clusterItems';
import {
  fetchClusterItemsCluster,
  fetchClusterItemsClusters,
} from 'common/redux/pages/clusterItems/asyncs';
import { selectClusterItemsCluster } from 'common/redux/pages/clusterItems/selectors';
import { selectProjectAlias } from 'common/redux/runtime/selectors';
import { appendPageDownloader } from 'common/routes/pageLoadable';
import { PAGE_TYPE } from 'config/common/router/typings';

import { IAppRoute } from '../typings';

const ClusterItemsDesktop = loadable(
  () => import('desktop/pages/ClusterItems'),
);
const ClusterItemsMobile = loadable(() => import('mobile/pages/ClusterItems'));

type ClusterItemsMatchRoute = {
  clusterId: string;
};

/**
 * Роут списка item'ом кластера.
 * @see https://auto.rambler.ru/roadaccidents/43529290-rossiyskiy-hokkeist-ustroil-dtp-i-sbezhal/items/
 * @param isMobile - флаг мобильной версии
 */
export const clusterItemsRoutes = (
  isMobile: SettingsType['isMobile'],
): IAppRoute<ClusterItemsMatchRoute> => ({
  name: PAGE_TYPE.clusterItems,
  exact: true,
  path: '/:topicAlias/:clusterId(\\d{6,10})\\-:clusterInfo/items/',
  render: isMobile
    ? () => <ClusterItemsMobile />
    : () => <ClusterItemsDesktop />,
  fetchData: async ({ dispatch, getState }, { params: { clusterId } }) => {
    const projectAlias = selectProjectAlias(getState());

    const promiseList: PromiseListType = [
      dispatch(fetchClusterItemsCluster({ clusterId })),
      dispatch(fetchClusterItemsClusters({ clusterId })),
    ];

    await Promise.all(
      appendPageDownloader({
        promiseList,
        loadableComponent: isMobile ? ClusterItemsMobile : ClusterItemsDesktop,
        chunkName: Object.keys({ ClusterItemsDesktop })[0],
      }),
    );

    const clusterItemsCluster = selectClusterItemsCluster(getState());

    dispatch(
      setClusterItemsPuids({
        projectAlias,
        topicAlias: clusterItemsCluster?.topicAlias || '',
      }),
    );
  },
});
