import { PayloadAction } from '@reduxjs/toolkit';

import { getTravelCountries } from 'api';
import { selectApiConfig } from 'common/redux/runtime/selectors';
import { createSlice } from 'common/redux/utils';

import { travelCountriesAdapter } from './adapter';

const LIMIT = 100;
const ORDER = 'name';
const INITIAL_STATE = travelCountriesAdapter.getInitialState({
  fetching: false,
  error: '',
});

/**
 * Слайс со странами для виджета путешествий.
 */
const travelCountriesSlice = createSlice({
  name: 'travelCountries',
  initialState: INITIAL_STATE,
  reducers: (create) => ({
    /**
     * Обновление списка городов для страны тк изначально может быть не полный список
     */
    updateCountryCities: create.reducer(
      (state, { payload }: PayloadAction<TravelRegionType>) => {
        travelCountriesAdapter.upsertOne(state, payload);
      },
    ),

    /**
     * Функция загрузки стран для путешествий.
     */
    fetchTravelCountries: create.asyncThunk(
      async (_props, { getState }) => {
        const apiConfig = selectApiConfig(getState() as AppState);

        const { data, error } = await getTravelCountries(apiConfig, {
          limit: LIMIT,
          order: ORDER,
        });

        if (error || !data) {
          throw error || new Error('Ошибка при получении списка стран');
        }

        return { data };
      },

      {
        pending: (state) => {
          state.fetching = true;
          state.error = '';
        },
        fulfilled: (state, { payload }) => {
          travelCountriesAdapter.upsertMany(state, payload.data);
          state.error = '';
        },
        rejected: (state, { error }) => {
          state.error = error.message || '';
        },
        settled: (state) => {
          state.fetching = false;
        },
      },
    ),
  }),
});

export const { updateCountryCities, fetchTravelCountries } =
  travelCountriesSlice.actions;

export const travelCountriesReducer = travelCountriesSlice.reducer;
