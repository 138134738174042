import { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from 'common/redux/utils';

type TravelWidgetStateType = {
  city: string;
  country: string;
  cities: [string, string][];
};

const INITIAL_STATE: TravelWidgetStateType = {
  city: '',
  country: '',
  cities: [],
};

/**
 * Слайс виджета путешествий.
 */
const travelWidgetSlice = createSlice({
  name: 'travelWidget',
  initialState: INITIAL_STATE,
  reducers: (create) => ({
    /**
     * Сохранение города выбранного пользователем
     * @param action.payload - алиас города.
     */
    setTravelCity: create.reducer(
      (state, { payload }: PayloadAction<string>) => {
        state.city = payload;
      },
    ),

    /**
     * Сохранение страны выбранной пользователем
     * @param action.payload - алиас страны.
     */
    setTravelCountry: create.reducer(
      (state, { payload }: PayloadAction<string>) => {
        state.country = payload;
        state.city = '';
      },
    ),

    /**
     * Сохранение списка городов выбранной страны
     * @param action.payload - массив городов.
     */
    setTravelCities: create.reducer(
      (state, { payload }: PayloadAction<[string, string][]>) => {
        state.cities = payload;
      },
    ),

    /**
     * Сохранение всей информации для трэвэл виджета
     * @param action.payload.city - город,
     * @param action.payload.country - страна,
     * @param action.payload.cities - массив городов.
     */
    setTravelInfo: create.reducer(
      (state, { payload }: PayloadAction<TravelWidgetStateType>) => {
        state.country = payload.country;
        state.city = payload.city;
        state.cities = payload.cities;
      },
    ),
  }),
});

export const {
  setTravelCity,
  setTravelCountry,
  setTravelCities,
  setTravelInfo,
} = travelWidgetSlice.actions;

export const travelWidgetReducer = travelWidgetSlice.reducer;
