import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { getLocation, getLocationAuto } from 'api';
import { selectApiConfig, selectIsDebug } from 'common/redux/runtime/selectors';

/**
 * Получение данных о локации пользователя
 */
const fetchLocation = createAsyncThunk(
  'location/request',
  async (_props, { getState, signal }) => {
    const forceGeoId = Cookies.get('geoid');
    const isDebug = selectIsDebug(getState() as AppState);

    const { data, error } =
      forceGeoId && isDebug
        ? await getLocation(
            selectApiConfig(getState() as AppState),
            forceGeoId,
            signal,
          )
        : await getLocationAuto(
            selectApiConfig(getState() as AppState),
            signal,
          );

    if (!data || error) {
      throw error;
    }

    return data;
  },
);

type LocationState = {
  // Данные о локации пользователя.
  data: LocationData | null;
} & InitialState;

/**
 * Слайс локации пользователя.
 * Получает и хранит данные о локации пользователя.
 *
 */
const locationSlice = createSlice({
  name: 'location',
  initialState: {
    fetching: false,
    data: null,
    error: '',
  } as LocationState,
  reducers: {
    handleChange: (state, { payload }: PayloadAction<LocationData>) => {
      state.data = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchLocation.pending, (state) => {
      state.fetching = true;
    });

    builder.addCase(fetchLocation.fulfilled, (state, { payload: data }) => {
      state.fetching = false;
      state.data = data;
    });

    builder.addCase(fetchLocation.rejected, (state, error) => {
      state.error = error.error?.message;
      state.fetching = false;
    });
  },
});

export const locationReducer = locationSlice.reducer;

export const { handleChange } = locationSlice.actions;

export { fetchLocation };
