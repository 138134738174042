import cn from 'classnames';
import React, { useMemo } from 'react';

import { useTopicsMenuReachGoal } from 'common/components/metrics/utils';
import { RamblerTabs } from 'common/components/RamblerComponents/RamblerTabs';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { StateTopicType } from 'common/redux/commonData/topics/typings';
import { selectIsMobile } from 'common/redux/runtime/selectors';
import { useAppSelector } from 'store/hooks';

import { useTopicsMenu } from '../useTopicsMenu';

import s from './styles.module.css';

const TAIL_SPACE_MOBILE = 10;

type TopicsMenuRedesignPropsType = {
  projectId: ProjectType['id'] | undefined;
  activeTopicId: StateTopicType['id'] | undefined | null;
  className?: string;
};

/**
 * Меню рубрик редизайн версия
 * @param props - пропсы
 * @param props.projectId - id вертикали;
 * @param props.activeTopicId - id топика к которому относится страница;
 * @param props.className - дополнительные стили.
 */
const TopicsMenuRedesignComponent = function TopicsMenuRedesign({
  projectId,
  activeTopicId,
  className,
}: TopicsMenuRedesignPropsType) {
  const isMobile = useAppSelector(selectIsMobile);
  const topics = useTopicsMenu(projectId);

  const { menuWrapperRef, reachGoalClickCallback } = useTopicsMenuReachGoal();

  const menuItems = useMemo(
    () =>
      topics.map(({ id, alias, name }) => ({
        url: `/${alias}/`,
        value: id,
        label: name,
      })),
    [topics],
  );

  return (
    <nav ref={menuWrapperRef} className={s.root}>
      <RamblerTabs
        items={menuItems}
        value={activeTopicId ?? undefined}
        isToggles
        size="small"
        display="normal"
        ariaLabel="Меню рубрик"
        className={cn(s.tabs, className, !isMobile && s.desktop)}
        isScrollable={isMobile}
        tailSpace={isMobile ? TAIL_SPACE_MOBILE : 0}
        onChange={reachGoalClickCallback}
      />
    </nav>
  );
};

export const TopicsMenuRedesign = withErrorBoundary(
  TopicsMenuRedesignComponent,
);
