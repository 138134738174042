import cn from 'classnames';
import React from 'react';

import { Link } from 'common/components/Link';
import { RamblerImage } from 'common/components/RamblerComponents/RamblerImage';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { defaultCallback } from 'config/common/common';

import s from './styles.module.css';

const IMAGE_HEIGHT = 25;

type EditorInfoPropsType = {
  utm: string;
  editor: FullEditorType;
  withIcon: boolean;
  customStyles?: StylesType;
  onClick?: () => void;
};

/**
 * Карточка редактора
 * @param props - пропсы
 * @param props.editor - инфа по редактору;
 * @param props.editor.alias - алиас редактора;
 * @param props.editor.image - объект изображения автора;
 * @param props.editor.name - имя редактора;
 * @param props.utm - utm метки;
 * @param props.withIcon - нужно ли показывать иконку автора;
 * @param props.customStyles - кастомные стили;
 * @param props.onClick - обработчик клика.
 */
const EditorInfoComponent = function EditorInfo({
  utm,
  withIcon,
  editor: { alias: editorAlias, image, name: editorName },
  customStyles = {},
  onClick = defaultCallback,
}: EditorInfoPropsType) {
  const top100Attribute = useTop100AttributeWithValue('editor');

  return (
    <div className={cn(s.root, customStyles.editorRoot)} {...top100Attribute}>
      {withIcon && image?.url && (
        <RamblerImage
          height={IMAGE_HEIGHT}
          src={image.url}
          alt={editorName}
          className={cn(s.avatar, customStyles.editorAvatar)}
          isS3={image.s3}
          data-test="editor-info-image"
          isLazy
        />
      )}
      <Link
        ariaLabel={`Открыть информацию о редакторе ${editorName}`}
        href={`/editors/${editorAlias}/?${utm}`}
        force
        className={cn(s.name, customStyles.editorName)}
        data-test="editor-info-link"
        onClick={onClick}
      >
        {editorName}
      </Link>
    </div>
  );
};

export const EditorInfo = withErrorBoundary(EditorInfoComponent);
