import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { CarouselDesktop } from 'common/components/Carousel/CarouselDesktop';
import { getFilteredClusters } from 'common/components/Carousel/utils';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import {
  selectAllEntries,
  selectClusterById,
} from 'common/redux/commonData/entries/selectors';
import { selectTopicsByClusters } from 'common/redux/commonData/topics/selectors';
import { selectClusterPageClusterFeed } from 'common/redux/pages/cluster/selectors';
import { selectTopicPageClusterIds } from 'common/redux/pages/topic/selectors';
import { useAppSelector } from 'store/hooks';

import s from './styles.module.css';

type GalleryPropsType = {
  clusterId: Cluster['id'] | undefined;
  clusterIndex: number;
  clusterPuids: PuidsType | undefined;
  shouldDisableAds?: boolean;
};

/**
 * Галерея кластера с описанием и ссылками на источники фото
 * @param props - пропсы
 * @param props.clusterId - id кластера;
 * @param props.clusterIndex - индекс кластера;
 * @param props.clusterPuids - рекламные пуиды кластера;
 * @param props.shouldDisableAds - флаг отключения рекламы в галлерее
 */
const GalleryComponent = function Gallery({
  clusterId,
  clusterIndex,
  clusterPuids,
  shouldDisableAds,
}: GalleryPropsType) {
  const cluster = useSelector(selectClusterById(clusterId), shallowEqual);
  const clusterFeed = useSelector(selectClusterPageClusterFeed, shallowEqual);
  const topicClusterIds = useSelector(selectTopicPageClusterIds, shallowEqual);
  const entries = useSelector(selectAllEntries, shallowEqual);

  const top100Attribute = useTop100AttributeWithValue('gallery');

  const filteredClusters = getFilteredClusters({
    clusterFeed,
    topicClusterIds,
    entries,
  });

  const topicsByClusters = useAppSelector(
    selectTopicsByClusters(filteredClusters),
    shallowEqual,
  );

  if (!cluster) {
    return null;
  }

  const recommendedClusters = filteredClusters.map((item) => ({
    ...item,
    topicInfo: topicsByClusters.find((topic) => topic?.id === item.mainTopicId),
  }));

  return (
    <figure className={s.gallery} {...top100Attribute}>
      <CarouselDesktop
        cluster={cluster}
        clusterIndex={clusterIndex}
        clusterPuids={clusterPuids}
        recommendedClusters={recommendedClusters}
        shouldDisableAds={shouldDisableAds}
      />
    </figure>
  );
};

export const Gallery = withErrorBoundary(GalleryComponent);
