import React, { useState, useEffect, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { RouteConfig } from 'react-router-config';
import { Switch } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import {
  incrementReloadKey,
  resetBannersState,
  setPageName,
} from 'common/redux/appController';
import { selectPageName } from 'common/redux/appController/selectors';
import { selectIsMobile, selectRuntime } from 'common/redux/runtime/selectors';
import { NEWS_COLOR } from 'config/common/css';
import { PAGE_TYPE } from 'config/common/router/typings';
import { clientFetchData as desktopClientFetchData } from 'desktop/redux/fetch';
import { clientFetchData as mobileClientFetchData } from 'mobile/redux/fetch';
import { incrementCounters } from 'utils/counters/init/client';

import { checkShouldPreventScroll } from './utils';

import s from './styles.module.css';

type PageSwitchPropsType = {
  children: React.ReactNode | React.ReactNode[];
};

const DATA_LOADED_PROGRESS = 50;
const MINOR_LOADING_PROGRESS = 10;

let refLoadingBar: LoadingBar | null = null;

const onChangeRefLoadingBar = (ref: LoadingBar) => {
  refLoadingBar = ref;
};

const setProgressBy = (progress: number) => refLoadingBar?.add(progress);

/**
 * Обертка над React-router Switch с LoadingBar и плавным переходом между страницами
 * @param props - пропсы
 * @param props.children - массив компонентов React-router Route.
 */
const PageSwitchComponent = function PageSwitch({
  children,
}: PageSwitchPropsType) {
  const dispatch = useDispatch();
  const location = useLocation();

  const runtime = useSelector(selectRuntime, shallowEqual);
  const isMobile = useSelector(selectIsMobile);
  const previousPageName = useSelector(selectPageName);

  const [relevantLocation, setRelevantLocation] = useState(location);

  const firstRenderRef = useRef(true);

  const clientFetchData = isMobile
    ? mobileClientFetchData
    : desktopClientFetchData;

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;

      return;
    }

    refLoadingBar?.staticStart(0);

    dispatch(
      clientFetchData(location.pathname, (router: RouteConfig) => {
        // Загрузка данных самая долгая, поэтому она больше всего дергает ползунок
        setProgressBy(DATA_LOADED_PROGRESS);

        setRelevantLocation(location);

        const currentPageName = router.name;

        if (
          previousPageName === PAGE_TYPE.cluster &&
          currentPageName === PAGE_TYPE.cluster
        ) {
          refLoadingBar?.complete();

          return;
        }

        dispatch(resetBannersState());

        // Все остальные события прогресса открытия страницы разбросаны по категориям
        setProgressBy(MINOR_LOADING_PROGRESS);

        dispatch(incrementReloadKey());

        setProgressBy(MINOR_LOADING_PROGRESS);

        incrementCounters({
          runtime,
          type: router.name,
          pageName: currentPageName,
        });

        setProgressBy(MINOR_LOADING_PROGRESS);

        /**
         * Принудительный скролл требуется, чтобы юзера не редиректило в середину другой страницы.
         * Скролл жесткий, так как анимированный скролл срабатывает только после загрузки всех данных.
         * В рамках NEWS-11408 было принято решение, убрать отступ по y, чтобы новый топлайн был виден полностью.
         */
        if (!checkShouldPreventScroll(previousPageName, currentPageName)) {
          window.scrollTo(0, 0);
        }

        refLoadingBar?.complete();

        if (previousPageName !== currentPageName) {
          dispatch(setPageName(currentPageName));
        }
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <div className={s.loadingBar}>
        <LoadingBar
          height={1}
          color={NEWS_COLOR}
          onRef={onChangeRefLoadingBar}
        />
      </div>
      <Switch location={relevantLocation}>{children}</Switch>
    </>
  );
};

export const PageSwitch = withErrorBoundary(PageSwitchComponent);
