import { selectCardById } from 'common/redux/commonData/entries/selectors';
import {
  selectRegionById,
  selectRegionClustersIds,
} from 'common/redux/commonData/regions/selectors';
import { selectWidgetsTopMainClusterIDs } from 'common/redux/commonData/widgets/topMainWidget/selectors';
import { selectWidgetsTopProjectClusterIDs } from 'common/redux/commonData/widgets/topProjectWidget/selectors';
import { selectProjectId } from 'common/redux/runtime/selectors';

import { selectPages } from '../selectors';

export const selectRegionPage = (state: AppState) => selectPages(state).region;

export const selectRegionPageRegionId = (state: AppState) =>
  selectRegionPage(state).regionId;

export const selectRegionPageItem = (state: AppState) =>
  selectRegionById(selectRegionPageRegionId(state))(state);

export const selectRegionPageClustersIds = (state: AppState) =>
  selectRegionClustersIds(selectRegionPageRegionId(state))(state);

export const selectRegionPageCluster =
  (clusterIds: Card['id'][]) => (state: AppState) =>
    clusterIds?.map((clusterId) => selectCardById(clusterId)(state)) || [];

export const selectRegionPageClusterInCardData = (state: AppState) =>
  selectRegionPageCluster(
    selectRegionPageClustersIds(state).map((id) => `${id}`),
  )(state);

export const selectRegionPagePuids = (state: AppState) =>
  selectRegionPage(state).puids;

export const selectRegionPageRecommendClusterIds = (state: AppState) =>
  selectRegionPage(state).recommendClusterIds;

export const selectRegionPageLoadingState = (state: AppState) =>
  selectRegionPage(state).fetching;

export const selectRegionPageErrorState = (state: AppState) =>
  selectRegionPage(state).error;

export const selectRegionPageHasNextPage = (state: AppState) =>
  selectRegionPage(state).hasNextPage;

export const selectRegionExcludedClustersIds = (state: AppState) => {
  const projectId = selectProjectId(state);
  const region = selectRegionPageItem(state);
  const regionClustersIds = selectRegionClustersIds(region?.id)(state);
  const topProjectWidgetsClusterIds =
    selectWidgetsTopProjectClusterIDs(projectId)(state);
  const topMainWidgetsClustersIds = selectWidgetsTopMainClusterIDs(state);
  const regionRecommendClustersIds = selectRegionPageRecommendClusterIds(state);

  return [
    ...regionClustersIds,
    ...topProjectWidgetsClusterIds,
    ...topMainWidgetsClustersIds,
    ...regionRecommendClustersIds,
  ];
};
