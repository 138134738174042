export type IndicesAndQuotesWidgetType = {
  id: string;
  data: { value: number; diff: number } | null;
} & InitialState;

export enum IndicesAndQuotesMarketsName {
  forex = 'forex',
  bcs = 'bcs',
  moex = 'moex',
}

export enum IndicesAndQuotesPeriod {
  M1 = 'M1',
  D1 = 'D1',
  W1 = 'W1',
  H1 = 'h1',
  H60 = '60',
  M = 'M',
  D = 'D',
  W = 'W',
}

export enum IndicesAndQuotesCharCode {
  XAUUSD = 'XAUUSD',
  BRN = 'BRN',
  RTSI = 'RTSI',
}
