import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { PROJECT_ALIASES } from 'config/common/project/typings';
import { PAGE_TYPE } from 'config/common/router/typings';

import { fetchThemeClusters, fetchThemeInfo } from './asyncs';

type StateThemeType = {
  puids: PuidsType;
  info: ATThemeInfo;
  clustersIds: Card['id'][];
  currentPage: number;
  hasNextPage: boolean;
} & InitialState;

const themeSlice = createSlice({
  name: PAGE_TYPE.theme,
  initialState: {
    puids: {},
    info: {} as ATThemeInfo,
    clustersIds: [],
    currentPage: 1,
    hasNextPage: false,
    fetching: false,
    error: '',
  } as StateThemeType,
  reducers: {
    /**
     * Генерация puids для страницы.
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload - alias проекта, на котором запускается приложение.
     */
    setThemePuids: (state, { payload }: PayloadAction<PROJECT_ALIASES>) => {
      const puidName = getPuidName(payload);

      state.puids = {
        puid6: `${puidName}_theme`.toUpperCase(),
        puid18: `${puidName}_theme_main`.toUpperCase(),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchThemeInfo.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchThemeInfo.fulfilled, (state, { payload }) => {
        state.info = payload;
        state.fetching = false;
      })
      .addCase(fetchThemeInfo.rejected, (state, error) => {
        state.fetching = false;
        state.error = error.error.message;
      });
    builder
      .addCase(fetchThemeClusters.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchThemeClusters.fulfilled, (state, { payload }) => {
        const { clusterIds, currentPage, hasNextPage } = payload;

        state.clustersIds = [...state.clustersIds, ...clusterIds];
        state.currentPage = currentPage;
        state.hasNextPage = hasNextPage;
        state.fetching = false;
      })
      .addCase(fetchThemeClusters.rejected, (state, error) => {
        state.fetching = false;
        state.error = error.error.message;
      });
  },
});

export const themeReducer = themeSlice.reducer;

export const { setThemePuids } = themeSlice.actions;
