import { textToSpeech } from 'utils/textToSpeech';

import { ChangeSpeak } from './typings';

/**
 * Функция запуска проигрывания переданного заголовка и тела кластера.
 * @param props - пропсы
 * @param props.clusterId - id проигрываемого кластера;
 * @param props.clusterTitle - заголовок проигрываемого кластера;
 * @param props.clusterBody - тело проигрываемого кластера;
 * @param props.speakingClusterId - id последнего проигранного кластера;
 * @param props.speakingIsPaused - флаг, что проигрывание приостановлено;
 * @param props.onChange - коллбек, принимающий новое состояние проигрыша звука.
 */
export const playSpeech = ({
  clusterId,
  clusterTitle,
  clusterBody,
  speakingClusterId: speakingClusterID,
  speakingIsPaused,
  onChange,
}: {
  clusterId: Cluster['id'];
  clusterTitle: Cluster['longTitle'];
  clusterBody: Cluster['body'];
  speakingClusterId: ClusterSpeakingType['clusterId'];
  speakingIsPaused: ClusterSpeakingType['pause'];
  onChange: ChangeSpeak;
}) => {
  const callback = () =>
    onChange({
      play: false,
      pause: false,
      clusterId,
    });

  onChange({
    play: true,
    pause: false,
    clusterId,
  });

  if (clusterId === speakingClusterID && speakingIsPaused) {
    textToSpeech.resumeSpeech(callback);

    return;
  }

  if (clusterId !== speakingClusterID) {
    textToSpeech.stopSpeech();
  }

  textToSpeech.addClusterTextToSpeech(clusterTitle, clusterBody);
  textToSpeech.startSpeech(callback);
};

/**
 * Функция остановки проигрывания звука для переданного кластера.
 * @param onChange - коллбек, принимающий новое состояние проигрыша звука.
 */
export const pauseSpeech = (onChange: ChangeSpeak) => {
  textToSpeech.pauseSpeech();
  onChange({
    play: false,
    pause: true,
  });
};
