import {
  ErrorBoundary as SentryErrorBoundary,
  ErrorBoundaryProps as SentryErrorBoundaryProps,
  withProfiler,
} from '@sentry/react';
import React, { ReactNode, Suspense, memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectIsDebug, selectIsMobile } from 'common/redux/runtime/selectors';
import { getDeviceType } from 'config/common/devices';
import { metricsBatch } from 'server/collectors/prometheus/utils/metricsBatch';
import { COUNTERS_NAMES, ERROR_TYPE } from 'server/typings';

/**
 * Вспомогательная функция для определения типа ошибки.
 * @param error – данные об ошибке
 */
const getErrorType = (error: Error): ERROR_TYPE => {
  if (error instanceof TypeError) {
    return ERROR_TYPE.Type;
  }

  if (error instanceof ReferenceError) {
    return ERROR_TYPE.Reference;
  }

  if (error instanceof RangeError) {
    return ERROR_TYPE.Range;
  }

  return ERROR_TYPE.Unknown;
};

type ErrorBoundaryPropsType = {
  children: ReactNode;
  componentName: string;
  disableSuspense?: boolean;
  FallbackComponent?: React.ReactElement;
};

/**
 * Компонент-предохранитель для отлова ошибок в дереве дочерних компонентов и отправки ошибки в sentry
 * @param props - пропсы
 * @param props.children - компоненты-потомки на которых будет происходить перехват ошибок;
 * @param props.componentName - название компонента для отправки метрик, нужен тк componentStack показывает на Suspense;
 * @param props.disableSuspense - флаг принудительного отключения Suspense в случаях, когда код внутри компонента может
 *  быть принудительно перезаписан. Например, для рекламы. Использовать в крайних случаях;
 * @param props.FallbackComponent - запасной компонент для рендера во время возникновения ошибки.
 */
const ErrorBoundaryComponent = function ErrorBoundary({
  children,
  componentName,
  disableSuspense,
  FallbackComponent,
}: ErrorBoundaryPropsType) {
  const isMobile = useSelector(selectIsMobile);
  const isDebug = useSelector(selectIsDebug);

  const [isError, setIsError] = useState(false);

  const onError = useCallback<NonNullable<SentryErrorBoundaryProps['onError']>>(
    (error, componentStack, eventId) => {
      if (__DEV__ || isDebug) {
        console.error(`${componentName}: ${error}`, componentStack, eventId);
      }

      const deviceType = getDeviceType(isMobile);

      setIsError(true);

      // Batch ошибки компонента
      metricsBatch.pushToCounters<COUNTERS_NAMES.ComponentError>({
        counterName: COUNTERS_NAMES.ComponentError,
        params: {
          deviceType,
          errorType: getErrorType(error as Error),
          componentName,
        },
      });
    },
    [isDebug, isMobile, componentName],
  );

  const suspenseWrapper = (
    <Suspense fallback={FallbackComponent || null}>{children}</Suspense>
  );

  const commonWrapper = isError ? FallbackComponent : children;

  return (
    <SentryErrorBoundary onError={onError} fallback={FallbackComponent}>
      {disableSuspense ? commonWrapper : suspenseWrapper}
    </SentryErrorBoundary>
  );
};

export const ErrorBoundary = withProfiler(memo(ErrorBoundaryComponent));
