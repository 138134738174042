import { ImageObject, VideoObject, WithContext } from 'schema-dts';

import { DEFAULT_IMAGE } from 'config/common/common';

const PLAYER_URL = 'https://vp.rambler.ru/player/1.39.2/player.html#id=1987304';

/**
 * Хук для получения item для VideoObject
 * @param cluster - инфо о кластере.
 */
export const getItemForVideoObject = (cluster: Cluster) => {
  const { annotation, video, longTitle, publicationTime } = cluster;

  const detailsImage = video?.preview;
  const detailsDuration = video?.duration;

  const url = video?.url;

  return {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name: longTitle,
    description: annotation,
    uploadDate: publicationTime,
    url,
    contentUrl: url,
    embedUrl: PLAYER_URL,
    isFamilyFriendly: 'True',
    thumbnail: (detailsImage || DEFAULT_IMAGE) as unknown as ImageObject,
    thumbnailUrl: detailsImage || DEFAULT_IMAGE,
    ...(detailsDuration && {
      duration: `T${Math.floor(detailsDuration / 1000)}S`,
    }),
  } as WithContext<VideoObject>;
};
