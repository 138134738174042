import { createAsyncThunk } from '@reduxjs/toolkit';

import { getEditor, getEditorForCluster, getEditorsByProject } from 'api';
import { selectApiConfig } from 'common/redux/runtime/selectors';

/**
 * Функция загрузки редакторов для проекта.
 * @param projectId - id проекта.
 */
export const fetchEditorsByProjectId = createAsyncThunk(
  'fetchEditorsByProjectId',
  async ({ projectId }: { projectId: ProjectType['id'] }, { getState }) => {
    const api = selectApiConfig(getState() as AppState);

    const { data: editors, error } = await getEditorsByProject(api, projectId);

    if (error || !editors) {
      throw (
        error ||
        new Error(`Ошибка получения редакторов про id проекта - ${projectId}`)
      );
    }

    return editors;
  },
);

/**
 * Функция закрузки редактора по алиасу.
 * @param editorAlias - алиас редактора.
 */
export const fetchEditor = createAsyncThunk(
  'fetchEditor',
  async (
    { editorAlias }: { editorAlias: EditorType['alias'] },
    { getState },
  ) => {
    const api = selectApiConfig(getState() as AppState);

    const { data: editor, error } = await getEditor(api, editorAlias);

    if (error || !editor) {
      throw (
        error ||
        new Error(`Ошибка получение редактора по алиасу ${editorAlias}`)
      );
    }

    return editor;
  },
);

/**
 * Функция загрузки редактора кластера.
 * Для тех кластеров, у которых редактора нет, функция будет выпадать с внутренней ошибкой.
 * @param clusterId - id кластера, для которого загружаются редаткоры;
 * @param callback - коллбек, которому возвращаются сокращенные данные о редактора кластера.
 */
export const fetchEditorByCluster = createAsyncThunk(
  'fetchEditorByCluster',
  async (
    {
      clusterId,
      callback,
    }: {
      clusterId: Cluster['id'];
      callback?: (resource: EditorType) => Promise<void> | void;
    },
    { getState },
  ) => {
    const api = selectApiConfig(getState() as AppState);

    const { data: editor, error } = await getEditorForCluster(api, clusterId);

    if (error || !editor) {
      throw (
        error ||
        new Error(`Ошибка при получении редактора по кластеру: ${clusterId}`)
      );
    }

    if (callback) await callback(editor);

    return editor;
  },
);
