import { selectCommonData } from '../selectors';

import { banksAdapter, banksCharToIdAdapter } from './adapter';
import { BankType } from './typings';

const selectors = banksAdapter.getSelectors(
  (state: AppState) => selectCommonData(state).banks,
);

export const selectBanks = (state: AppState) => selectCommonData(state).banks;

export const selectBankById = (bankId: BankType['id']) => (state: AppState) =>
  selectors.selectById(state, bankId) || null;

export const selectBanksByIds =
  (banksIds: BankType['id'][] | null) => (state: AppState) =>
    (banksIds
      ?.map((id) => id && selectBankById(id)(state))
      .filter(Boolean) as BankType[]) || [];

export const selectBankIdByCode =
  banksCharToIdAdapter.createAliasToIdSelector(selectBanks);
