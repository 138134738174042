import { selectCardById } from '../../entries/selectors';
import { selectWidgets } from '../selectors';

export const selectWidgetsTopMain = (state: AppState) =>
  selectWidgets(state).topMainWidget;

export const selectWidgetsTopMainClusterIDs = (state: AppState) =>
  selectWidgetsTopMain(state).clusterIds || [];

export const selectWidgetsTopMainCluster =
  (clusterIds: Card['id'][]) => (state: AppState) =>
    clusterIds.map((clusterId) => selectCardById(clusterId)(state)) || [];

export const selectWidgetsTopMainClusterInCardData = (state: AppState) =>
  selectWidgetsTopMainCluster(selectWidgetsTopMainClusterIDs(state))(state);

export const selectWidgetsTopMainLoadingState = (state: AppState) =>
  selectWidgetsTopMain(state).fetching;

export const selectWidgetsTopMainErrorState = (state: AppState) =>
  selectWidgetsTopMain(state).error;
