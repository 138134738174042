import React from 'react';

import { getVideoParams } from 'common/components/VideoPlayer/utils/getVideoParams';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { useCurrentCluster } from 'common/hooks/useCurrentCluster';

import { VideoPlayerBody } from './components/VideoPlayerBody';

export const VIDEO_DATA_TYPE = {
  broadcast: 7,
  common: 6,
};

type VideoPlayerPropsType = {
  embedCode: string | undefined;
  recordId?: string | number;
  puids: PuidsType | undefined;
  type?: 'default' | 'multi';
  styles?: StylesType;
  needMiniPlayer?: boolean;
  miniPlayerStopRef?: React.MutableRefObject<HTMLElement | null>;
  isBroadcast: boolean;
  height?: number;
  clusterId: Card['id'];
  clusterUrl: string;
  isAd?: boolean;
};

/** https://jira.rambler-co.ru/browse/NEWS-7927 - основная задача */
/** https://jira.rambler-co.ru/browse/NEWS-8446 - задача из-за которой закомментирован код */
// const DO_DIR1_TOPIC_ID = [465, 151, 153, 145, 144, 150, 154, 143, 147, 148, 157, 160, 158, 146, 159, 152, 156, 226, 219, 240, 504];

/**
 * Компонент RamblerPlayer для видео от EaglePlatform
 * @see https://vp.rambler.ru/docs/player/index.html
 * @param props - пропсы
 * @param props.embedCode - html-код видео плеера;
 * @param props.recordId - id видео кластера;
 * @param props.puids - рекламные пуиды;
 * @param props.styles - дополнительные стили;
 * @param props.isBroadcast - флаг, что это видеотрансляция;
 * @param props.height - фиксированная высота видео;
 * @param props.clusterId - id кластера, которому принадлежит плеер;
 * @param props.clusterUrl - URL кластера, в который встроен плеер;
 * @param props.isAd - флаг, что это рекламное видео.
 */
const VideoPlayerComponent = function VideoPlayer({
  embedCode,
  recordId,
  puids,
  styles,
  isBroadcast = false,
  height,
  clusterId,
  clusterUrl,
  isAd = false,
}: VideoPlayerPropsType) {
  /**
   * Не проставляем ad_template_id для данных рубрик
   * https://jira.rambler-co.ru/browse/NEWS-8446
   */
  const {
    recordId: recordIdFromParams,
    multiplayerFilterId,
    playerTemplateId,
    sspJparams,
    previewComment,
  } = getVideoParams(embedCode, puids, isAd);

  const { isCurrentCluster } = useCurrentCluster(String(clusterId));

  return (
    <VideoPlayerBody
      clusterUrl={clusterUrl}
      recordId={recordId || recordIdFromParams}
      multiplayerFilterId={multiplayerFilterId}
      playerTemplateId={playerTemplateId}
      sspJparams={sspJparams}
      previewComment={previewComment}
      height={height}
      isLive={isBroadcast}
      isCurrentCluster={isCurrentCluster}
      styles={styles}
    />
  );
};

export const VideoPlayer = withErrorBoundary(VideoPlayerComponent);
