import { createAsyncThunk } from '@reduxjs/toolkit';

import { getCommentsByClusterID, getRecommendedClusters } from 'api';
import { selectPageName } from 'common/redux/appController/selectors';
import {
  selectAdtech,
  selectApiConfig,
  selectDomainConfig,
  selectIsHumanCenteredStrategyStateActivated,
  selectIsBot,
  selectProjectId,
  selectRuid,
  selectUserId,
  selectVariables,
} from 'common/redux/runtime/selectors';
import { selectPageTopic } from 'common/redux/selectors';
import { RCM_BLOCK_TYPE } from 'config/common/rcm/typings';
import { RUID_MOCK } from 'config/common/recommend';
import { adaptClusterToCardRcm } from 'utils/adapters';

import { addManyEntries } from '../../entries';
import { selectClusterIsArticle } from '../../entries/selectors';
import { addRecommends } from '../../recommendedClusters';

const FETCHING_RCM_LIMIT = 5;
const FETCHING_RCM_REDESIGN_LIMIT = 7;

type FetchRecommendedNewsPropsType = {
  clusterId: Card['id'];
  itemExcludedIds: Card['id'][];
  sessionId?: string;
};

/**
 * Функция загрузки новостей для виджета рекоммендаций.
 * @param clusterId - id кластера, для которого грузятся рекоммендации;
 * @param itemExcludedIds - список id кластеров, которые будут исключены из выдачи рекоммендаций.
 * @param sessionId - id сессии общения с рекомендами
 */
export const fetchRecommendedNews = createAsyncThunk(
  'widgets/fetchRecommendedNews',
  async (
    { clusterId, itemExcludedIds, sessionId }: FetchRecommendedNewsPropsType,
    { getState, dispatch },
  ) => {
    /* Использовать аккуратно - стейт перестает динамически обновляться */
    const state = getState() as AppState;

    const variables = selectVariables(state);
    const domainConfig = selectDomainConfig(state);
    const projectId = selectProjectId(state);
    const forceRedesign = selectIsHumanCenteredStrategyStateActivated(state);
    const isBot = selectIsBot(state);
    const adtech = selectAdtech(state);
    const userId = selectUserId(state);
    const ruid = selectRuid(state);
    const pageTopic = selectPageTopic(state);
    const apiConfig = selectApiConfig(state);
    const pageName = selectPageName(state);
    const isArticle = selectClusterIsArticle(clusterId)(state);

    const rcmBlockType = isArticle
      ? RCM_BLOCK_TYPE.greenTopNowDesktop
      : RCM_BLOCK_TYPE.topNowDesktop;

    const blockId = adtech.recommendBlockID[rcmBlockType];

    const { data, error } = await getRecommendedClusters({
      blockId,
      xuid: ruid || (__DEV__ ? RUID_MOCK : ''),
      itemId: clusterId,
      itemExcludedIds,
      limit: forceRedesign ? FETCHING_RCM_REDESIGN_LIMIT : FETCHING_RCM_LIMIT,
      userId,
      pageName,
      isBot,
      category: pageTopic?.name,
      apiConfig,
      sessionID: sessionId,
    });

    if (error || !data) {
      throw error;
    }

    dispatch(addRecommends({ data, rcmBlockType }));

    const clusterIds = data.recommendations.map((item) => item.itemID);

    const { data: comments, error: commentsError } =
      await getCommentsByClusterID(apiConfig, clusterIds);

    if (commentsError || !comments) {
      throw commentsError;
    }

    const cards = data.recommendations.map((cluster, index) =>
      adaptClusterToCardRcm({
        cluster,
        projectId,
        domainConfig,
        variables,
        commentsCount: comments[index].comments_count,
      }),
    );

    dispatch(addManyEntries(cards));

    // slice оставляем тк ручка лагает и иногда присылает больше рекомендаций чем запрашиваем
    return {
      clusterIds: cards
        .map(({ id }) => id)
        .slice(
          0,
          forceRedesign ? FETCHING_RCM_REDESIGN_LIMIT : FETCHING_RCM_LIMIT,
        ),
      sessionId: data.sessionID,
    };
  },
);
