import { createAsyncThunk } from '@reduxjs/toolkit';

import { getRegionClusters, getRegionInfo } from 'api';
import { upsertEntries, addEntries } from 'common/redux/commonData/entries';
import {
  selectApiConfig,
  selectProjectId,
  selectVariables,
  selectDomainConfig,
} from 'common/redux/runtime/selectors';
import { adaptClustersToCards } from 'utils/adapters';

import { selectRegionAliasToId } from './selectors';

type FetchRegionClustersType = {
  alias: ATRegion['alias'];
  page?: number;
  limit?: number;
};

/**
 * Получение cписка кластеров для региона
 * @param params.alias - alias региона
 * @param params.limit - количество кластеров на странице
 */
export const fetchRegionClusters = createAsyncThunk(
  'fetchRegionClusters',
  async ({ alias, limit }: FetchRegionClustersType, { getState, dispatch }) => {
    const state = getState() as AppState;

    const apiConfig = selectApiConfig(state);
    const domainConfig = selectDomainConfig(state);
    const variables = selectVariables(state);
    const projectId = selectProjectId(state);

    const { data: entries, error } = await getRegionClusters(
      apiConfig,
      projectId,
      alias,
      1,
      limit,
    );

    if (!entries || error) {
      throw error;
    }

    const clusterIds = entries.map((cluster) => `${cluster.id}`) || [];

    const id = selectRegionAliasToId(alias)(getState() as AppState);

    const normalizedEntries = adaptClustersToCards({
      clusters: entries,
      domainConfig,
      variables,
    });

    dispatch(addEntries(normalizedEntries));
    // Для страниц мобильного кластера используется стейт редизайна
    dispatch(upsertEntries(normalizedEntries));

    return { clusterIds, id };
  },
);

/**
 * Получение данных для региона
 * @param alias - alias региона
 */
export const fetchRegion = createAsyncThunk(
  'fetchRegion',
  async (alias: ATRegion['alias'], { getState }) => {
    const apiConfig = selectApiConfig(getState() as AppState);

    const { data, error } = await getRegionInfo(apiConfig, alias);

    if (!data || error) {
      throw error;
    }

    return data;
  },
);
