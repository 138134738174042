import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { useHistory } from 'react-router';

import { selectRuntime } from 'common/redux/runtime/selectors';
import { useAppSelector } from 'store/hooks';
import { incrementCounters } from 'utils/counters/init/client';

import {
  UseSwipeType,
  OnSwipedDesktopType,
  OnSwipedMobileType,
} from './typings';
import { setPageHash } from './utils';

/**
 * Генерация обработчика свайпа для десктопа.
 * @param props - пропсы
 * @param props.slidesConfigList - массив конфигруций сплитов;
 * @param props.setSlideIndex - сеттер для установки индекса слайда.
 */
export const useSwipeDesktop = ({
  slidesConfigList,
  setSlideIndex,
}: UseSwipeType) => {
  const runtime = useAppSelector(selectRuntime, shallowEqual);

  const { location, replace } = useHistory();

  const onSwiped = useCallback(
    ({ direction, callbackSlide }: OnSwipedDesktopType) => {
      let slideIndex = 0;

      if (direction === 'forward') {
        setSlideIndex((prev) => {
          const value = prev + 1;

          slideIndex = value;

          return value;
        });
      } else {
        setSlideIndex((prev) => {
          const value = prev - 1;

          slideIndex = value;

          return value;
        });
      }

      const { hash } = slidesConfigList[slideIndex];

      setPageHash({
        hash,
        pathname: location.pathname,
        search: location.search,
        replace,
      });

      callbackSlide();

      incrementCounters({ runtime });
    },
    [runtime, slidesConfigList, setSlideIndex, location, replace],
  );

  return { onSwiped };
};

/**
 * Генерация обработчика свайпа для мобилы.
 * @param props - пропсы
 * @param props.slidesConfigList - массив конфигруций сплитов;
 * @param props.setSlideIndex - сеттер для установки индекса слайда.
 */
export const useSwipeMobile = ({
  slidesConfigList,
  setSlideIndex,
}: UseSwipeType) => {
  const runtime = useAppSelector(selectRuntime, shallowEqual);

  const { location, replace } = useHistory();

  const onSwiped = useCallback(
    ({ slideIndex }: OnSwipedMobileType) => {
      setSlideIndex(slideIndex);

      const { hash } = slidesConfigList[slideIndex];

      setPageHash({
        hash,
        pathname: location.pathname,
        search: location.search,
        replace,
      });

      incrementCounters({ runtime });
    },
    [runtime, slidesConfigList, setSlideIndex, location, replace],
  );

  return { onSwiped };
};
