import cn from 'classnames';
import React from 'react';
import { shallowEqual } from 'react-redux';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { useAnalytics } from 'common/hooks/useAnalytics';
import { expertLinkAnalyticsConfig } from 'common/hooks/useAnalytics/configs';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import {
  selectIsMobile,
  selectProjectId,
  selectDomainConfig,
} from 'common/redux/runtime/selectors';
import { getExpertLink } from 'common/utils/getExpertLink';
import { StarIcon } from 'icons';
import { useAppSelector } from 'store/hooks';

import s from './styles.module.css';

type ExpertSignaturesPropsType = {
  experts: (ATExpertType | undefined)[];
  customStyles?: StylesType;
  withIcon?: boolean;
};

/**
 * Компонент данных о всех экспертах, упомянутых в статье
 * @param props - пропсы
 * @param props.experts - массив экспертов;
 * @param props.customStyles - кастомные стили;
 * @param props.withIcon - флаг наличия иконки эксперта.
 */
const ExpertSignaturesComponent = function ExpertSignatures({
  experts,
  customStyles = {},
  withIcon = true,
}: ExpertSignaturesPropsType) {
  const domainConfig = useAppSelector(selectDomainConfig, shallowEqual);
  const projectId = useAppSelector(selectProjectId);
  const isMobile = useAppSelector(selectIsMobile);
  const top100Attribute = useTop100AttributeWithValue('expert');

  const { reachClick } = useAnalytics(expertLinkAnalyticsConfig, isMobile);

  const handleClick = () => {
    if (isMobile) {
      reachClick();
    }
  };

  return (
    <span className={cn(s.container, customStyles.expertRoot)}>
      {experts.map((expert, index) => {
        const { alias = '', name } = expert || {};
        const isLastElement = index === experts.length - 1;

        return (
          <a
            key={alias}
            href={getExpertLink({ domainConfig, projectId, alias })}
            className={cn(s.title, customStyles.expertTitle)}
            data-test="expert-signature-link"
            onClick={handleClick}
            {...top100Attribute}
          >
            {!!withIcon && (
              <StarIcon className={cn(s.icon, customStyles.expertIcon)} />
            )}
            <span className={cn(s.name, customStyles.expertName)}>{name}</span>
            <span>
              эксперт
              {isLastElement ? ' ' : ','}
            </span>
          </a>
        );
      })}
    </span>
  );
};

export const ExpertSignatures = withErrorBoundary(ExpertSignaturesComponent);
