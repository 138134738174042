import { selectWidgets } from '../selectors';

export const selectWidgetsCross = (state: AppState) =>
  selectWidgets(state).crossWidget;

export const selectWidgetsCrossFirstCurrency = (state: AppState) =>
  selectWidgetsCross(state).firstCurrency;

export const selectWidgetsCrossSecondCurrency = (state: AppState) =>
  selectWidgetsCross(state).secondCurrency;

export const selectWidgetsCrossTitle = (state: AppState) =>
  selectWidgetsCross(state).title;

export const selectWidgetsCrossData = (state: AppState) =>
  selectWidgetsCross(state).data;

export const selectWidgetsCrossRates = (state: AppState) =>
  selectWidgetsCross(state).rates;

export const selectWidgetsCrossDescriptions = (state: AppState) =>
  selectWidgetsCross(state).descriptions;
