import { createAsyncThunk } from '@reduxjs/toolkit';

import { getEditorNewsByProject } from 'api';
import { addManyEntries } from 'common/redux/commonData/entries';
import {
  selectApiConfig,
  selectDomainConfig,
  selectProjectId,
  selectVariables,
} from 'common/redux/runtime/selectors';
import { adaptClusterToCard } from 'utils/adapters';

type FetchEditorClustersPropsType = {
  editorAlias: string;
};

/**
 * Получение списка новостей автора/редактора.
 * @param props.editorAlias - алиас автора/редактора.
 */
export const fetchEditorClusters = createAsyncThunk(
  'fetchEditorClusters',
  async (
    { editorAlias }: FetchEditorClustersPropsType,
    { dispatch, getState },
  ) => {
    const state = getState() as AppState;

    const apiConfig = selectApiConfig(state);
    const domainConfig = selectDomainConfig(state);
    const variables = selectVariables(state);
    const projectId = selectProjectId(state);

    const { error, data } = await getEditorNewsByProject(
      apiConfig,
      projectId,
      editorAlias,
    );

    if (error || !data) {
      throw (
        error ||
        new Error(
          `Ошибка при получение новостей автора/редактора: ${editorAlias}`,
        )
      );
    }

    const modifyClusters = data.map((cluster) =>
      adaptClusterToCard({ cluster, domainConfig, variables }),
    );

    dispatch(addManyEntries(modifyClusters));

    return modifyClusters.map(({ id }) => id);
  },
);
