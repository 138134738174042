import React, { memo, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { VideoAdIframe } from 'common/components/VideoAdIframe';
import {
  selectClusterById,
  selectClusterPuids,
} from 'common/redux/commonData/entries/selectors';
import { selectClusterPageClusterFeed } from 'common/redux/pages/cluster/selectors';
import { selectDomainConfig } from 'common/redux/runtime/selectors';
import { selectClusterMainTopic, selectVideoAd } from 'common/redux/selectors';
import { generateClusterUrl } from 'utils/urlGenerator';

type VideoAdIframeWrapperPropsType = {
  clusterId: Card['id'];
};

/**
 * Компонент обертка над компонентом отрисовки Видео дня. Уменьшает количество пропсов.
 * @param props - пропсы
 * @param props.clusterId - id кластера, внутри которого рисуется Видео дня.
 */
const VideoAdIframeWrapperComponent = function VideoAdIframeWrapper({
  clusterId,
}: VideoAdIframeWrapperPropsType) {
  const videoAd = useSelector(selectVideoAd, shallowEqual);
  const feedList = useSelector(selectClusterPageClusterFeed, shallowEqual);
  const firstCluster = useSelector(
    selectClusterById(feedList[0]),
    shallowEqual,
  );
  const topic = useSelector(selectClusterMainTopic(feedList[0]), shallowEqual);
  const puids = useSelector(selectClusterPuids(clusterId), shallowEqual);
  const domainConfig = useSelector(selectDomainConfig, shallowEqual);

  const clusterInFeedList = feedList.includes(clusterId);

  const clusterUrl = useMemo(() => {
    if (!firstCluster) return '';

    const { id, normalizedTitle } = firstCluster;

    return generateClusterUrl({
      clusterId: id,
      normalizedTitle,
      topic,
      domainConfig,
    });
  }, [domainConfig, firstCluster, topic]);

  if (!clusterInFeedList || !firstCluster) {
    return null;
  }

  return (
    <VideoAdIframe
      embedCode={videoAd.embedCode}
      puids={puids}
      clusterId={clusterId}
      clusterUrl={clusterUrl}
    />
  );
};

export const VideoAdIframeWrapper = memo(VideoAdIframeWrapperComponent);
