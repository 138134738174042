import cn from 'classnames';
import React, { memo } from 'react';
import { shallowEqual } from 'react-redux';

import { selectClusterById } from 'common/redux/commonData/entries/selectors';
import {
  MAX_TEXT_SCALE_VALUE,
  MIN_TEXT_SCALE_VALUE,
} from 'common/redux/pages/cluster';
import {
  selectIsHumanCenteredStrategyStateActivated,
  selectIsMobile,
} from 'common/redux/runtime/selectors';
import { CLUSTER_NAME_TYPES } from 'config/common/cluster/constants';
import { ClusterControl } from 'desktop/pages/Cluster/components/ClusterControl';
import {
  CLUSTER_BUTTONS_PLACES,
  ShareButton,
} from 'desktop/pages/Cluster/components/ShareButton';
import { CommentsButton } from 'mobile/components/CommentsButton';
import { useAppSelector } from 'store/hooks';

import s from './styles.module.css';

type ClusterButtonsHeaderPropsType = {
  clusterId: Cluster['id'];
  clusterIndex: number;
};

/**
 * Компонент с кнопками для шапки кластера.
 * @param props - пропсы
 * @param props.clusterId – id кластера;
 * @param props.clusterIndex – индекс кластера.
 */
const ClusterButtonsHeaderComponent = function ClusterButtonsHeader({
  clusterId,
  clusterIndex,
}: ClusterButtonsHeaderPropsType) {
  const cluster = useAppSelector(selectClusterById(clusterId), shallowEqual);
  const forceRedesign = useAppSelector(
    selectIsHumanCenteredStrategyStateActivated,
  );
  const isMobile = useAppSelector(selectIsMobile);

  const { url = '', title = '', image, type } = cluster || {};

  const dataRamblerShare =
    typeof type === 'number'
      ? `cluster_${CLUSTER_NAME_TYPES[type]}::${clusterIndex + 1}`
      : '';

  return (
    <div className={cn(s.container, isMobile && s.mobile)}>
      <ClusterControl
        clusterId={clusterId}
        customStyles={s}
        textScaleMax={MAX_TEXT_SCALE_VALUE}
        textScaleMin={MIN_TEXT_SCALE_VALUE}
      />
      <div className={cn(s.shareAndComments, forceRedesign && s.redesign)}>
        <ShareButton
          clusterId={clusterId}
          clusterUrl={url}
          clusterTitle={title}
          clusterImageUrl={image?.url}
          dataRamblerShare={dataRamblerShare}
          componentPlace={CLUSTER_BUTTONS_PLACES.top}
          customStyles={s}
        />
        {isMobile && (
          <CommentsButton clusterId={clusterId} dataBlocks="comments_button" />
        )}
      </div>
    </div>
  );
};

export const ClusterButtonsHeader = memo(ClusterButtonsHeaderComponent);
