export type BanksRatesWidgetType = {
  id: string;
  data: number[] | null;
} & InitialState;

export enum BANKS_RATES_CURRENCY {
  USD = 'USD',
  EUR = 'EUR',
}

export enum BANKS_RATES_CURRENCY_ACTION_BUY {
  USD = 'usd_buy',
  EUR = 'eur_buy',
}

export enum BANKS_RATES_CURRENCY_ACTION_SELL {
  USD = '-usd_sell',
  EUR = '-eur_sell',
}

export enum BANKS_RATES_ACTION {
  buy = 'buy',
  sell = 'sell',
}
