import { LoadableComponent } from '@loadable/component';

/** Объект флагов ранее загруженных страниц */
const componentsController: {
  [chunkName: string]: boolean;
} = {};

/**
 * Функция, возвращающая promise-обертку для принудительной загрузки чанка со страницей.
 * @param loadableComponent - объект компонента-чанка;
 * @param chunkName - название компонента или любой другой уникальный ключ для хранения информации о странице.
 */
export const pageLoadable = (
  loadableComponent: LoadableComponent<object>,
  chunkName: string,
) => {
  if (__BROWSER__ && !componentsController[chunkName]) {
    return async () => {
      const data = await loadableComponent.load();

      componentsController[chunkName] = true;

      return data;
    };
  }

  return () => Promise.resolve(loadableComponent);
};

/**
 * Функция, возвращающая promise для принудительной загрузки чанка со страницей.
 * @param loadableComponent - объект компонента-чанка;
 * @param chunkName - название компонента или любой другой уникальный ключ для хранения информации о странице.
 */
export const getPageDownloader = (
  loadableComponent: LoadableComponent<object>,
  chunkName: string,
) => {
  const pageDownloader = pageLoadable(loadableComponent, chunkName);

  return pageDownloader();
};

type AppendPageDownloaderPropsType = {
  promiseList: PromiseListType;
  loadableComponent: LoadableComponent<object>;
  chunkName: string;
};

/**
 * Функция, возвращающая массив promise'ов с promise для принудительной загрузки чанка со страницей.
 * @param props - пропсы
 * @param props.promiseList - массив promise'ов;
 * @param props.loadableComponent - объект компонента-чанка;
 * @param props.chunkName - название компонента или любой другой уникальный ключ для хранения информации о странице.
 */
export const appendPageDownloader = ({
  promiseList,
  loadableComponent,
  chunkName,
}: AppendPageDownloaderPropsType) => {
  const pageDownloader = pageLoadable(loadableComponent, chunkName);

  return [...promiseList, pageDownloader()];
};
