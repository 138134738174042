import cn from 'classnames';
import React, {
  useRef,
  useCallback,
  MouseEventHandler,
  TouchEventHandler,
  MutableRefObject,
  ReactNode,
} from 'react';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { useObserver } from 'common/hooks/useObserver';
import { ArrowUpIcon } from 'icons';

import s from './styles.module.css';

type S2SLabelPropsType = {
  runtime: Runtime;
  active: boolean;
  onMouseDown:
    | React.TouchEventHandler<HTMLElement>
    | React.MouseEventHandler<HTMLElement>;
  labelRef: MutableRefObject<HTMLElement | null>;
  color?: string;
  isSwipe?: boolean;
  link?: string;
  text?: string;
  type?: 'line' | '70x55' | '60x75';
  slotLabel?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
  textShowCallback?: () => void;
  children: ReactNode;
};

const observerConfig = { threshold: 1 };
const entryCheck = true;

enum YM_IS_SEND {
  yes = 1,
  no,
}

/**
 * Компонент-обертка для вставки лэйбла для s2s
 * @param props - пропсы
 * @param props.link - ссылка для перехода по клику для перехода.
 * Должен приходить пропсами от ScrollToSite (пропс labelClickLink);
 * @param props.text - текст для блока под логотипом;
 * @param props.textShowCallback - callback, срабатывающий один раз при показе текста перед скриншотом;
 * @param props.type - внешний вид отображаемого лейбла;
 * @param props.style - кастомные стили для лэйбла (не обертки);
 * @param props.className - кастомный класс для лэйбла (не обертки);
 * @param props.isSwipe - позволяет свайпом логотипа переходить на страницу s2s.
 * Должен приходить пропсами от ScrollToSite;
 * @param props.onMouseDown - обработчик для свайпа от drag&drop.
 * Должен приходить пропсами от ScrollToSite;
 * @param props.labelRef - ref для слижения за лейблом, который свайпится.
 * Должен приходить пропсами от ScrollToSite;
 * @param props.onClick - callback, срабатывающий при клике на ссылку, если не включен свайп.
 * При свайпе не работает;
 * @param props.children - слот для логотипа;
 * @param props.runtime - объект рантайма;
 * @param props.runtime.settings - объект настроек;
 * @param props.runtime.settings.isMobile - флаг, что мобильная версия;
 * @param props.color - цвет для стилей.
 */
const S2SLabelComponent = function S2SLabel({
  runtime: {
    settings: { isMobile },
  },
  onMouseDown,
  labelRef,
  color = '',
  isSwipe = false,
  link = '',
  type = 'line',
  children,
  style,
  className = '',
  onClick,
  text = '',
  textShowCallback,
}: S2SLabelPropsType) {
  const ymIsSend = useRef<YM_IS_SEND>(text ? YM_IS_SEND.no : YM_IS_SEND.yes);

  const localCallback = useCallback(() => {
    if (ymIsSend && ymIsSend.current === YM_IS_SEND.no && textShowCallback) {
      ymIsSend.current = YM_IS_SEND.yes;
      textShowCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textShowCallback]);

  const ref = useObserver<HTMLDivElement>({
    callback:
      textShowCallback && ymIsSend && ymIsSend.current === YM_IS_SEND.no
        ? localCallback
        : null,
    entryCheck,
    observerConfig,
  });

  const labelNode = (
    <>
      <div
        className={cn(s.label, { [s.labelDesktop]: !isMobile }, className)}
        style={
          style || color
            ? {
                ...(style || {}),
                ...(color ? { backgroundColor: `${color}` } : {}),
              }
            : undefined
        }
      >
        <div
          className={cn(s.labelTail, s.labelTail_left)}
          style={color ? { color } : undefined}
        />
        <ArrowUpIcon className={s.icon} />
        {children}
        <div
          className={cn(s.labelTail, s.labelTail_right)}
          style={color ? { color } : undefined}
        />
      </div>
      <div
        ref={ref}
        className={cn(s.text, { [s.textDesktop]: !isMobile })}
        style={color ? { backgroundColor: `${color}` } : undefined}
      >
        <div
          className={cn(s.textContent, { [s.textContentDesktop]: !isMobile })}
          data-test="label-text"
        >
          {text}
        </div>
      </div>
    </>
  );

  const labelWrapper = cn(s.labelWrapper, {
    [s[`labelWrapper_${type}`]]: type,
    [s[`labelWrapper_${type}Desktop`]]: !isMobile,
  });

  return link ? (
    <a
      className={labelWrapper}
      href={link}
      onClick={onClick}
      data-test="label-link"
    >
      {labelNode}
    </a>
  ) : (
    <button
      ref={isSwipe ? (labelRef as MutableRefObject<HTMLButtonElement>) : null}
      type="button"
      className={labelWrapper}
      onTouchStart={
        isSwipe && isMobile
          ? (onMouseDown as TouchEventHandler<HTMLElement>)
          : undefined
      }
      onMouseDown={
        isSwipe && !isMobile
          ? (onMouseDown as MouseEventHandler<HTMLElement>)
          : undefined
      }
    >
      {labelNode}
    </button>
  );
};

export const S2SLabel = withErrorBoundary(S2SLabelComponent);
