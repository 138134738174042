import DraftParser, {
  CustomRendererMapType,
  EntityRendererMapType,
  InlineRendererMapType,
} from '@media-components/draft-parser';
import cn from 'classnames';
import React, { memo, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { usePublisherAdData } from 'common/hooks/usePublishedAdData';
import { selectClusterById } from 'common/redux/commonData/entries/selectors';
import {
  selectClusterPageClusterIdByIndex,
  selectClusterPageTextScale,
} from 'common/redux/pages/cluster/selectors';
import {
  selectIsHumanCenteredStrategyStateActivated,
  selectIsMobile,
} from 'common/redux/runtime/selectors';

import { ClusterWasRead } from '../ClusterWasRead';

import { DraftContextProvider } from './context';
import { getRender } from './renderer';
import {
  CustomBlockRendererMapType,
  CustomRendererType,
  EntityModifierType,
  InjectorGetterType,
} from './typings';
import { getModifyDraft } from './utils';

import '@media-components/draft-parser/styles.css';
import s from './styles.module.css';

/**
 * Проект пока не готов к SPA
 * Как починится - можно убрать этот флаг.
 */
const SHOULD_DISABLE_SPA = false;

type ClusterContentOnDraftPropsType = {
  draft: RawDraftContentState;
  clusterFeedListIndex: number;
  injectorsGetter: InjectorGetterType;
  entityModifiers?: EntityModifierType[];
  customRenderers?: CustomRendererType;
  forceRedesign?: boolean;
  isMulticluster?: boolean;
};

type MergedRendererType = CustomRendererMapType<
  InlineRendererMapType,
  CustomBlockRendererMapType,
  EntityRendererMapType
>;

/**
 * Компонент контента кластера с использованием драфта для десктопа.
 * @param props - пропсы
 * @param props.draft - объект драфта кластера;
 * @param props.injectorsGetter - функция, возвращающая массив инжекторов для данного окружения;
 * @param props.entityModifiers - массив модификаторов энтити;
 * @param props.clusterFeedListIndex - индекс кластера в фид листе;
 * @param props.customRenderers - рендереры для определенных элементов драфта, зависящие от окружения;
 * @param props.forceRedesign - флаг нового дизайна, когда его нужно переназначить;
 * @param props.isMulticluster - флаг, что это мультикластер.
 */
export const ClusterContentOnDraftComponent = function ClusterContentOnDraft({
  draft,
  clusterFeedListIndex,
  injectorsGetter,
  entityModifiers,
  customRenderers,
  forceRedesign: outerForceRedesign = false,
  isMulticluster = false,
}: ClusterContentOnDraftPropsType) {
  const textScaleValue = useSelector(selectClusterPageTextScale);
  const isMobile = useSelector(selectIsMobile);
  const clusterId = useSelector(
    selectClusterPageClusterIdByIndex(clusterFeedListIndex),
  );
  const cluster = useSelector(selectClusterById(clusterId), shallowEqual);

  const forceRedesign =
    useSelector(selectIsHumanCenteredStrategyStateActivated) ||
    outerForceRedesign;

  const { publisherId, publisherScope } = usePublisherAdData();

  const { puids, url } = cluster ?? {};

  const modifiedDraft = getModifyDraft({
    draft,
    injectorsGetter,
    entityModifiers,
  });

  const outerProps = useMemo(
    () => ({
      puids,
      pageUrl: url || '',
      pageId: clusterId,
      forceLegacy: __DEV__,
      disableSPA: SHOULD_DISABLE_SPA,
      isMobile,
    }),
    [clusterId, isMobile, puids, url],
  );

  const customRenderer = getRender({
    forceRedesign,
    isMulticluster,
    outerProps,
    customRenderers,
  });

  return (
    <div
      className={cn(s.root, `fontSize_${textScaleValue}`, {
        [s.multicluster]: isMulticluster,
        [s.mobile]: isMobile,
      })}
    >
      <DraftContextProvider value={outerProps}>
        <DraftParser
          draft={modifiedDraft}
          customRenderer={customRenderer as MergedRendererType}
          pageId={clusterId}
          pageUrl={url || ''}
          puids={puids}
          forceLegacy={false}
          isMobile={isMobile}
          sspUserId={publisherId}
          sspUserIdScope={publisherScope}
        />
      </DraftContextProvider>
      <ClusterWasRead clusterId={clusterId} />
    </div>
  );
};

export const ClusterContentOnDraft = memo(ClusterContentOnDraftComponent);
