import {
  useState,
  useEffect,
  useCallback,
  useRef,
  MutableRefObject,
  MouseEventHandler,
  TouchEventHandler,
} from 'react';

import { useBodyScrollLock } from 'common/hooks/useBodyScrollLock';

type DNDonMouseDowntype =
  | MouseEventHandler<HTMLElement>
  | TouchEventHandler<HTMLElement>;

type UseDragAndDropReturnType = {
  onMouseDown: DNDonMouseDowntype;
  labelRef: MutableRefObject<HTMLElement | HTMLButtonElement | null>;
  translate: number;
};

type UseDragAndDropType = (props: {
  swipeLimit: number;
  link: string;
  callback?: () => void;
}) => UseDragAndDropReturnType;

/**
 * Функция вычисления положения мыши от верхней части экрана
 * @param event - объект события мыши
 */
const getClientY = (
  event: { clientY?: number } | { targetTouches?: TouchList },
) => {
  if (event instanceof MouseEvent) {
    return event.clientY;
  }

  if (event instanceof TouchEvent) {
    return event.targetTouches[0].clientY;
  }

  return null;
};

/**
 * Функция для редиректа
 * @param link - ссылка для редиректа
 */
const redirect = (link: string) => {
  window.location.href = link;
};

/**
 * Хук обработки d&d на лейбле
 * @param props - пропсы
 * @param props.link - ссылка для редиректа при s2s переходе по свайпу
 * @param props.swipeLimit - процент, после которого происходит s2s переход по свайпу
 * @param props.callback - callback, срабатывающий перед s2s переходом по свайпу
 */
export const useDragAndDrop: UseDragAndDropType = ({
  link,
  swipeLimit,
  callback,
}) => {
  const shiftY = useRef(0);
  const {
    ref: labelRef,
    disableBodyScroll,
    enableBodyScroll,
  } = useBodyScrollLock();

  const [dndActive, setDndActive] = useState(false);
  const [translate, setTranslate] = useState(0);
  const [activeRedirect, setActiveRedirect] = useState(false);

  useEffect(() => {
    if (activeRedirect) {
      if (callback) callback();
      redirect(link);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, activeRedirect]);

  const checkSwipeValue = useCallback(
    (translateValue: number) => {
      const value =
        (translateValue * 100) / document.documentElement.clientHeight;

      if (!activeRedirect) {
        if (value >= swipeLimit) {
          setActiveRedirect(true);
        } else {
          setTranslate(translateValue);
        }
      }
    },
    [activeRedirect, swipeLimit],
  );

  const onMouseDown = useCallback(
    (event: { clientY: number } | { targetTouches?: TouchList }) => {
      disableBodyScroll();
      shiftY.current = getClientY(event) ?? 0;
      setDndActive(true);
    },
    [disableBodyScroll],
  );

  const onMouseMove = useCallback(
    (event: { clientY?: number } | { targetTouches?: TouchList }) => {
      const clientY = getClientY(event) ?? 0;
      const translateValue = shiftY.current - clientY;

      if (translateValue >= 0) {
        checkSwipeValue(translateValue);
      }
    },
    [checkSwipeValue],
  );

  const onMouseUp = useCallback(() => {
    if (dndActive) {
      setDndActive(false);

      checkSwipeValue(0);
      setTimeout(() => {
        enableBodyScroll();
      }, 0);
    }
  }, [dndActive, checkSwipeValue, enableBodyScroll]);

  useEffect(() => {
    if (!dndActive) return undefined;

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    document.addEventListener('touchmove', onMouseMove);
    document.addEventListener('touchend', onMouseUp);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);

      document.removeEventListener('touchmove', onMouseMove);
      document.removeEventListener('touchend', onMouseUp);
    };
  }, [dndActive, onMouseUp, onMouseMove]);

  return {
    translate,
    labelRef,
    onMouseDown,
  };
};
