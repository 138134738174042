/**
 * Функция проверяющая, что в html разметке есть CPA-элемент
 * @param htmlCode - html разметка
 */
export const getIsRamblerInfoBanner = (htmlCode: string) =>
  htmlCode.includes('rambler-info-banner') &&
  htmlCode.includes('banner-id') &&
  htmlCode.includes(
    'https://embeds.rambler.ru/cpa-elements/info-banner/sdk.js',
  );
