import { selectCardById } from '../../commonData/entries/selectors';
import { selectPages } from '../selectors';

export const selectClusterCommentsPage = (state: AppState) =>
  selectPages(state).clusterComments;

export const selectClusterCommentsPageClusterId = (state: AppState) =>
  selectClusterCommentsPage(state).clusterId;

export const selectClusterCommentsPageFullCluster = (state: AppState) =>
  selectCardById(selectClusterCommentsPageClusterId(state));

export const selectClusterCommentsPagePuids = (state: AppState) =>
  selectClusterCommentsPage(state).puids;
