import { createAsyncThunk } from '@reduxjs/toolkit';

import { getAllTopics, getClusterTopics, getNewsByTopic } from 'api';
import {
  selectApiConfig,
  selectDomainConfig,
  selectProjectId,
  selectVariables,
} from 'common/redux/runtime/selectors';
import { TOPIC_STATUS } from 'config/common/topic/typings';
import { adaptClusterToCard } from 'utils/adapters';

/**
 * Функция загрузки топиков.
 *
 */
export const fetchAllTopics = createAsyncThunk(
  'fetchAllTopics',
  async (_props, { getState }) => {
    const api = selectApiConfig(getState() as AppState);
    const projectId = selectProjectId(getState() as AppState);

    const { data: allTopics, error } = await getAllTopics(api);

    if (error || !allTopics) {
      throw error || new Error('Ошибка при получении топиков');
    }

    return [
      ...allTopics.filter((topic) => topic.project_id === projectId),
      ...allTopics.filter(
        (topic) =>
          topic.project_id !== projectId &&
          topic.status === TOPIC_STATUS.active,
      ),
    ];
  },
);

/**
 * Функция загрузки топиков кластера.
 * @param clusterId - id кластера, для которого загружаются топики;
 * @param callback - коллбек, которому возвращаются сокращенные данные о топиках кластера.
 */
export const fetchTopicsByCluster = createAsyncThunk(
  'fetchTagsByCluster',
  async (
    {
      clusterId,
      callback,
    }: {
      clusterId: Cluster['id'];
      callback?: (resource: ClusterTopicsType[]) => Promise<void> | void;
    },
    { getState },
  ) => {
    const api = selectApiConfig(getState() as AppState);

    const { data: shortTopics, error } = await getClusterTopics(api, clusterId);

    if (error || !shortTopics) {
      throw (
        error ||
        new Error(`Ошибка при получении топиков по кластеру: ${clusterId}`)
      );
    }

    if (callback) await callback(shortTopics);

    return shortTopics;
  },
);

/**
 * Функция загрузки кластеров, принадлежащий топику.
 * @param topicId - id топика, для которого загружаются кластера.
 * @returns
 *  topicId - id топика для последующего присваивания данных;
 *  clusterIds - id кластеров для текущего топика;
 *  hasNextPage - флаг, что есть ещё новости для топика.
 */
export const fetchClustersByTopic = createAsyncThunk(
  'fetchClustersByTopic',
  async (
    {
      topicId,
      callback,
    }: {
      topicId: ATTopic['id'] | undefined;
      callback: (clusters: Card[]) => void;
    },
    { getState },
  ) => {
    // После вызова getState стейт становится статичным. Использовать аккуратно
    const state = getState() as AppState;

    const apiConfig = selectApiConfig(state);
    const domainConfig = selectDomainConfig(state);
    const variables = selectVariables(state);

    if (!topicId) {
      throw new Error(`Отсутствует topicId: ${topicId}`);
    }

    const { data, error } = await getNewsByTopic({ apiConfig, topicId });

    if (error || !data || !data.clusters) {
      throw (
        error ||
        new Error(`Ошибка при получении кластеров по топику: ${topicId}`)
      );
    }

    const clusters = data.clusters.map((cluster) =>
      adaptClusterToCard({ cluster, domainConfig, variables }),
    );

    if (callback) {
      callback(clusters);
    }

    return {
      topicId,
      clusterIds: clusters.map((cluster) => cluster.id),
      hasNextPage: !!data.pagination.nextPage,
    };
  },
);
