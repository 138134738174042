import { createSlice } from '@reduxjs/toolkit';

import { fetchCrossRate } from './asyncs';
import {
  DEFAULT_CROSS_FIRST_CURRENCY,
  DEFAULT_CROSS_SECOND_CURRENCY,
} from './constants';
import { CrossDataType, CrossDescriptionType, CrossRateType } from './typings';

type CrossWidgetType = {
  firstCurrency: CurrencyType['charCode'];
  secondCurrency: CurrencyType['charCode'];
  title: string;
  data: CrossDataType[];
  rates: CrossRateType;
  descriptions: CrossDescriptionType;
} & InitialState;

/**
 * Слайс виджета кросс-курса.
 */
const crossSlice = createSlice({
  name: 'cross',
  initialState: {
    firstCurrency: DEFAULT_CROSS_FIRST_CURRENCY,
    secondCurrency: DEFAULT_CROSS_SECOND_CURRENCY,
    title: '',
    // Массив значений кода и названия кросс-курса.
    data: [] as CrossDataType[],
    // Коллекция пар валюты к смежным валютам.
    rates: {},
    // Коллекция пар кода к названию кросс-курса.
    descriptions: {},
    fetching: false,
    error: '',
  } as CrossWidgetType,
  reducers: {
    /**
     * Метод обновления первой валюты
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload - выбранная валюта.
     */
    setCrossFirstCurrency: (state, { payload }) => {
      state.firstCurrency = payload;
    },

    /**
     * Метод обновления второй валюты
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload - выбранная валюта.
     */
    setCrossSecondCurrency: (state, { payload }) => {
      state.secondCurrency = payload;
    },

    /**
     * Метод обновления заголовка
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload - заголовок.
     */
    setCrossTitle: (state, { payload }) => {
      state.title = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCrossRate.pending, (state) => {
      state.fetching = true;
      state.error = '';
    });
    builder.addCase(fetchCrossRate.fulfilled, (state, { payload }) => {
      state.data = payload.data;
      state.rates = payload.rates;
      state.descriptions = payload.descriptions;
      state.fetching = false;
      state.error = '';
    });
    builder.addCase(fetchCrossRate.rejected, (state, { error }) => {
      state.fetching = false;
      state.error = error.message;
    });
  },
});

export const crossReducer = crossSlice.reducer;

export const { setCrossFirstCurrency, setCrossSecondCurrency, setCrossTitle } =
  crossSlice.actions;
