import loadable from '@loadable/component';
import React from 'react';

import {
  selectTopicById,
  selectTopicIdByAlias,
  selectTopicIsGreen,
  selectTopicsByProjectId,
} from 'common/redux/commonData/topics/selectors';
import { fetchTopicNews as fetchTopicNewsWidget } from 'common/redux/commonData/widgets/topicNewsWidget/asyncs';
import {
  fetchTopicNews,
  setTopicInfo,
  setTopicPuids,
} from 'common/redux/pages/topic';
import { fetchFullRecsData } from 'common/redux/pages/topic/asyncs';
import {
  selectTopicPageClusterIds,
  selectTopicPageRecommendClusterIds,
} from 'common/redux/pages/topic/selectors';
import {
  selectHumanCenteredStrategyValue,
  selectIsHumanCenteredStrategyStateActivated,
  selectProjectAlias,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import {
  appendPageDownloader,
  getPageDownloader,
} from 'common/routes/pageLoadable';
import { getIsFormatTopic } from 'config/common/common';
import { PAGE_TYPE } from 'config/common/router/typings';
import { filterTopics } from 'utils/filterTopics';

import { LIMIT_BY_CLUSTERS_FETCHING } from '../constants';
import { GREEN_PROJECT_IDS } from '../home/constants';
import { IAppRoute } from '../typings';
import { getTopLimitBy } from '../utils';

import {
  BLOCK_ID_FOR_TOPIC,
  LIMIT_FOR_FIRST_CLUSTERS_BLOCK,
} from './constants';
import { loadWidgetData } from './utils';

const TopicDesktop = loadable(() => import('desktop/pages/Topic'));
const TopicMobile = loadable(() => import('mobile/pages/Topic'));

type TopicMatchProps = {
  topicAlias: TopicType['alias'];
};

/**
 * Роут рубрики.
 * @see https://news.rambler.ru/politics/
 * @param isMobile - флаг мобильной версии
 */
export const topicRoutes = (
  isMobile: SettingsType['isMobile'],
): IAppRoute<TopicMatchProps> => ({
  name: PAGE_TYPE.topic,
  exact: true,
  sensitive: true,
  path: `/:topicAlias([-_a-z0-9]+)/`,
  render: isMobile ? () => <TopicMobile /> : () => <TopicDesktop />,
  fetchData: async ({ dispatch, getState }, { params: { topicAlias } }) => {
    const projectId = selectProjectId(getState());
    const projectAlias = selectProjectAlias(getState());
    const topicId = selectTopicIdByAlias(topicAlias, projectId)(getState());
    const topic = selectTopicById(topicId)(getState());

    const isGreenTopic = selectTopicIsGreen(topicId)(getState());
    const isGreen = isGreenTopic || !!(topic && GREEN_PROJECT_IDS.includes(topic?.project_id));

    const projectTopics = selectTopicsByProjectId(projectId)(getState());
    const forceRedesign =
      selectIsHumanCenteredStrategyStateActivated(getState());
    const redesignSplitValue = selectHumanCenteredStrategyValue(getState());

    const filteredTopics = filterTopics(projectTopics, projectId);
    const isFormatTopic = getIsFormatTopic(topicAlias);
    const limitBy = getTopLimitBy({
      forceRedesign,
      redesignSplitValue,
      isGreen,
      topicAlias,
      isMobile,
    });

    dispatch(setTopicPuids({ topicAlias, projectAlias }));
    dispatch(setTopicInfo({ topicId, isFormatTopic }));

    await dispatch(
      fetchTopicNews({
        topicId,
        length: LIMIT_BY_CLUSTERS_FETCHING,
        limitBy,
      }),
    );

    const topicClusterIds = selectTopicPageClusterIds(getState());

    const topicClusterIdsLength = topicClusterIds.length;

    const topicType = isGreen ? 'green' : 'default';

    /**
     * Догружаем кластеров из рекоммендов,
     *  если изначально их меньше указанного числа
     */
    if (topic && topicClusterIdsLength < LIMIT_FOR_FIRST_CLUSTERS_BLOCK) {
      await dispatch(
        fetchFullRecsData({
          length: LIMIT_FOR_FIRST_CLUSTERS_BLOCK - topicClusterIdsLength,
          excludedClustersIds: topicClusterIds,
          rcmBlockType: isMobile
            ? BLOCK_ID_FOR_TOPIC.mobile[topicType]
            : BLOCK_ID_FOR_TOPIC.desktop[topicType],
        }),
      );
    }

    const topicRecommendClusterIds =
      selectTopicPageRecommendClusterIds(getState());

    if (isMobile) {
      await getPageDownloader(TopicMobile, Object.keys({ TopicMobile })[0]);

      return;
    }

    const promiseList: PromiseListType = [
      ...loadWidgetData({
        projectId,
        topicAlias,
        itemExcludedIds: [...topicClusterIds, ...topicRecommendClusterIds],
        rcmBlockType: BLOCK_ID_FOR_TOPIC.desktop[topicType],
      }).map((promise) => dispatch(promise)),
      dispatch(fetchTopicNewsWidget({ topicId: filteredTopics[0].id })),
    ];

    await Promise.all(
      appendPageDownloader({
        promiseList,
        loadableComponent: TopicDesktop,
        chunkName: Object.keys({ TopicDesktop })[0],
      }),
    );
  },
});
