import React, { useCallback, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Ad } from 'common/components/Ad';
import { RamblerGeo } from 'common/components/RamblerComponents/RamblerGeo';
import {
  RamblerHeader,
  UserProfileType,
} from 'common/components/RamblerComponents/RamblerHeader';
import { RamblerPopup } from 'common/components/RamblerComponents/RamblerPopup';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { useAuthAnalytics } from 'common/hooks/useAuthAnalytics';
import { useAuthListener } from 'common/hooks/useAuthListener';
import { UserStateType, useRCMsetMeta } from 'common/hooks/useRcm';
import { useToplineAuth } from 'common/hooks/useToplineAuth';
import {
  selectHeaderWidgetsCurrencies,
  selectHeaderWidgetsDoodle,
  selectHeaderWidgetsExpandedWeather,
  selectHeaderWidgetsWeather,
} from 'common/redux/commonData/headerWidgets/selectors';
import {
  selectIsHumanCenteredStrategyStateActivated,
  selectProjectAlias,
  selectRuntime,
} from 'common/redux/runtime/selectors';
import { BANNERS } from 'config/common/banners/typings';
import { RamblerHeaderExtension } from 'desktop/components/RamblerComponents/RamblerHeaderExtension';
import { RamblerOldTopline } from 'desktop/components/RamblerComponents/RamblerOldTopline';
import { RamblerTopline } from 'desktop/components/RamblerComponents/RamblerTopline';
import { yaReachGoal } from 'utils/counters/yaMetrika';
import { COUNTER_ID } from 'utils/counters/yaMetrika/constants';

type HeaderPropsType = {
  puids: PuidsType;
};

/**
 * Компонент хэдера страницы состоящий из Topline и HeaderExtension
 * @param props - пропсы
 * @param props.puids – объект пуидов рекламы.
 */
const HeaderComponent = function Header({ puids }: HeaderPropsType) {
  const runtime = useSelector(selectRuntime, shallowEqual);
  const projectAlias = useSelector(selectProjectAlias);
  const currencies = useSelector(selectHeaderWidgetsCurrencies, shallowEqual);
  const doodle = useSelector(selectHeaderWidgetsDoodle, shallowEqual);
  const weather = useSelector(selectHeaderWidgetsWeather, shallowEqual);
  const expandedWeather = useSelector(
    selectHeaderWidgetsExpandedWeather,
    shallowEqual,
  );
  const forceRedesign = useSelector(
    selectIsHumanCenteredStrategyStateActivated,
  );

  const [userFetched, setUserFetched] = useState(false);
  const [userProfile, setUserProfile] = useState<UserProfileType | null>(null);
  const [geoOpened, setGeoOpened] = useState(false);

  const promoAd = useMemo(
    () => <Ad name={BANNERS.Topline} puids={puids} />,
    [puids],
  );

  useAuthListener();

  const onUserInfoRecieved = useToplineAuth();

  const { setMetaState } = useRCMsetMeta();

  const handleUserState = useCallback(
    (state: UserStateType) => {
      setMetaState((prev) => ({ ...prev, userState: state }));
      yaReachGoal(runtime, COUNTER_ID.Portal, `user_${state}`);
      yaReachGoal(runtime, COUNTER_ID.Common, `user_${state}`);
    },
    [runtime, setMetaState],
  );

  const handleUserId = useCallback(
    (id: string) => {
      setMetaState((prev) => ({ ...prev, userId: id }));
      onUserInfoRecieved(id);
    },
    [onUserInfoRecieved, setMetaState],
  );

  const onOpenGeo = useCallback(() => {
    setGeoOpened(true);
  }, []);

  useAuthAnalytics(userFetched, userProfile);

  const handleCloseGeo = useCallback(() => setGeoOpened(false), []);

  if (forceRedesign) {
    return (
      <>
        <RamblerTopline
          projectCode={projectAlias}
          promoAd={promoAd}
          withWeather
          weather={expandedWeather}
          onUserFetched={setUserFetched as () => void}
          onUserInfo={setUserProfile}
          onUserId={handleUserId}
          onOpenGeo={onOpenGeo}
          onUserState={handleUserState}
          withExchangeRates
        />
        <RamblerPopup width={560} isOpen={geoOpened} onClose={handleCloseGeo}>
          <RamblerGeo onClose={handleCloseGeo} />
        </RamblerPopup>
      </>
    );
  }

  return (
    <>
      <RamblerOldTopline
        projectCode={projectAlias}
        redirectToMain={false}
        promoAd={promoAd}
        onUserFetched={setUserFetched as () => void}
        onUserInfo={setUserProfile}
        onUserId={handleUserId}
        onOpenGeo={onOpenGeo}
        onUserState={handleUserState}
      />
      <RamblerPopup width={560} isOpen={geoOpened} onClose={handleCloseGeo}>
        <RamblerGeo onClose={handleCloseGeo} />
      </RamblerPopup>
      <RamblerHeader
        projectCode={projectAlias}
        doodle={doodle}
        userFetched={userFetched}
        userProfile={userProfile}
      >
        <RamblerHeaderExtension
          currencies={currencies}
          projectCode={projectAlias}
          weather={weather}
        />
      </RamblerHeader>
    </>
  );
};

export const Header = withErrorBoundary(HeaderComponent);
