import { selectHumanCenteredStrategyValue } from 'common/redux/runtime/selectors';
import { HUMAN_CENTERED_STRATEGY_SPLIT_VALUES } from 'config/common/splits/typings';
import { useAppSelector } from 'store/hooks';

const FALLBACK_MISC = 0;

const HUMAN_CENTERED_STRATEGY_MISCS = {
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.off]: 8,
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.on]: 7,
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.on_4]: 1,
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.on_1_4]: 2,
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.fullscreen_off]: 3,
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.fullscreen_on]: 4,
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.default]: undefined,
};

/**
 * Хук выбора нужного миска (дополнительной информации о странице) для баннера.
 */
export const useMisc = () => {
  const humanCenteredStrategyValue = useAppSelector(
    selectHumanCenteredStrategyValue,
  );

  if (
    humanCenteredStrategyValue &&
    HUMAN_CENTERED_STRATEGY_MISCS[humanCenteredStrategyValue]
  ) {
    return HUMAN_CENTERED_STRATEGY_MISCS[humanCenteredStrategyValue];
  }

  return FALLBACK_MISC;
};
