import { BANNERS } from 'config/common/banners/typings';

// Баннеры с отступом в 20px внизу у контейнера
export const BANNERS_WITH_MARGIN_BOTTOM: { [index: string]: boolean } = {
  [BANNERS.TopBanner]: true,
  [BANNERS.Billboard]: false,
  [BANNERS.Parallax]: true,
  [BANNERS.Superfooter]: true,
  [BANNERS.Inread]: true,
  [BANNERS['240x400']]: true,
  [BANNERS['100x70']]: true,
  [BANNERS['240x400_2']]: true,
};

// Баннеры для вертикали финансов - десктоп
export const BANNERS_REQUIRED_FINANCE_DESKTOP: { [index: string]: boolean } = {
  [BANNERS.NativePartner]: true,
  [BANNERS['100x70']]: true,
  [BANNERS['240x400_2']]: true,
  [BANNERS.Superfooter]: true,
  [BANNERS.Native]: true,
  [BANNERS.Parallax]: true,
};

// Баннеры для вертикали финансов - мобилка
export const BANNERS_REQUIRED_FINANCE_MOBILE: { [index: string]: boolean } = {
  [BANNERS.TopBanner]: true,
  [BANNERS.Content1]: true,
  [BANNERS.Footer]: true,
  [BANNERS.Content4]: true,
  [BANNERS.Content2]: true,
  [BANNERS.ContentSpec]: true,
  [BANNERS.Content3]: true,
  [BANNERS.Listing1]: true,
  [BANNERS.Spec]: true,
  [BANNERS.Listing4]: true,
  [BANNERS.Listing2]: true,
  [BANNERS.Listing3]: true,
};

export const BANNER_PRELOAD_HEIGHT: { [x in BANNERS]?: number } = {
  // Desktop

  // [BANNERS.Billboard]: 250, Настраивается для каждого конкректного случая
  [BANNERS.Parallax]: 400,
  [BANNERS.Superfooter]: 250,

  [BANNERS.Inread]: 300,
  [BANNERS.Inpage]: 250,

  [BANNERS.NativePartner]: 115,

  [BANNERS['240x400']]: 600,
  [BANNERS['240x400_2']]: 600,

  [BANNERS.Native]: 290,
  [BANNERS.Context]: 290,
  [BANNERS['100x70']]: 290,

  // Mobile

  [BANNERS.TopBanner]: 250,

  [BANNERS.Listing1]: 250,
  [BANNERS.Listing2]: 250,
  [BANNERS.Listing3]: 250,
  [BANNERS.Listing4]: 250,
  [BANNERS.Spec]: 250,

  [BANNERS.Content1]: 250,
  [BANNERS.Content2]: 250,
  [BANNERS.Content3]: 250,
  [BANNERS.Content4]: 250,
  [BANNERS.ContentSpec]: 250,

  [BANNERS.Footer]: 250,
};
