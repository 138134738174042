import {
  OuterPropsType,
  H1,
  JSXToBlockRenderer,
  JSXToEntityRenderer,
  Image,
  OrderedList,
  UnorderedList,
  Video,
  Youtube,
  BLOCK_TYPES,
  ENTITY_TYPES,
} from '@media-components/draft-parser';

import {
  CUSTOM_BLOCK_TYPE,
  CustomRendererType,
  OuterDraftPropsType,
} from '../typings';

import { Banner } from './components/Banner';
import { Blockquote } from './components/Blockquote';
import { Expert } from './components/Expert';
import ExpertStyles from './components/Expert/styles.module.css';
import H1Styles from './components/H1.css';
import { H2 } from './components/H2';
import { H3 } from './components/H3';
import ImgStyles from './components/Image.css';
import ImageRedesignStyles from './components/ImageRedesign.css';
import { Legacy } from './components/Legacy';
import LegacyStyles from './components/Legacy/styles.module.css';
import { Link } from './components/Link';
import ClusterLinkStyles from './components/Link.css';
import MulticlusterLinkStyles from './components/MulticlusterLink.css';
import OrderedListStyles from './components/OrderedList.css';
import OLRedesignStyles from './components/OrderedListRedesign.css';
import { RamblerQuiz } from './components/Quiz';
import RamblerQuizStyles from './components/Quiz/styles.module.css';
import { RTag } from './components/RTag';
import { ShortSummaryBlock } from './components/ShortSummaryBlock';
import UnorderedListStyles from './components/UnorderedList.css';
import ULRedesignStyles from './components/UnorderedListRedesign.css';
import VideoStyles from './components/Video.css';
import { VideoPlatform } from './components/VideoPlatform';
import { VideoPlatformLive } from './components/VideoPlatform/components/VideoPlatformLive';
import YoutubeStyles from './components/Youtube.css';

type GetRenderPropsType = {
  forceRedesign: boolean;
  isMulticluster: boolean;
  outerProps: OuterPropsType<OuterDraftPropsType>;
  customRenderers?: CustomRendererType;
};

/**
 * Функция получения кастомного рендера.
 * @param props - пропсы
 * @param props.forceRedesign - флаг, что это редизайн;
 * @param props.isMulticluster - флаг, что мультикластер;
 * @param props.customRenderers - дополнительные рендеры, объявляемые окружением;
 * @param props.outerProps - внешние пропсы.
 * @returns объект кастомного рендера для драфта.
 */
export const getRender = ({
  forceRedesign,
  isMulticluster,
  outerProps,
  customRenderers,
}: GetRenderPropsType) => {
  const LinkStyles = isMulticluster
    ? MulticlusterLinkStyles
    : ClusterLinkStyles;

  const ULStyles = forceRedesign ? ULRedesignStyles : UnorderedListStyles;
  const ImageStyles = forceRedesign ? ImageRedesignStyles : ImgStyles;
  const OLStyles = forceRedesign ? OLRedesignStyles : OrderedListStyles;

  return {
    blocks: {
      [BLOCK_TYPES.H1]: JSXToBlockRenderer({ Component: H1, styles: H1Styles }),
      [BLOCK_TYPES.H2]: JSXToBlockRenderer({ Component: H2 }),
      [BLOCK_TYPES.H3]: JSXToBlockRenderer({ Component: H3 }),

      /** H4 - H6 референсов не имеют, поэтому пока без них */
      [BLOCK_TYPES.Blockquote]: JSXToBlockRenderer({
        Component: Blockquote,
      }),
      [BLOCK_TYPES.ULItem]: JSXToBlockRenderer({
        Component: UnorderedList,
        styles: ULStyles,
        outerProps,
      }),
      [BLOCK_TYPES.OLItem]: JSXToBlockRenderer({
        Component: OrderedList,
        styles: OLStyles,
        outerProps,
      }),

      /** Кастомные блоки, специфичные для кластера */
      [CUSTOM_BLOCK_TYPE.Banner]: JSXToBlockRenderer({
        Component: Banner,
        outerProps,
      }),

      [CUSTOM_BLOCK_TYPE.ShortSummary]: JSXToBlockRenderer({
        Component: ShortSummaryBlock,
      }),
      ...(customRenderers?.blocks || {}),
    },
    entities: {
      [ENTITY_TYPES.Link]: JSXToEntityRenderer({
        Component: Link,
        styles: LinkStyles,
        outerProps,
      }),
      [ENTITY_TYPES.VideoPlatform]: JSXToEntityRenderer({
        Component: VideoPlatform,
        outerProps,
      }),
      [ENTITY_TYPES.VideoPlatformLive]: JSXToEntityRenderer({
        Component: VideoPlatformLive,
        outerProps,
      }),
      [ENTITY_TYPES.Video]: JSXToEntityRenderer({
        Component: Video,
        styles: VideoStyles,
        outerProps,
      }),
      [ENTITY_TYPES.RTag]: JSXToEntityRenderer({
        Component: RTag,
        outerProps: { ...outerProps, isMulticluster },
      }),
      [ENTITY_TYPES.Image]: JSXToEntityRenderer({
        Component: Image,
        styles: ImageStyles,
        outerProps,
      }),
      [ENTITY_TYPES.Youtube]: JSXToEntityRenderer({
        Component: Youtube,
        styles: YoutubeStyles,
        outerProps,
      }),
      [ENTITY_TYPES.Expert]: JSXToEntityRenderer({
        Component: Expert,
        styles: ExpertStyles,
      }),
      [ENTITY_TYPES.Legacy]: JSXToEntityRenderer({
        Component: Legacy,
        styles: LegacyStyles,
      }),
      [ENTITY_TYPES.RamblerQuiz]: JSXToEntityRenderer({
        Component: RamblerQuiz,
        styles: RamblerQuizStyles,
      }),
      ...(customRenderers?.entities || {}),
    },
  };
};
