import cn from 'classnames';
import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { selectIsMobile, selectRuntime } from 'common/redux/runtime/selectors';
import { CONTENT_WIDTH_MOBILE } from 'config/common/css';
import { HEAD_DOMAIN } from 'config/common/project/constants';

import { useRedirect } from './hooks/useRedirect';
import { useScrollToSite } from './hooks/useScrollToSite';
import { useSwipe } from './hooks/useSwipe';
import { PortalLoader } from './loader';
import { ScrollToSitePropsType } from './typings';

import s from './styles.module.css';

const REDIRECT_PERCENT = 50;
const REDIRECT_DELAY = 0;

const DESKTOP_CONTAINER_ACTIVE_STYLES: React.CSSProperties = {
  position: 'fixed',
  left: '50%',
  transform: 'translateX(-50%)',
};

const MOBILE_CONTAINER_ACTIVE_STYLES: React.CSSProperties = {
  position: 'fixed',
  maxWidth: `${CONTENT_WIDTH_MOBILE}px`,
  left: 'auto',
};

/**
 * Механика ScrollToSite для имитации плавного перехода на другой домен по доскроллу
 * @param props - пропсы
 * @param props.active - активность s2s;
 * @param props.isSwipe - включение возможности свайпнуть лэйбл;
 * @param props.withoutLoader - отключение лоадера перед переходом;
 * @param props.withoutAnimation - отключение анимации перед переходом;
 * @param props.link - ссылка для перехода;
 * @param props.labelSwipeLink - ссылка для перехода по свайпу лейбла;
 * @param props.labelClickLink - ссылка для перехода по клику на лейбл;
 * @param props.slot - слот для вставки контента в s2s;
 * @param props.slotLabel - слот для вставки лейбла в s2s;
 * @param props.s2sClassName  - дополнительные классы для обертки слота;
 * @param props.loaderStyle - дополнительные стили для лоадера;
 * @param props.loaderClassName - дополнительные классы для лоадера;
 * @param props.redirectPercent - процент, после которого происходит s2s переход по доскроллу;
 * @param props.redirectDelay - время в мс, на которое будет осуществлена задержка перед переходом;
 * @param props.redirectCallback - callback, срабатывающий перед переходом по скроллу;
 * @param props.labelSwipeCallback - callback, срабатывающий перед переходом по свайпу;
 * @param props.labelClickCallback - callback, срабатывающий после клика на лейбл;
 * @param props.children - контент, оборачиваемый в s2s;
 * @param props.commercialClassName - дополнительный класс.
 */
const ScrollToSiteComponent = function ScrollToSite({
  active = true,
  isSwipe = false,
  withoutLoader = false,
  withoutAnimation = false,
  link = HEAD_DOMAIN,
  labelSwipeLink = '',
  labelClickLink = '',
  slot,
  slotLabel,
  s2sClassName = '',
  loaderStyle,
  loaderClassName = '',
  redirectPercent = REDIRECT_PERCENT,
  redirectDelay = REDIRECT_DELAY,
  redirectCallback,
  labelSwipeCallback,
  labelClickCallback,
  children,
  commercialClassName,
}: ScrollToSitePropsType) {
  const runtime = useSelector(selectRuntime, shallowEqual);
  const isMobile = useSelector(selectIsMobile);

  const { activeS2S, s2sRef, stickyRef, spacerStickyRef } =
    useScrollToSite<HTMLDivElement>(active);

  useEffect(() => {
    if ('scrollRestoration' in history) {
      // Back off, browser, I got this...
      history.scrollRestoration = 'manual';
    }
  }, []);

  const { animation, activeRedirect } = useRedirect({
    activeS2S,
    s2sRef,
    link,
    redirectOptions: {
      redirectPercent,
      redirectCallback,
      redirectDelay,
    },
  });

  const { s2sStyles, labelRef, onMouseDown } = useSwipe({
    activeS2S,
    slotLabel,
    link: labelSwipeLink,
    callback: labelSwipeCallback,
  });

  const styles = isMobile
    ? MOBILE_CONTAINER_ACTIVE_STYLES
    : DESKTOP_CONTAINER_ACTIVE_STYLES;

  return (
    <>
      <div>
        <div
          ref={stickyRef}
          className={cn(s.sticky, commercialClassName)}
          style={activeS2S ? styles : undefined}
        >
          {children}
        </div>
      </div>
      {active ? (
        <>
          <div
            ref={spacerStickyRef}
            className={s.spacer}
            style={
              activeS2S
                ? {
                    display: 'block',
                  }
                : undefined
            }
          />
          <div ref={s2sRef} />
          <div
            className={cn(s.s2s, s2sClassName, {
              [s.s2s_stub]: !slot,
              [s.s2s_animation]: !withoutAnimation && animation,
            })}
            style={s2sStyles}
          >
            {slotLabel
              ? slotLabel({
                  isSwipe,
                  link: labelClickLink,
                  active: activeS2S,
                  onMouseDown,
                  labelRef,
                  runtime,
                  onClick: labelClickCallback,
                })
              : null}
            <div className={s.slotWrapper}>
              {slot ? slot({ active: activeS2S }) : null}
            </div>
          </div>
          {slotLabel ? (
            <div
              className={s.spacer_s2s}
              style={
                !activeS2S
                  ? {
                      display: 'block',
                    }
                  : undefined
              }
            />
          ) : null}
        </>
      ) : null}
      {!withoutLoader && activeRedirect ? (
        <PortalLoader style={loaderStyle} className={loaderClassName} />
      ) : null}
    </>
  );
};

export const ScrollToSite = withErrorBoundary(ScrollToSiteComponent);
