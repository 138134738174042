import { API_NAMES } from 'api/typings';
import { ACCEPTED_SWITCHERS } from 'config/common/debugger/typings';
import { PROJECT_ALIASES } from 'config/common/project/typings';
import { RCM_BLOCK_TYPE } from 'config/common/rcm/typings';
import { PAGE_TYPE } from 'config/common/router/typings';
import { HUMAN_CENTERED_STRATEGY_SPLIT_VALUES } from 'config/common/splits/typings';

export const selectRuntime = (state: AppState): Runtime => state.runtime;

export const selectApiConfig = (state: AppState) =>
  selectRuntime(state).apiConfig;

export const selectCacheConfig = (state: AppState) =>
  selectRuntime(state).cacheConfig;

export const selectPushwoosh = (state: AppState) =>
  selectRuntime(state).pushwoosh;

export const selectApiConfigByName =
  (apiName: API_NAMES) => (state: AppState) =>
    selectApiConfig(state)[apiName] ?? selectApiConfig(state).default;

export const selectApiUrlByName = (apiName: API_NAMES) => (state: AppState) => {
  const value = selectApiConfigByName(apiName)(state);

  if (typeof value === 'string') return value;

  return value.url;
};

export const selectProject = (state: AppState) => selectRuntime(state).project;

export const selectProjectId = (state: AppState): ProjectType['id'] =>
  selectProject(state).id;

export const selectProjectDetails = (state: AppState): ProjectType['details'] =>
  selectProject(state).details;

export const selectTeamDescription = (state: AppState) =>
  selectProject(state).team_description;

export const selectCurrentServerTime = (state: AppState) =>
  selectRuntime(state).currentServerTime;

export const selectProjectAlias = (state: AppState): PROJECT_ALIASES =>
  selectProject(state).alias;

export const selectProjectName = (state: AppState) => selectProject(state).name;

export const selectProjectCommentsAppId = (state: AppState) =>
  selectProject(state).comments_appid;

export const selectProfile = (state: AppState) => selectRuntime(state).profile;

export const selectSentryConfig = (state: AppState) =>
  selectRuntime(state).sentryConfig;

export const selectDebuggerConfig = (state: AppState) =>
  selectRuntime(state).debuggerConfig;

export const selectCustomQueries = (state: AppState) =>
  selectDebuggerConfig(state).customQueries;

export const selectCustomClusterFeed = (state: AppState) =>
  selectCustomQueries(state).clusterFeed;

export const selectSplits = (state: AppState) =>
  selectDebuggerConfig(state).splits;

export const selectSplitsConfig = (state: AppState) =>
  selectDebuggerConfig(state).splitsConfig;

export const selectSwitchers = (state: AppState) =>
  selectDebuggerConfig(state).switchers;

export const selectForceAdFallback = (state: AppState) =>
  selectSwitchers(state)[ACCEPTED_SWITCHERS.forceAdFallback].value;

export const selectPromoWidgetEnabled = (state: AppState) =>
  selectSwitchers(state)[ACCEPTED_SWITCHERS.promoWidgetEnabled].value;

export const selectPromoWidgetPlaceholderEnabled = (state: AppState) =>
  selectSwitchers(state)[ACCEPTED_SWITCHERS.promoWidgetPlaceholderEnabled]
    .value;

export const selectGigaEnabled = (state: AppState) =>
  selectSwitchers(state)[ACCEPTED_SWITCHERS.gigaEnabled].value;

export const selectArticleEnabled = (state: AppState) =>
  selectSwitchers(state)[ACCEPTED_SWITCHERS.forceArticle].value;

export const selectTopicUpdaterEnabled = (state: AppState) =>
  selectSwitchers(state)[ACCEPTED_SWITCHERS.changeMeToUpdateTopics].value;

export const selectHumanCenteredStrategyValueFromSplits = (
  splits: DebuggerConfigType['splits'],
) => splits?.humanCenteredStrategy;

export const selectHumanCenteredStrategyValue = (state: AppState) =>
  selectHumanCenteredStrategyValueFromSplits(selectSplits(state));

export const selectIsHumanCenteredStrategyStateActivated = (
  state: AppState,
) => {
  const value = selectHumanCenteredStrategyValueFromSplits(selectSplits(state));

  if (!value || state.appController.pageName === PAGE_TYPE.longread)
    return true;

  return ![
    HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.default,
    HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.off,
    HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.fullscreen_off,
    HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.fullscreen_on,
  ].includes(value);
};

export const selectAdtech = (state: AppState) => selectRuntime(state).adtech;

export const selectRcmId = (state: AppState) => selectAdtech(state).rcmID;

export const selectRecommendBlockID =
  (recommendBlockID: RCM_BLOCK_TYPE | undefined) => (state: AppState) =>
    recommendBlockID
      ? selectAdtech(state).recommendBlockID[recommendBlockID]
      : '';

export const selectAdtechUid = (state: AppState) =>
  selectAdtech(state).uid || '';

export const selectAdtechScope = (state: AppState) =>
  selectAdtech(state).scope || '';

export const selectRuid = (state: AppState) => selectRuntime(state).ruid || '';

export const selectUserId = (state: AppState) =>
  selectRuntime(state).userId ?? null;

export const selectDomainConfig = (state: AppState) =>
  selectRuntime(state).domainConfig;

export const selectPostfix = (state: AppState) =>
  selectDomainConfig(state).postfix;

export const selectProtocol = (state: AppState) =>
  selectDomainConfig(state).protocol;

export const selectBanners = (state: AppState) => selectRuntime(state).banners;

export const selectCommercialS2S =
  (clusterId: number | undefined | null) => (state: AppState) =>
    clusterId ? selectRuntime(state).commercialS2S?.[clusterId] : null;

export const selectSettings = (state: AppState): SettingsType =>
  selectRuntime(state).settings;

export const selectIsDebug = (state: AppState) => selectSettings(state).isDebug;

export const selectIsRussia = (state: AppState) =>
  selectSettings(state).isRussia;

export const selectIsMobile = (state: AppState) =>
  selectSettings(state).isMobile;

export const selectIsBot = (state: AppState) => selectSettings(state).isBot;

export const selectVersion = (state: AppState) => selectRuntime(state).version;

export const selectIsPWA = (state: AppState) => selectSettings(state).isPWA;

export const selectVariables = (state: AppState) =>
  selectRuntime(state).variables || {};

export const selectVariablesBannedCommentsTopics = (state: AppState) =>
  selectVariables(state).topicsWithDisabledComments || '';

export const selectIsBannedCommentsTopic =
  (topicAlias: TopicType['alias'] | undefined) => (state: AppState) =>
    selectVariablesBannedCommentsTopics(state).includes(topicAlias as string);

export const selectSberWidgetErid = (state: AppState) =>
  selectVariables(state).sberWidgetErid || '';

export const selectVariablesBatchInterval = (state: AppState) => {
  const value = Number(selectVariables(state).metricsBatchInterval);

  /*
   * Защита от дурака, если кто-то проставит значение, меньше 10
   * 10 - дефолтное значение, плюс интервал ниже 10 будет убийственным для прода
   */
  if (Number.isNaN(value) || value < 10) return undefined;

  return value;
};

export const selectVariablesBatchSamplePercent = (state: AppState) => {
  const value = Number(selectVariables(state).metricsBatchSamplePercent);
  if (Number.isNaN(value) || value > 100 || value < 0) return undefined;

  return value;
};

// Получение шанса, что когда клиент уходит со страницы, то данные будут отправлены. Да, для этого сделана отдельная метрика. Если удасться стабилизировать работу прода или вынести код по сбору метрик с клиента на другое приложение, то этот параметр будет не нужен.
export const selectVariablesBatchSampleClientLeavePercent = (
  state: AppState,
) => {
  const value = Number(
    selectVariables(state).metricsBatchSampleClientLeavePercent,
  );
  if (Number.isNaN(value) || value > 100 || value < 0) return undefined;

  return value;
};
