import React, { memo, useCallback, useEffect } from 'react';

import { useClusterChangeUrlListener } from 'common/hooks/useClusterChangeUrl/listener';
import { textToSpeech } from 'utils/textToSpeech';

import { PauseButton } from './components/PauseButton';
import { PlayButton } from './components/PlayButton';
import { ChangeSpeak } from './typings';
import { pauseSpeech } from './utils';

type TextToSpeechPropsType = {
  clusterId: Cluster['id'];
  clusterTitle: Cluster['longTitle'];
  clusterBody: Cluster['body'];
  speaking: ClusterSpeakingType;
  changeSpeak: ChangeSpeak;
  customStyles?: StylesType;
  isMobile?: boolean;
};

/**
 * Кнопка запуска озвучки текста кластера.
 * @param props - пропсы
 * @param props.clusterId - id кластера, для которого воспроизводится текст;
 * @param props.clusterTitle - заголовок кластера;
 * @param props.clusterBody - тело (html) кластера;
 * @param props.speaking - объект активности воспроизведения текста;
 * @param props.changeSpeak - метод изменения контроллера воспроизведения;
 * @param props.customStyles - объект стилизации компонента.
 */
const TextToSpeechComponent = function TextToSpeech({
  clusterId,
  clusterTitle,
  clusterBody,
  speaking,
  changeSpeak,
  customStyles = {},
}: TextToSpeechPropsType) {
  const { textToSpeechAvailable } = textToSpeech;

  const onClusterChange = useCallback(
    () => pauseSpeech(changeSpeak),
    [changeSpeak],
  );

  useClusterChangeUrlListener(onClusterChange);

  useEffect(() => {
    const callback = (e: MessageEvent<any>) => {
      if (!e.data) return;

      const { origin, event } = e.data;

      if (
        origin === 'eagleplayer' &&
        ['userChangeState', 'advertisement_state'].includes(event)
      ) {
        pauseSpeech(changeSpeak);
      }
    };

    // Останавливаем озвучку при воспроизведении видео.
    window.addEventListener('message', callback);

    return () => {
      window.removeEventListener('message', callback);
    };
  }, [changeSpeak]);

  if (speaking.play && clusterId === speaking.clusterId)
    return (
      <PauseButton
        containerClassName={customStyles.textToSpeechBtn}
        iconClassName={customStyles.icon}
        iconMuteClassName={customStyles.iconMute}
        onChange={changeSpeak}
      />
    );

  return (
    <PlayButton
      clusterId={clusterId}
      clusterBody={clusterBody}
      clusterTitle={clusterTitle}
      speakingClusterId={speaking.clusterId}
      speakingIsPaused={speaking.pause}
      containerClassName={customStyles.textToSpeechBtn}
      iconClassName={customStyles.icon}
      iconVolumeClassName={customStyles.iconVolume}
      onChange={changeSpeak}
      disabled={!textToSpeechAvailable}
    />
  );
};

export const TextToSpeech = memo(TextToSpeechComponent);
