import {
  countTextLengthPerBlock,
  draftModifier,
} from '@media-components/draft-parser';

import { CLUSTER_TYPE } from 'config/common/cluster/typings';

import { EntityModifierType, InjectorGetterType } from './typings';

/**
 * Функция для проверки, что у кластера есть галлерея.
 * @param cluster - объект кластера.
 * @returns флаг, что у кластера есть галлера.
 */
export const isClusterHasGallery = (cluster: Cluster) =>
  cluster.type === CLUSTER_TYPE.photo && !!cluster.gallery;

type GetModifyDraftType = (props: {
  draft: RawDraftContentState;
  injectorsGetter: InjectorGetterType;
  entityModifiers?: EntityModifierType[];
}) => RawDraftContentState;

/**
 * Функция модификации драфта под условия текста десктопного и мобильного кластеров.
 * @param props - пропсы
 * @param props.draft - блок драфта
 * @param props.injectorsGetter - функция получения массива инжекторов
 * @param props.entityModifiers - функция модификации энтити кластера
 */
export const getModifyDraft: GetModifyDraftType = ({
  draft,
  injectorsGetter,
  entityModifiers,
}) => {
  const blocksLength = countTextLengthPerBlock(draft.blocks);

  const updatedDraft = draftModifier({
    draft,
    injectors: injectorsGetter({
      blocksLength,
    }),
  });

  if (entityModifiers) {
    return {
      blocks: updatedDraft.blocks,
      entityMap: entityModifiers.reduce((entities, modifier) => {
        return modifier(entities);
      }, updatedDraft.entityMap),
    };
  }

  return updatedDraft;
};
