import { BlockElementPropsType } from '@media-components/draft-parser';
import React, { memo } from 'react';

import { BorderedQuote } from 'common/components/BorderedQuote';

import s from './styles.module.css';

/**
 * Компонент блока цитаты.
 * @param props - параметры компонента;
 * @param props.children - информация о содержимом блока цитаты.
 */
const BlockquoteComponent = function Blockquote({
  children,
}: BlockElementPropsType<unknown, unknown>) {
  const modifiedChildren = children.map((child, index) => {
    if (index > 0 && child[1]?.toString().length === 1) {
      return [...child, '\n\n'];
    }

    return child;
  });

  return <BorderedQuote className={s.root}>{modifiedChildren}</BorderedQuote>;
};

export const Blockquote = memo(BlockquoteComponent);
