import cn from 'classnames';
import React, { memo } from 'react';

import s from './styles.module.css';

/**
 * Цветные блямбы.
 */
const BackgroundBulbsComponents = function BackgroundBulbs() {
  return (
    <div className={s.root}>
      <div className={cn(s.bulb, s.left)} />
      <div className={cn(s.bulb, s.right)} />
    </div>
  );
};

export const BackgroundBulbs = memo(BackgroundBulbsComponents);
