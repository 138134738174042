import React from 'react';

import { CarouselSlide } from './CarouselDesktop/components/CarouselSlide';
import stylesCarouselSlide from './CarouselDesktop/components/CarouselSlide/styles.module.css';
import { CarouselSlideBanner } from './CarouselDesktop/components/CarouselSlideBanner';
import stylesCarouselSlideBanner from './CarouselDesktop/components/CarouselSlideBanner/styles.module.css';
import { CarouselSlideRecommendation } from './CarouselDesktop/components/CarouselSlideRecommendation';
import stylesCarouselSlideRecommendation from './CarouselDesktop/components/CarouselSlideRecommendation/styles.module.css';
import { BANNER_REPEAT } from './constants';
import {
  SLIDE_TYPE,
  SlidesConfigListDesktopPropsType,
  SlidesConfigListType,
} from './typings';

/**
 * Генерация массива слайдов для десктопа.
 * @param props - пропсы
 * @param props.cluster - кластер;
 * @param props.clusterPuids - рекламные пуиды кластер;
 * @param props.recommendedClusters - рекомендательные кластера;
 * @param props.bannersVisible - видимы ли баннеры.
 */
export const getSlidesConfigListDesktop = ({
  cluster,
  clusterPuids,
  recommendedClusters,
  bannersVisible = true,
}: SlidesConfigListDesktopPropsType) =>
  cluster?.gallery?.reduce((acc, slide, index) => {
    const slideIndex = index + 1;
    const isLastSlide = slideIndex === cluster.gallery.length;
    const isBannerSlide = slideIndex % BANNER_REPEAT === BANNER_REPEAT - 1;
    const isInsertBanner =
      bannersVisible && index > 0 && !isLastSlide && isBannerSlide;

    acc.push({
      type: SLIDE_TYPE.image,
      hash: `${slideIndex}`,
      component: (
        <CarouselSlide
          key={slide.id}
          slide={slide}
          slideIndex={slideIndex}
          cluster={cluster}
          styles={stylesCarouselSlide}
        />
      ),
    });

    if (isInsertBanner) {
      acc.push({
        type: SLIDE_TYPE.banner,
        hash: `a${slideIndex}`,
        component: (
          <CarouselSlideBanner
            key={slideIndex}
            puids={clusterPuids}
            styles={stylesCarouselSlideBanner}
          />
        ),
      });
    }

    if (isLastSlide) {
      acc.push({
        type: SLIDE_TYPE.recommendation,
        hash: 'more',
        component: (
          <CarouselSlideRecommendation
            key="more"
            recommendedClusters={recommendedClusters}
            styles={stylesCarouselSlideRecommendation}
          />
        ),
      });
    }

    return acc;
  }, [] as SlidesConfigListType[]);
