import { BlockElementPropsType } from '@media-components/draft-parser';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { Ad } from 'common/components/Ad';
import { useDraftContext } from 'common/components/ClusterContentOnDraft/context';
import {
  BannerDataType,
  BANNER_REPLACEMENT_SLOT,
} from 'common/components/ClusterContentOnDraft/draftModifier/generators/bannerBlockGenerator';
import { selectIsMobile } from 'common/redux/runtime/selectors';

import { VideoAdIframeWrapper } from './components/VideoAdIframe';

import s from './styles.module.css';

/**
 * Функция генерации блока вставки в рекламу внутри текста кластера.
 * @param bannerReplacementSlot - наименование блока вставки;
 * @param clusterId - id кластера, для которого рисуется блок вставки.
 */
const getBannerReplacementElement = (
  bannerReplacementSlot: BANNER_REPLACEMENT_SLOT | undefined,
  clusterId: Card['id'],
) => {
  if (bannerReplacementSlot === BANNER_REPLACEMENT_SLOT.VideoAdIframe) {
    return <VideoAdIframeWrapper clusterId={clusterId} />;
  }

  return null;
};

/**
 * Компонент рендера блока рекламы.
 * @param props - пропсы
 * @param props.data - объект со специфичными данными для блока:
 * - name - наименование баннера;
 * - isLazy - флаг ленивой загрузки баннера;
 * - onRenderStyle - дополнительные стили для баннера.
 */
const BannerComponent = function Banner({
  data,
}: BlockElementPropsType<BannerDataType, unknown>) {
  const { puids } = useDraftContext();
  const isMobile = useSelector(selectIsMobile);

  if (!data?.[0]?.name) {
    return null;
  }

  const {
    name,
    clusterId,
    isLazy,
    onRenderStyle,
    bannerReplacementSlot,
    className,
  } = data[0];

  const bannerReplacementElement = getBannerReplacementElement(
    bannerReplacementSlot,
    String(clusterId),
  );

  return (
    <>
      {isMobile && (
        <div className={s.readAfter}>Продолжение истории после рекламы</div>
      )}
      <Ad
        name={name}
        puids={puids}
        isLazy={isLazy}
        loadedStyle={onRenderStyle}
        bannerReplacementSlot={bannerReplacementElement}
        className={className}
      />
    </>
  );
};

export const Banner = memo(
  BannerComponent,
  (prev, next) => prev?.data?.[0]?.name === next?.data?.[0]?.name,
);
