import { EntityPropsType } from '@media-components/draft-parser';
import React, { memo } from 'react';

import { LegacyDataPropsType } from 'common/components/ClusterContentOnDraft/typings';
import { RamblerInfoBanner } from 'common/components/RamblerEmbeds/RamblerInfoBanner';

import { getIsRamblerInfoBanner } from './utils';

/**
 * Компонент легаси эмбеда. Его не смог распарсить бек.
 * @param props - данные компонента;
 * @param props.data - объект со специфичными данными для блока:
 * - data.embed.htmlCode - html-код эмбеда, который не смог распарсить бек;
 * @param props.styles - дополнительные стили.
 */
const LegacyComponent = function Legacy({
  data,
  styles,
}: EntityPropsType<LegacyDataPropsType>) {
  const htmlCode = data?.data?.embed?.htmlCode;

  if (!htmlCode) {
    return null;
  }

  const isRamblerInfoBanner = getIsRamblerInfoBanner(htmlCode);

  if (isRamblerInfoBanner) {
    const bannerId = htmlCode.match(/banner-id="(.+?)"/)?.[1];

    return bannerId ? <RamblerInfoBanner bannerId={bannerId} /> : null;
  }

  // Ключевое слово, если эмбед не попал в более широкий список разрешенных эмбедов.
  if (!htmlCode.includes('data-draft="force"')) {
    return null;
  }

  return (
    <div
      className={styles?.root}
      dangerouslySetInnerHTML={{ __html: htmlCode }}
    />
  );
};

export const Legacy = memo(
  LegacyComponent,
  (prev, next) =>
    prev.data.data.embed.htmlCode === next.data.data.embed.htmlCode,
);
