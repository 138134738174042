import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { RamblerFooter } from 'common/components/RamblerComponents/RamblerFooter';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { selectProjectAlias } from 'common/redux/runtime/selectors';
import { generateFooterSocial, generateFooterMenu } from 'config/common/footer';

/**
 * Компонент Footer страницы
 */
const FooterComponent = function Footer() {
  const projectAlias = useSelector(selectProjectAlias);
  const top100AttributeFooter = useTop100AttributeWithValue('footer');

  const menuLinks = generateFooterMenu({
    project: projectAlias,
    isMobile: false,
  });

  return (
    <div className="footer" {...top100AttributeFooter}>
      <RamblerFooter
        simpleLinks={menuLinks}
        socialLinks={generateFooterSocial()}
      />
    </div>
  );
};

export const Footer = memo(FooterComponent);
