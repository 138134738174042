import {
  disableBodyScroll as disableScroll,
  enableBodyScroll as enableScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import { useRef, useEffect, useCallback } from 'react';

/** https://github.com/willmcpo/body-scroll-lock */

/**
 * Хук-помощник для блокировки скролла при D&D элементов
 */
export const useBodyScrollLock = () => {
  const ref = useRef<HTMLElement>(null);

  useEffect(() => clearAllBodyScrollLocks, []);

  const disableBodyScroll = useCallback(() => {
    if (ref && ref.current) disableScroll(ref.current);
  }, []);

  const enableBodyScroll = useCallback(() => {
    if (ref && ref.current) enableScroll(ref.current);
  }, []);

  return { ref, disableBodyScroll, enableBodyScroll };
};
