import React from 'react';

import { Screenshot } from 'common/components/ScrollToSite/components/Screenshot';
import { SlotPropsType } from 'common/components/ScrollToSite/typings';

type GetSlotPropsType = {
  img: CommercialClusterS2S['img'];
  alt: CommercialClusterS2S['alt'];
};

/**
 * Функция для получения компонента для пропса "slot".
 * @param props - пропсы
 * @param props.img - src изображения
 * @param props.alt - alt изображения
 */
export const getSlot =
  ({ img, alt }: GetSlotPropsType) =>
  (props: SlotPropsType) => <Screenshot src={img} alt={alt} {...props} />;
