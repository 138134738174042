import { getRamblerPlayerPuids } from 'common/utils/searchIframe';

import { getPlayerTemplateId } from './getPlayerTemplateId';

export const RECORD_ID_REGEXP = /data-id="(\S+)"|record_id=(\S*?)/;
const MULTIPLAYER_FILTER_ID_REGEXP = /multiplayer-filter-id="(\d+)"/;
const PLAYER_TEMPLATE_ID_REGEXP = /player-template-id="(\d+)"/;
const PREVIEW_COMMENT_REGEXP = /<!--(.)*-->/;

type VideoPlayerParamType = {
  recordId: string;
  multiplayerFilterId: number | undefined;
  playerTemplateId: number | undefined;
  sspJparams: PuidsType | undefined;
  previewComment?: string;
};

/**
 * Получение параметров для плеера от EaglePlatform
 * @param html - html-код видео плеера
 * @param puids - рекламные пуиды
 * @param isAd - флаг что это рекламное видео,
 */
export const getVideoParams = (
  html: string | undefined,
  puids: PuidsType | undefined,
  isAd: boolean,
): VideoPlayerParamType => {
  const recordId =
    (html?.match(RECORD_ID_REGEXP) || []).filter((el) => el)?.[1] || '';
  const multiplayerFilterId =
    Number(html?.match(MULTIPLAYER_FILTER_ID_REGEXP)?.[1]) || undefined;
  const playerTemplateIdFromHtml =
    Number(html?.match(PLAYER_TEMPLATE_ID_REGEXP)?.[1]) || undefined;
  const playerTemplateId = getPlayerTemplateId(playerTemplateIdFromHtml, isAd);
  const sspJparams = getRamblerPlayerPuids({ puids });
  const previewComment = html?.match(PREVIEW_COMMENT_REGEXP)?.[0];

  return {
    recordId,
    multiplayerFilterId,
    playerTemplateId,
    sspJparams,
    previewComment,
  };
};
