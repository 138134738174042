import { FINISH_LENGTH, MIDLE_LENGTH, START_LENGTH } from './constants';
import { CROSS_RATE, CrossDescriptionType } from './typings';

/**
 * Функция получения первой и второй валюты в кросс-курсе.
 * @param charCode - код кросс-курса типа EURUSD.
 */
export const getCrossCurrencies = (charCode: CROSS_RATE) => {
  const firstCharCode = charCode.substring(
    START_LENGTH,
    MIDLE_LENGTH,
  ) as CurrencyType['charCode'];

  const secondCharCode = charCode.substring(
    MIDLE_LENGTH,
    FINISH_LENGTH,
  ) as CurrencyType['charCode'];

  return { firstCharCode, secondCharCode };
};

type GetCrossTitlePropsType = {
  firstCurrency: CurrencyType['charCode'];
  secondCurrency: CurrencyType['charCode'];
  descriptions: CrossDescriptionType;
};

/**
 * Функция получения заголовка кросс-курса.
 * @param props - пропсы
 * @param props.firstCurrency - первая валюта кросс-курса;
 * @param props.secondCurrency - вторая валюта кросс-курса;
 * @param props.descriptions - коллекция описаний к кросс-курсам.
 */
export const getCrossTitle = ({
  firstCurrency,
  secondCurrency,
  descriptions,
}: GetCrossTitlePropsType) => {
  const charCode = `${firstCurrency}${secondCurrency}` as CROSS_RATE;

  const data = descriptions[charCode];

  if (!data?.description) return '';

  return data.description
    .toLowerCase()
    .replace(/курс/g, 'Кросс-курс')
    .replace(/сша/g, 'США');
};
