import cn from 'classnames';
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAnalytics } from 'common/hooks/useAnalytics';
import {
  fontEnlargeButtonAnalyticsConfig,
  fontReduceButtonAnalyticsConfig,
} from 'common/hooks/useAnalytics/configs';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import {
  MAX_TEXT_SCALE_VALUE,
  MIN_TEXT_SCALE_VALUE,
  TextScaleValueType,
} from 'common/redux/pages/cluster';
import { initTextScale } from 'common/redux/pages/cluster/utils';
import { MinusIcon, PlusIcon } from 'icons';

import { clearTextScalePlug } from './utils';

type TextScalePropsType = {
  textScaleValue: TextScaleValueType;
  textScaleUp: React.MouseEventHandler<HTMLButtonElement>;
  textScaleDown: React.MouseEventHandler<HTMLButtonElement>;
  customStyles?: StylesType;
  textScaleMax?: number;
  textScaleMin?: number;
  isMobile?: boolean;
};

/**
 * Панель управления размером шрифта кластера
 * @param props - пропсы
 * @param props.textScaleValue - значение масштаба шрифта;
 * @param props.textScaleUp - увеличение размера шрифта;
 * @param props.textScaleDown - уменьшение размера шрифта;
 * @param props.customStyles - объект стилизации компонента;
 * @param props.textScaleMax - максимальное увеличение текста;
 * @param props.textScaleMin - минимальное увеличение текста;
 * @param props.isMobile - флаг мобильной версии.
 */
const TextScaleComponent = function TextScale({
  textScaleValue,
  textScaleUp,
  textScaleDown,
  customStyles = {},
  textScaleMax = MAX_TEXT_SCALE_VALUE,
  textScaleMin = MIN_TEXT_SCALE_VALUE,
  isMobile,
}: TextScalePropsType) {
  const { reachClick: reachEnlargeButtonClick } = useAnalytics(
    fontEnlargeButtonAnalyticsConfig,
    isMobile,
  );
  const { reachClick: reachReduceButtonClick } = useAnalytics(
    fontReduceButtonAnalyticsConfig,
    isMobile,
  );
  const dispatch = useDispatch();

  const top100AttributeFontUp = useTop100AttributeWithValue('font_up');
  const top100AttributeFontDown = useTop100AttributeWithValue('font_down');

  useEffect(() => {
    dispatch(initTextScale());
    clearTextScalePlug();
  }, [dispatch]);

  const handleScaleUpClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      textScaleUp(e);

      if (isMobile) {
        reachEnlargeButtonClick();
      }
    },
    [isMobile, reachEnlargeButtonClick, textScaleUp],
  );

  const handleScaleDownClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      textScaleDown(e);

      if (isMobile) {
        reachReduceButtonClick();
      }
    },
    [isMobile, reachReduceButtonClick, textScaleDown],
  );

  return (
    <div className={customStyles.textScaleWrapper}>
      <button
        type="button"
        disabled={textScaleValue === textScaleMax}
        className={cn(customStyles.textScaleBtn, {
          [customStyles.textScaleBtn_disabled]: textScaleValue === textScaleMax,
        })}
        onClick={handleScaleUpClick}
        aria-label="Увеличить шрифт"
        {...top100AttributeFontUp}
      >
        <PlusIcon className={customStyles.icon} />
      </button>
      <button
        type="button"
        className={cn(customStyles.textScaleBtn, {
          [customStyles.textScaleBtn_disabled]: textScaleValue === textScaleMin,
        })}
        disabled={textScaleValue === textScaleMin}
        onClick={handleScaleDownClick}
        aria-label="Уменьшить шрифт"
        {...top100AttributeFontDown}
      >
        <MinusIcon className={customStyles.icon} />
      </button>
    </div>
  );
};

export const TextScale = memo(TextScaleComponent);
