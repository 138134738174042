import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import {
  BreadcrumbList,
  NewsArticle,
  Organization,
  WithContext,
} from 'schema-dts';

import {
  getOrganization,
  getAuthor,
  getImageObject,
} from 'common/components/schemaOrg/modules';
import { getGetImageSrc } from 'common/components/schemaOrg/utils';
import { selectPageName } from 'common/redux/appController/selectors';
import { selectEditorById } from 'common/redux/commonData/editors/selectors';
import { selectResourceById } from 'common/redux/commonData/resources/selectors';
import { selectTopicById } from 'common/redux/commonData/topics/selectors';
import {
  selectDomainConfig,
  selectProjectAlias,
  selectProjectId,
  selectProjectName,
} from 'common/redux/runtime/selectors';
import { PROJECT_IDS } from 'config/common/project/typings';
import { PAGE_TYPE } from 'config/common/router/typings';
import { TOPIC_ID_PODCAST } from 'config/common/topic/constants';
import { getPureText } from 'utils/getPureText';
import { replaceLinks } from 'utils/replaceLinks';
import { textCroppingByWord } from 'utils/textCroppingByWord';
import { domainGenerator } from 'utils/urlGenerator';

const MILLISECONDS_REGEXP = /\.\d+/;

/**
 * Хук для получения item для NewsArticle
 * @param cluster - данные кластера;
 */
export const useItemForNewsArticle = (cluster: Cluster | undefined) => {
  const projectId = useSelector(selectProjectId);
  const projectName = useSelector(selectProjectName);
  const projectAlias = useSelector(selectProjectAlias);
  const domainConfig = useSelector(selectDomainConfig, shallowEqual);
  const pageName = useSelector(selectPageName);
  const resource = useSelector(
    selectResourceById(cluster?.resourceId),
    shallowEqual,
  );
  const editor = useSelector(selectEditorById(cluster?.editorId), shallowEqual);
  const topic = useSelector(
    selectTopicById(cluster?.mainTopicId),
    shallowEqual,
  );

  const domain = useMemo(
    () => domainGenerator(domainConfig, projectId),
    [domainConfig, projectId],
  );

  const {
    id,
    annotation,
    image,
    body = '',
    longTitle,
    publicationTime,
    modificationTime,
    creationTime,
    commentsCount,
    url: clusterUrl,
  } = cluster || {};

  const imageUrl = image?.url || '';
  const isS3Image = image?.s3 || false;
  const articleBody = getPureText(body)?.replace(/(\n)/g, '') || '';

  const pageImages = getGetImageSrc(imageUrl, isS3Image);

  const modificationTimeWithoutMs =
    typeof modificationTime === 'string'
      ? modificationTime.replace(MILLISECONDS_REGEXP, '')
      : '';
  const creationTimeWithoutMs =
    typeof creationTime === 'string'
      ? creationTime.replace(MILLISECONDS_REGEXP, '')
      : '';

  const commentsInfo =
    commentsCount === 0
      ? {}
      : {
          commentCount: commentsCount,
          discussionUrl: `${clusterUrl}comments/`,
        };

  const organization: Organization = useMemo(
    () =>
      getOrganization({
        domain,
        projectId,
        projectName,
        projectAlias,
      }),
    [domain, projectId, projectName, projectAlias],
  );

  const author = useMemo(
    () =>
      getAuthor({
        domain,
        projectName,
        resource,
        editor,
      }),
    [domain, projectName, editor, resource],
  );

  const description = useMemo(
    () =>
      annotation && typeof annotation === 'string'
        ? replaceLinks(annotation)
        : '',
    [annotation],
  );

  const imageSource = image?.source?.title || resource?.title || projectName;

  const keywordPrefix = topic?.name ? `${topic.name}, ` : '';
  const keywords = `${keywordPrefix}${longTitle}`;

  return {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    '@id': clusterUrl,
    articleBody,
    description,
    identifier: {
      '@type': 'PropertyValue',
      propertyID: 'Article id',
      value: id,
    },
    image: getImageObject({
      imgUrls: pageImages,
      name: longTitle,
      description: longTitle,
      imageSource,
    }),
    genre: 'news',
    articleSection: topic?.name || '',
    headline: textCroppingByWord({ str: longTitle, maxLength: 110 }),
    datePublished: publicationTime,
    dateModified: modificationTimeWithoutMs,
    dateCreated: creationTimeWithoutMs,
    author,
    name: longTitle,
    keywords,
    publisher: organization,
    about: topic
      ? {
          '@type': 'Thing',
          name: topic.name,
        }
      : null,
    url: clusterUrl,
    thumbnailUrl: pageImages,
    mainEntityOfPage: clusterUrl,
    ...commentsInfo,
    speakable: {
      '@type': 'SpeakableSpecification',
      cssSelector: ['#headline', '#summary'],
    },
    ...(pageName === PAGE_TYPE.cluster &&
    projectId === PROJECT_IDS.News &&
    topic?.id === TOPIC_ID_PODCAST
      ? {
          associatedMedia: {
            '@type': 'MediaObject',
            contentUrl: `https://cdn-store.rambler.ru/podcasts/${id}.mp3`,
            encodingFormat: 'audio/mpeg',
          },
        }
      : {}),
  } as WithContext<NewsArticle>;
};

/**
 * Хук для получения item для BreadcrumbList
 * @param topicId - id топика
 */
export const useItemForBreadcrumbList = (
  topicId: ATTopic['id'] | undefined,
) => {
  const domainConfig = useSelector(selectDomainConfig, shallowEqual);
  const projectId = useSelector(selectProjectId);
  const domain = useMemo(
    () => domainGenerator(domainConfig, projectId),
    [domainConfig, projectId],
  );
  const topic = useSelector(selectTopicById(topicId), shallowEqual);

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: {
      '@type': 'ListItem',
      name: topic?.name || '',
      position: '1',
      item: {
        '@type': 'Thing',
        '@id': topic ? `${domain}/${topic.alias}/` : `${domain}/`,
      },
    },
  } as WithContext<BreadcrumbList>;
};
