import _pick from 'lodash.pick';

/**
 * @param text - проверяемый на вставку видео через iframe текст
 */
export const checkIframeInText = (text: string) => text.includes('iframe');

// Puids, которые нужно передавать при вставке видео EaglePlatform
const RAMBLER_PLAYER_PUIDS: { [puid in string]: boolean } = {
  puid6: true,
  puid15: true,
  puid59: true,
  puid62: true,
  puid48: true,
};

type PuidsForEaglePlatformType = {
  puids: PuidsType | undefined;
};

/**
 * Функция, возвращающая строку или объект с пуидами для видео EaglePlatform
 * @param props - пропсы
 * @param props.puids - рекламные пуиды
 */
export const getRamblerPlayerPuids = ({
  puids,
}: PuidsForEaglePlatformType): PuidsType | undefined => {
  if (puids) {
    return _pick(puids, Object.keys(RAMBLER_PLAYER_PUIDS));
  }

  return undefined;
};
