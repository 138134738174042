import {
  EntityPropsType,
  OuterPropsType,
} from '@media-components/draft-parser';
import React, { memo } from 'react';

import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';

import defaultStyles from './defaultStyles.module.css';
import multiclusterStyles from './multiclusterStyles.module.css';

enum AutotagsDraftTypes {
  Organization = 'Organization:Name',
  Person = 'Person:Name',
  Auto = 'auto',
  Games = 'games',
  Movies = 'movies',
  Region = 'region',
  Country = 'country',
  City = 'city',
}

const DraftTypeToPageType = {
  [AutotagsDraftTypes.Organization]: 'organization',
  [AutotagsDraftTypes.Person]: 'person',
  [AutotagsDraftTypes.Auto]: 'auto',
  [AutotagsDraftTypes.Games]: 'games',
  [AutotagsDraftTypes.Movies]: 'movies',
  [AutotagsDraftTypes.Region]: 'region',
  [AutotagsDraftTypes.Country]: 'country',
  [AutotagsDraftTypes.City]: 'city',
};

export type RTagDataPropsType = {
  alias: string;
  type: AutotagsDraftTypes;
};

type RTagOuterPropsType = {
  isMulticluster?: boolean;
} & OuterPropsType;

const TAGS_WITH_SHORT_URL = [
  AutotagsDraftTypes.Region,
  AutotagsDraftTypes.Country,
  AutotagsDraftTypes.City,
];

/**
 * Компонент ссылки автотега.
 * @param props - пропсы
 * @param props.children - компонент содержимого энтити;
 * @param props.data - объект со специфичными данными для блока:
 * - alias - алиас (никнейм) автотега;
 * - isMulticluster - флаг мультикластера;
 * - type - тип автотега;
 * @param props.outerProps - внешние пропсы:
 * - type - тип автотега.
 */
const RTagComponent = function RTag({
  children,
  data,
  outerProps,
}: EntityPropsType<RTagDataPropsType, RTagOuterPropsType>) {
  const styles = outerProps.isMulticluster ? multiclusterStyles : defaultStyles;
  const top100DataType = data.type ? `_${DraftTypeToPageType[data.type]}` : '';

  const top100Attribute = useTop100AttributeWithValue(
    `click_link_contentpage${top100DataType}`,
  );

  /**
   * Если бек не отдал алиас, то и рисовать нечего
   */
  if (!data.alias) return children;

  const url = TAGS_WITH_SHORT_URL.includes(
    DraftTypeToPageType[data.type] as AutotagsDraftTypes,
  )
    ? `/${data.alias}/`
    : `/${DraftTypeToPageType[data.type]}/${data.alias}/`;

  const ariaLabel = `Перейти к новостям по теме ${children}`;

  // Если бэк передал тег, тип которого обработать мы не можем - не генерируем ссылку
  if (!DraftTypeToPageType[data.type]) {
    return <span>{children}</span>;
  }

  // href нужен для обработки случаев, когда у юзера не включен javascript
  return (
    <a
      className={styles.root}
      href={url}
      aria-label={ariaLabel}
      {...top100Attribute}
    >
      {children}
    </a>
  );
};

export const RTag = memo(
  RTagComponent,
  (prev, next) =>
    prev.data.alias === next.data.alias && prev.data.type === next.data.type,
);
