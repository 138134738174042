import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { PROJECT_ALIASES } from 'config/common/project/typings';
import { PAGE_TYPE } from 'config/common/router/typings';

import { fetchTop, fetchRecs } from './asyncs';

const similarActions = {
  pending: [fetchTop.pending.type, fetchRecs.pending.type],
  fullfilled: [fetchTop.fulfilled.type, fetchRecs.fulfilled.type],
  rejected: [fetchTop.rejected.type, fetchRecs.rejected.type],
};

type StatusTypes = 'pending' | 'fullfilled' | 'rejected';

const matchType = (type: StatusTypes) => (action: PayloadAction) =>
  similarActions[type].includes(action.type);

type HomeState = {
  clusterIds: Card['id'][];
  recommendClusterIds: Card['id'][];
  hasNextPage: boolean;
  puids: PuidsType;
} & InitialState;

const initialState: HomeState = {
  // Массив id кластеров, отображемых на странице.
  clusterIds: [],
  // Массив id рекоммендов, отображаемых на странице. Этот массив позволяет однозначно определить к какому типу относится тот или иной кластер.
  recommendClusterIds: [],
  // Флаг, что есть кластера для загрузки.
  hasNextPage: true,
  puids: {},
  fetching: false,
  error: '',
};

const homeSlice = createSlice({
  name: PAGE_TYPE.home,
  initialState,
  reducers: {
    /**
     * Генерация puids для главной страницы
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload - alias проекта, на котором запускается приложение.
     */
    setHomePuids(state, { payload }: PayloadAction<PROJECT_ALIASES>) {
      if (!payload) return;

      const puidName = getPuidName(payload);

      state.puids = {
        puid6: `${puidName}_main`.toUpperCase(),
        puid18: `${puidName}_main_0`.toUpperCase(),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchTop.fulfilled.type,
        (state, { payload }: PayloadAction<Card['id'][]>) => {
          state.fetching = false;

          /**
           * Если уже есть что-нибудь в стейте, то проверяем, пришло ли что-либо от сервера.
           * Если ничего не пришло, то помечаем, что грузить больше нечего.
           */
          state.hasNextPage =
            state.clusterIds.length === 0 || payload.length !== 0;

          state.clusterIds = [...state.clusterIds, ...payload];
        },
      )
      .addCase(
        fetchRecs.fulfilled.type,
        (state, { payload }: PayloadAction<Card['id'][]>) => {
          state.fetching = false;

          /**
           * Если уже есть что-нибудь в стейте, то проверяем, пришло ли что-либо от сервера.
           * Если ничего не пришло, то помечаем, что грузить больше нечего.
           */
          state.hasNextPage = payload.length !== 0;

          state.recommendClusterIds = [
            ...state.recommendClusterIds,
            ...payload,
          ];
        },
      )
      .addMatcher(matchType('pending'), (state) => {
        state.fetching = true;
      })
      .addMatcher(
        matchType('rejected'),
        (state, { error }: { error: Error }) => {
          state.fetching = false;
          state.error = error.message;
        },
      );
  },
});

export const homeReducer = homeSlice.reducer;
export const { setHomePuids } = homeSlice.actions;

export { fetchTop, fetchRecs };
