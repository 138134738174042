import { blockGenerator } from '@media-components/draft-parser';
import _uniqueid from 'lodash.uniqueid';
import { CSSProperties } from 'react';

import { CUSTOM_BLOCK_TYPE } from 'common/components/ClusterContentOnDraft/typings';
import { BANNERS } from 'config/common/banners/typings';

/**
 * Список обрабатываемых слотов для вставки.
 *  Слот обрабатывается не внутри непосредственной установки
 *  чтобы не загрязнять модификатор драфта jsx кодом.
 */
export enum BANNER_REPLACEMENT_SLOT {
  VideoAdIframe = 'video-ad-iframe',
}

/** Тип данных для баннера внутри статьи. */
export type BannerDataType = {
  name: BANNERS;
  isLazy?: boolean;
  onRenderStyle?: CSSProperties;
  className?: string;
  bannerReplacementSlot?: BANNER_REPLACEMENT_SLOT;
  clusterId?: Card['id'];
};

/**
 * Генератор блока с рекламой.
 * @param props - пропсы
 * @param props.name - наименование баннера
 * @param props.isLazy - флаг ленивой загрузки баннера
 * @param props.onRenderStyle - дополнительные стили для отрисовки баннера
 * @param props.className - дополнительный класс для баннера
 * @param props.bannerReplacementSlot - блок, заменяющий баннер, если последний не загрузился
 * @param props.clusterId - id кластера, для которого рисуется блок
 */
export const bannerBlockGenerator = ({
  name,
  isLazy,
  onRenderStyle,
  className,
  bannerReplacementSlot,
  clusterId,
}: BannerDataType) =>
  blockGenerator({
    key: `${CUSTOM_BLOCK_TYPE.Banner}_${name}_${_uniqueid()}`,
    type: CUSTOM_BLOCK_TYPE.Banner,
    data: {
      name,
      isLazy: isLazy || false,
      onRenderStyle,
      className,
      bannerReplacementSlot,
      clusterId,
    },
  });
