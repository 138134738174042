import { createAsyncThunk } from '@reduxjs/toolkit';

import { selectClusterIsArticle } from 'common/redux/commonData/entries/selectors';
import { addRecommends } from 'common/redux/commonData/recommendedClusters';
import { fetchRecommendedClusters } from 'common/redux/commonData/recommendedClusters/utils';
import {
  selectCustomClusterFeed,
  selectIsMobile,
} from 'common/redux/runtime/selectors';
import { RCM_BLOCK_TYPE } from 'config/common/rcm/typings';

// Длина ленты без учета основного кластера
const FEED_LENGTH_EXCLUDING_MAIN = 4;

/**
 * Функция получения данных кластеров, рекомендованных для данного кластера.
 * @param clusterId - id кластера;
 * @returns clusterFeed - массив id кластеров, рекомендованных к показу по данному кластеру.
 */
export const fetchClusterFeed = createAsyncThunk(
  'fetchClusterFeed',
  async (
    { clusterId }: { clusterId: Cluster['id'] },
    { dispatch, getState },
  ) => {
    const state = getState() as AppState;

    const isMobile = selectIsMobile(state);
    const customFeed = selectCustomClusterFeed(state);
    const isArticle = selectClusterIsArticle(clusterId)(state);

    const rcmBlockType = isMobile
      ? RCM_BLOCK_TYPE.clusterFeedMobile
      : RCM_BLOCK_TYPE.clusterFeedDesktop;

    const rcmBlockTypeGreen = isMobile
      ? RCM_BLOCK_TYPE.articleFeedMobile
      : RCM_BLOCK_TYPE.articleFeedDesktop;

    const { data, error } = await dispatch(
      // @ts-expect-error: ¯\_(ツ)_/¯
      fetchRecommendedClusters({
        rcmBlockType: isArticle ? rcmBlockTypeGreen : rcmBlockType,
        clusterId,
        limit: FEED_LENGTH_EXCLUDING_MAIN,
        itemExcludedIds: [clusterId],
      }),
    );

    if (error || !data) {
      throw (
        error ||
        new Error(
          `Ошибка при получении рекомендательных кластеров для кластера: ${clusterId}`,
        )
      );
    }

    dispatch(addRecommends({ data, rcmBlockType }));

    const recommendedClustersIds = data.recommendations.map(
      ({ itemID }) => itemID,
    );

    return [clusterId, ...customFeed, ...recommendedClustersIds];
  },
);
