import { BlockElementPropsType } from '@media-components/draft-parser';
import React, { memo } from 'react';

import s from './styles.module.css';

/**
 * Компонент заголовка h3.
 * @param props - пропсы
 * @param props.data - объект со специфичными данными для блока:
 * - id - id заголовка;
 * @param props.children - дочерние элементы компонента, если элементы такого типа идут подряд;
 * @param props.keys - дочерние ключи компонента, если элементы такого типа идут подряд.
 */
const H3Component = function H3({
  data,
  children,
  keys,
}: BlockElementPropsType<{ id?: string }, unknown>) {
  return (
    <>
      {children.map((child, index) => (
        <h3 className={s.root} key={keys[index]} id={data?.[0]?.id}>
          {child}
        </h3>
      ))}
    </>
  );
};

export const H3 = memo(H3Component);
