import { EntityPropsType } from '@media-components/draft-parser';
import React, { memo } from 'react';

import { useDraftContext } from 'common/components/ClusterContentOnDraft/context';
import { ErrorBoundary } from 'common/components/ErrorBoundary';
import { MetaMaxVideoPreview } from 'common/components/MetaMaxVideoPreview';
import { VideoPlayerBody } from 'common/components/VideoPlayer/components/VideoPlayerBody';
import { useCurrentCluster } from 'common/hooks/useCurrentCluster';
import { getRamblerPlayerPuids } from 'common/utils/searchIframe';

export type VideoPlatformDataPropsType = {
  id: string;
  width: string | undefined;
  height: string | undefined;
  filterId: string | undefined;
  playerTemplateId: string | undefined;
};

/**
 * Компонент видеовставки платформы.
 * @see https://confluence.rambler-co.ru/pages/viewpage.action?pageId=73974440
 * @param props - пропсы
 * @param props.data - объект со специфичными данными для блока:
 *  - id - id видео;
 *  - width - ширина блока с видео;
 *  - height - высота блока с видео;
 *  - filterId - (внутренний параметр) id, отвечающее за выводимые в рекомендациях плеера видео;
 *  - playerTemplateId - (внутренний параметр) id шаблона плеера (с рекомендациями, без и прочие);
 * @param props.styles - стили компонента;
 * @param props.isBroadcast - кастомный флаг, что это прямая трансляция.
 */
const VideoPlatformComponent = function VideoPlatform({
  data,
  styles,
  isBroadcast = false,
}: EntityPropsType<VideoPlatformDataPropsType, unknown> & {
  isBroadcast?: boolean;
}) {
  const { puids, pageId, pageUrl } = useDraftContext();
  const { isCurrentCluster } = useCurrentCluster(pageId);

  if (!data?.id) {
    return null;
  }

  const { id: recordId, filterId, playerTemplateId, height } = data;

  const sspJparams = getRamblerPlayerPuids({ puids });

  return (
    <div className={styles?.root}>
      <MetaMaxVideoPreview />
      <ErrorBoundary componentName="VideoPlayerBody">
        <VideoPlayerBody
          clusterUrl={pageUrl}
          recordId={recordId}
          playerTemplateId={Number(playerTemplateId)}
          multiplayerFilterId={Number(filterId)}
          height={Number(height)}
          sspJparams={sspJparams}
          isLive={isBroadcast}
          isCurrentCluster={isCurrentCluster}
        />
      </ErrorBoundary>
    </div>
  );
};

export const VideoPlatform = memo(
  VideoPlatformComponent,
  (prev, next) =>
    prev.data.id === next.data.id &&
    prev.data.playerTemplateId === next.data.playerTemplateId &&
    prev.data.filterId === next.data.filterId,
);
