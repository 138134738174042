import { cropImage } from 'common/utils/crop';
import { s3Image, s3Resize, s3Format } from 'common/utils/s3';
import { DEFAULT_IMAGE } from 'config/common/common';
import { AUTO } from 'config/common/s3';

import { IMAGE_WIDTH, IMAGE_HEIGHTS, IMAGE_CROP_STRINGS } from './constants';

/**
 * Хук для получения массива урлов картинок SchemaOrg
 * @param url - url картинки
 * @param s3 - флаг что картинка из s3
 */
export const getGetImageSrc = (url: string, s3: boolean) => {
  let images: string[];

  if (url) {
    if (s3) {
      images = IMAGE_HEIGHTS.map((cropHeight: number) =>
        s3Image(url, [
          s3Resize({ number: 1, width: IMAGE_WIDTH, height: cropHeight }),
          s3Format(2, AUTO),
        ]),
      );
    } else {
      images = IMAGE_HEIGHTS.map((cropHeight: number) =>
        cropImage(url, IMAGE_CROP_STRINGS[cropHeight]),
      );
    }
  } else {
    images = IMAGE_HEIGHTS.map((cropHeight: number) =>
      cropImage(DEFAULT_IMAGE, IMAGE_CROP_STRINGS[cropHeight]),
    );
  }

  return images;
};
