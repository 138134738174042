import { CURRENCY_SOURCES } from '../exchangeRatesWidget/typings';
import { selectWidgets } from '../selectors';

export const selectChartWidgetPeriods = (state: AppState) =>
  selectWidgets(state).chartWidgetPeriods;

export const selectChartWidgetPeriod = (state: AppState) =>
  selectChartWidgetPeriods(state).period;

export const selectChartWidgetDisabledPeriods = (state: AppState) =>
  selectChartWidgetPeriods(state).disabledPeriods;

export const selectChartWidgetDisabledPeriodsWithSource =
  (source: CURRENCY_SOURCES) => (state: AppState) =>
    selectChartWidgetDisabledPeriods(state)[source] ?? [];
