import { selectTopicById } from 'common/redux/commonData/topics/selectors';
import { StateTopicType } from 'common/redux/commonData/topics/typings';
import { isClusterData } from 'utils/adapters';

import { selectAutotagById } from '../autotags/selectors';
import { selectManualTagById } from '../manualTags/selectors';
import { selectResourceById } from '../resources/selectors';
import { selectCommonData } from '../selectors';
import { selectThemesByIds } from '../themes/selectors';

import { entriesAdapter } from './adapter';

export const selectEntries = (state: AppState) =>
  selectCommonData(state).entries;

const selectors = entriesAdapter.getSelectors(
  (state: AppState) => selectCommonData(state).entries,
);

export const selectCards = (state: AppState) => selectors.selectEntities(state);

export const selectAllEntries = (state: AppState) => selectors.selectAll(state);

export const selectCardById =
  (clusterId: Card['id'] | null) =>
  (state: AppState): Cluster | Card | undefined =>
    clusterId ? selectors.selectById(state, clusterId) : undefined;

export const selectCardsById =
  (clusterIds: Card['id'][]) =>
  (state: AppState): Card[] =>
    clusterIds.map((clusterId) => selectors.selectById(state, clusterId));

export const selectManyCluster =
  (clusterIds: Card['id'][]) => (state: AppState) =>
    clusterIds?.map((clusterId) => selectCardById(clusterId)(state)) || [];

// Селектор кластера. Если данных недостаточно, то возвращается undefined.
export const selectClusterById =
  (clusterId: Card['id'] | null | undefined) =>
  (state: AppState): Cluster | undefined => {
    const cluster = clusterId && selectors.selectById(state, clusterId);

    return cluster && isClusterData(cluster) ? cluster : undefined;
  };

export const selectClustersByIds =
  (clusterIds: Card['id'][]) =>
  (state: AppState): (Cluster | undefined)[] =>
    clusterIds.map((id) => selectClusterById(id)(state));

export const selectClusterTopics =
  (clusterId: Card['id']) => (state: AppState) =>
    selectClusterById(clusterId)(state)
      ?.topicIds.map((topicId) => selectTopicById(topicId)(state))
      ?.filter(Boolean) as StateTopicType[] | undefined;

export const selectClusterAutotags =
  (clusterId: Card['id']) => (state: AppState) =>
    selectClusterById(clusterId)(state)?.autotagIds.map((autotagId) =>
      selectAutotagById(autotagId)(state),
    );

export const selectClusterManualTags =
  (clusterId: Card['id']) => (state: AppState) =>
    (selectClusterById(clusterId)(state)
      ?.manualTagIds.map((manualTagId) =>
        selectManualTagById(manualTagId)(state),
      )
      .filter(Boolean) as ManualTagData[]) || [];

export const selectClusterExpertIds =
  (clusterId: Card['id'] | null) => (state: AppState) =>
    selectClusterById(clusterId)(state)?.expertIds;

export const selectClusterResource =
  (clusterId: Card['id'] | null) => (state: AppState) =>
    selectResourceById(selectClusterById(clusterId)(state)?.resourceId)(state);

export const selectClusterPuids =
  (clusterId: Card['id'] | null) => (state: AppState) =>
    selectClusterById(clusterId)(state)?.puids;

export const selectEntriesFetchingClusters = (state: AppState) =>
  selectEntries(state).fetchingClusters;

export const selectClusterRelated =
  (clusterId: Card['id'] | null) => (state: AppState) =>
    selectClusterById(clusterId)(state)?.related;

export const selectClusterType = (clusterId: Card['id']) => (state: AppState) =>
  selectClusterById(clusterId)(state)?.type;

export const selectClusterThemeIds =
  (clusterId: Card['id'] | null) => (state: AppState) =>
    selectClusterById(clusterId)(state)?.themeIds || [];

export const selectClusterThemes =
  (clusterId: Card['id']) => (state: AppState) =>
    selectThemesByIds(selectClusterThemeIds(clusterId)(state))(state) || [];

export const selectClusterIsArticle =
  (clusterId: Card['id'] | undefined | null) => (state: AppState) =>
    Boolean(selectClusterById(clusterId)(state)?.isArticle);

export const selectClusterIsLongread =
  (clusterId: Card['id'] | undefined | null) => (state: AppState) =>
    Boolean(selectClusterById(clusterId)(state)?.isCommercial);
