import { selectPages } from '../selectors';

export const selectAutotagTop = (state: AppState) =>
  selectPages(state).autotagTop;

export const selectAutotagTopAlias = (state: AppState) =>
  selectAutotagTop(state).alias;

export const selectAutotagTopPuids = (state: AppState) =>
  selectAutotagTop(state).puids;

export const selectAutotagTopTags = (state: AppState) =>
  selectAutotagTop(state).tags;

export const selectAutotagTopFetchingState = (state: AppState) =>
  selectAutotagTop(state).fetching;

export const selectAutotagTopErrorState = (state: AppState) =>
  selectAutotagTop(state).error;
