import cn from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';

import { RamblerLoader } from 'common/components/RamblerComponents/RamblerLoader';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';

import s from './styles.module.css';

type PortalLoaderPropsType = {
  style?: React.CSSProperties;
  className?: string;
};

/**
 * Попап с лоадером, имитация перехода на страницу
 * @param props - пропсы
 * @param props.style - дополнительные стили для лоадера;
 * @param props.className  - дополнительные классы для лоадера.
 */
const PortalLoaderComponent = function PortalLoader({
  className = '',
  style,
}: PortalLoaderPropsType) {
  return ReactDOM.createPortal(
    <div className={cn(s.popup, className)}>
      <RamblerLoader size="medium" style={style} />
    </div>,
    document.body,
  );
};

export const PortalLoader = withErrorBoundary(PortalLoaderComponent);
