import { useMemo } from 'react';

import { ScrollToSitePropsType } from '../typings';

import { useDragAndDrop } from './useDragAndDrop';

type UseSwipePropsType = {
  activeS2S: boolean;
  link: string;
  slotLabel?: ScrollToSitePropsType['slotLabel'];
  callback?: () => void;
};

const SWIPE_LIMIT = 10;

/**
 * Хук обработки свайпа по лейблу
 * @param props - пропсы
 * @param props.activeS2S - Флаг доскролла до s2s
 * @param props.link - ссылка для переходи по лейблу
 * @param props.slotLabel  - слот для вставки лейбла в s2s.
 *  Используется как флаг включения стилей для лейбла
 * @param props.callback - callback, срабатывающий перед переходом через свайп
 */
export const useSwipe = ({
  activeS2S,
  link,
  slotLabel,
  callback,
}: UseSwipePropsType) => {
  const { onMouseDown, labelRef, translate } = useDragAndDrop({
    link,
    swipeLimit: SWIPE_LIMIT,
    callback,
  });

  const s2sStyles = useMemo(() => {
    if (!slotLabel) {
      return {};
    }

    if (!activeS2S) {
      return {
        position: 'fixed',
        ...(!translate ? { transition: 'transform 0.2s' } : {}),
        top: '100vh',
        transform: `translate(0, -${translate}px)`,
      };
    }

    return {};
  }, [activeS2S, slotLabel, translate]);

  return {
    s2sStyles,
    onMouseDown,
    labelRef,
  };
};
