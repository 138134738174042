import { PayloadAction } from '@reduxjs/toolkit';

import { getBanksExchange } from 'api';
import { getApiBanksExchangeData } from 'api/adapters';
import { selectApiConfig } from 'common/redux/runtime/selectors';
import { createSlice } from 'common/redux/utils';
import { CURRENCY_CHAR_CODE } from 'config/common/finance/typings';

import { DEFAULT_REGION } from './constants';
import {
  AciveSortType,
  BankExchangeType,
  CurrencyExchangeType,
  FetchBankExchangePropsType,
  RATE_TYPE,
  SORT_DIRECTION,
  SORT_TYPE,
} from './typings';
import { sortBank, sortCurrency } from './utils';

type ExchangeWidgetType = {
  banks: BankExchangeType[];
  aciveSort: AciveSortType;
  activeTab: CurrencyExchangeType;
  region: string;
} & InitialState;

const initialState: ExchangeWidgetType = {
  // Массив банков относительно выбранного региона.
  banks: [],
  // Объект содержит тип сортировки и направление сортировки.
  aciveSort: {
    sort: SORT_TYPE.usdSell,
    direction: SORT_DIRECTION.asc,
  },
  // Тип отображаемой валюты (для мобилы).
  activeTab: CURRENCY_CHAR_CODE.USD,
  region: DEFAULT_REGION,
  fetching: false,
  error: '',
};

/**
 * Слайс виджета курса обмена доллара и евро.
 */
const exchangeSlice = createSlice({
  name: 'exchange',
  initialState,
  reducers: (create) => ({
    fetchBankExchange: create.asyncThunk(
      async (
        { bankRegion, activeSort }: FetchBankExchangePropsType,
        { getState },
      ) => {
        const apiConfig = selectApiConfig(getState() as AppState);

        const { data, error } = await getBanksExchange(
          apiConfig,
          bankRegion,
          'usd_buy',
        );

        if (error || !data) {
          throw (
            error ||
            new Error(`Ошибка при получении банков по региону ${bankRegion}`)
          );
        }

        const banks = getApiBanksExchangeData(data, [
          CURRENCY_CHAR_CODE.USD,
          CURRENCY_CHAR_CODE.EUR,
        ]);

        if (activeSort?.sort === SORT_TYPE.bank) {
          return banks.sort(sortBank(activeSort.direction));
        }

        if (activeSort) {
          const { sort, direction } = activeSort;

          const currency = sort.toLocaleLowerCase().includes('usd')
            ? CURRENCY_CHAR_CODE.USD
            : CURRENCY_CHAR_CODE.EUR;

          const rate = sort.toLocaleLowerCase().includes('buy')
            ? RATE_TYPE.buyRate
            : RATE_TYPE.sellRate;

          return banks.sort(
            sortCurrency({
              currency,
              rate,
              direction,
            }),
          );
        }

        return banks.sort(
          sortCurrency({
            currency: CURRENCY_CHAR_CODE.USD,
            rate: RATE_TYPE.sellRate,
            direction: SORT_DIRECTION.asc,
          }),
        );
      },
      {
        pending: (state, { meta }) => {
          state.fetching = true;

          if (meta?.arg?.bankRegion) {
            state.region = meta.arg.bankRegion;
          }
        },
        fulfilled: (state, { payload }) => {
          state.banks = payload;
          state.error = '';
        },
        rejected: (state, { error }) => {
          state.error = error.message;
        },
        settled: (state) => {
          state.fetching = false;
        },
      },
    ),

    /**
     * Метод обновления массива банков
     * @param action.payload - массив кластеров или данных карточек.
     */
    setBanks: create.reducer(
      (state, { payload }: PayloadAction<BankExchangeType[]>) => {
        state.banks = payload;
      },
    ),

    /**
     * Метод обновления объекта текущей сортировки
     * @param action.payload - массив кластеров или данных карточек.
     */
    setActiveSort: create.reducer(
      (state, { payload }: PayloadAction<AciveSortType>) => {
        state.aciveSort = payload;
      },
    ),

    /**
     * Метод обновления активной валюты (мобила)
     * @param action.payload - массив кластеров или данных карточек.
     */
    setActiveTab: create.reducer(
      (state, { payload }: PayloadAction<CurrencyExchangeType>) => {
        state.activeTab = payload;
      },
    ),

    /**
     * Метод установки региона.
     * @param action.payload  - устанавливаемый регион.
     */
    setRegion: create.reducer((state, { payload }: PayloadAction<string>) => {
      state.region = payload;
    }),
  }),
  selectors: {
    selectWidgetsExchangeBanks: (state) => state.banks,
    selectWidgetsExchangeAciveSort: (state) => state.aciveSort,
    selectWidgetsExchangeActiveTab: (state) => state.activeTab,
    selectWidgetsExchangeRegion: (state) => state.region,
  },
});

export const exchangeReducer = exchangeSlice.reducer;

export const {
  fetchBankExchange,
  setBanks,
  setActiveSort,
  setActiveTab,
  setRegion,
} = exchangeSlice.actions;

export const {
  selectWidgetsExchangeBanks,
  selectWidgetsExchangeAciveSort,
  selectWidgetsExchangeActiveTab,
  selectWidgetsExchangeRegion,
} = exchangeSlice.selectors;
