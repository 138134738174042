import React from 'react';
import { ImageObject } from 'schema-dts';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { JsonLd } from 'utils/jsond';

import { getItemForImageObject } from '../utils';

type SlideImageObjectPropsType = {
  imgUrls: string[];
  name: string | undefined;
  description: string | undefined;
  author: string;
  isMain: boolean;
};

/**
 * Получение ImageObject schema.org для слайда галереи
 * @param props - пропсы
 * @param props.imgUrls - url изображения;
 * @param props.name - длиный заголовок кластера;
 * @param props.description - описание слайда или длинный заголовок кластера;
 * @param props.author - название источника.
 * @returns блок schema.org описывающий ImageObject
 */
const SlideImageObjectComponent = function SlideImageObject({
  imgUrls,
  name,
  description,
  author,
}: SlideImageObjectPropsType) {
  const item = getItemForImageObject({
    imgUrls,
    name,
    description,
    imageSource: author,
  });

  return <JsonLd<ImageObject> item={item} />;
};

export const SlideImageObject = withErrorBoundary(SlideImageObjectComponent);
