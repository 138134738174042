import { getTravelCities, getTravelRegion } from 'api';
import { selectApiConfig } from 'common/redux/runtime/selectors';
import { createSlice } from 'common/redux/utils';

import { updateCountryCities } from '../travelCountries';

import { travelCitiesAdapter } from './adapter';

const LIMIT = 1000;

const INITIAL_STATE = travelCitiesAdapter.getInitialState({
  fetching: false,
  error: '',
});

/**
 * Слайс с городами для страниц путешествий.
 */
const travelCitiesSlice = createSlice({
  name: 'travelCities',
  initialState: INITIAL_STATE,
  reducers: (create) => ({
    fetchTravelCities: create.asyncThunk(
      async (
        {
          regionAlias,
          callback,
        }: {
          regionAlias: string;
          callback?: (cities: TravelRegionType[]) => void;
        },
        { getState, dispatch },
      ) => {
        const apiConfig = selectApiConfig(getState() as AppState);

        const { data, error } = await getTravelCities(apiConfig, {
          limit: LIMIT,
          regionAlias,
        });

        if (error || !data) {
          throw (
            error || new Error('Ошибка при получении списка городов региона')
          );
        }

        const travelWidgetCities = data.map(
          ({ short, alias }) => [short, alias] as [string, string],
        );

        if (callback) {
          callback(data);
        }

        dispatch(
          updateCountryCities({
            alias: regionAlias,
            cities: travelWidgetCities,
          } as TravelRegionType),
        );

        return { data };
      },

      {
        pending: (state) => {
          state.fetching = true;
          state.error = '';
        },
        fulfilled: (state, { payload }) => {
          travelCitiesAdapter.upsertMany(state, payload.data);
          state.error = '';
        },
        rejected: (state, { error }) => {
          state.error = error.message || '';
        },
        settled: (state) => {
          state.fetching = false;
        },
      },
    ),

    fetchTravelCityInfo: create.asyncThunk(
      async (
        {
          regionAlias,
        }: {
          regionAlias: string;
        },
        { getState },
      ) => {
        const apiConfig = selectApiConfig(getState() as AppState);

        const { data, error } = await getTravelRegion(apiConfig, regionAlias);

        if (error || !data) {
          throw (
            error ||
            new Error(
              `Ошибка при получении информации о городе: ${regionAlias}`,
            )
          );
        }

        return { data };
      },

      {
        pending: (state) => {
          state.fetching = true;
          state.error = '';
        },
        fulfilled: (state, { payload }) => {
          travelCitiesAdapter.upsertOne(state, payload.data);
          state.error = '';
        },
        rejected: (state, { error }) => {
          state.error = error.message || '';
        },
        settled: (state) => {
          state.fetching = false;
        },
      },
    ),
  }),
});

export const { fetchTravelCities, fetchTravelCityInfo } =
  travelCitiesSlice.actions;

export const travelCitiesReducer = travelCitiesSlice.reducer;
