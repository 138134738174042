import { selectClusterById } from '../entries/selectors';
import { selectCommonData } from '../selectors';

import { itemsAdapter } from './adapter';

const selectors = itemsAdapter.getSelectors(
  (state: AppState) => selectCommonData(state).items,
);

export const selectItems = (state: AppState) => selectCommonData(state).items;

export const selectItemsById =
  (itemId: ClusterResourceType['id']) => (state: AppState) =>
    selectors.selectById(state, itemId);

export const selectItemsIds = (clusterId: Cluster['id']) => (state: AppState) =>
  selectClusterById(clusterId)(state)?.itemIds;

export const selectItemsLoadingState = (state: AppState) =>
  selectItems(state).fetching;
