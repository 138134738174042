import React from 'react';
import { VideoObject } from 'schema-dts';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { JsonLd } from 'utils/jsond';

import { getItemForVideoObject } from './hooks';

type VideoObjectSchemaPropsType = {
  cluster: Cluster;
};

/**
 * Получение VideoObject для schema.org
 * @param props - пропсы
 * @param props.cluster - инфо о кластере.
 */
const VideoObjectSchemaComponent = function VideoObjectSchema({
  cluster,
}: VideoObjectSchemaPropsType) {
  const { publicationTime } = cluster;

  const item = getItemForVideoObject(cluster);

  if (!!publicationTime && new Date(publicationTime) > new Date()) return null;

  return <JsonLd<VideoObject> item={item} />;
};

export const VideoObjectSchema = withErrorBoundary(VideoObjectSchemaComponent);
