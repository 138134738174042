import { useRef, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { UserProfileType } from 'common/components/RamblerComponents/RamblerHeader';
import { selectIsMobile, selectRuntime } from 'common/redux/runtime/selectors';
import { ymParams } from 'utils/counters/yaMetrika';

/**
 * Отправка в ЯМетрику авторизован пользователь или нет.
 * @param userFetched - флаг, что юзер загружен;
 * @param userProfile - данные юзера.
 */
export const useAuthAnalytics = (
  userFetched: boolean,
  userProfile: UserProfileType | null,
) => {
  const isSent = useRef(false);

  const isMobile = useSelector(selectIsMobile);
  const runtime = useSelector(selectRuntime, shallowEqual);

  useEffect(() => {
    if (!isSent.current && userFetched) {
      isSent.current = true;

      const params = {
        authorization: {
          [isMobile ? 'mob' : 'web']: userProfile
            ? 'ID_authorized'
            : 'ID_unauthorized',
        },
      };

      ymParams({ params, runtime });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFetched, runtime]);
};
