import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { PAGE_TYPE } from 'config/common/router/typings';

import { fetchClusterItemsCluster, fetchClusterItemsClusters } from './asyncs';

type StateClusterItemsType = {
  puids: PuidsType;
  cluster: Card;
  clustersIds: Card['id'][];
  currentPage: number;
  hasNextPage: boolean;
} & InitialState;

const clusterItemsSlice = createSlice({
  name: PAGE_TYPE.clusterItems,
  initialState: {
    puids: {},
    cluster: {} as Card,
    clustersIds: [],
    currentPage: 1,
    hasNextPage: false,
    fetching: false,
  } as StateClusterItemsType,
  reducers: {
    /**
     * Генерация puids для страницы.
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload.projectAlias - alias вертикали
     * @param action.payload.topicAlias - alias рубрики
     */
    setClusterItemsPuids: (
      state,
      action: PayloadAction<{
        projectAlias: ProjectType['alias'];
        topicAlias: TopicType['alias'];
      }>,
    ) => {
      const { projectAlias, topicAlias } = action.payload;

      const puidName = getPuidName(projectAlias);
      const formattedTopicAlias = topicAlias && topicAlias.replace(/-|_/gi, '');

      state.puids = {
        puid6: `${puidName}_${formattedTopicAlias}`.toUpperCase(),
        puid15: 'items',
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClusterItemsCluster.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchClusterItemsCluster.fulfilled, (state, { payload }) => {
        const { cluster } = payload;

        state.cluster = cluster;
        state.fetching = false;
      })
      .addCase(fetchClusterItemsCluster.rejected, (state, error) => {
        state.fetching = false;
        state.error = error.error.message;
      });
    builder
      .addCase(fetchClusterItemsClusters.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchClusterItemsClusters.fulfilled, (state, { payload }) => {
        state.clustersIds = [...state.clustersIds, ...payload];
        state.fetching = false;
      })
      .addCase(fetchClusterItemsClusters.rejected, (state, error) => {
        state.fetching = false;
        state.error = error.error.message;
      });
  },
});

export const clusterItemsReducer = clusterItemsSlice.reducer;

export const { setClusterItemsPuids } = clusterItemsSlice.actions;
