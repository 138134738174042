import React, { useState, useCallback, memo } from 'react';
import { useSelector } from 'react-redux';

import { useObserver } from 'common/hooks/useObserver';
import { selectProjectId } from 'common/redux/runtime/selectors';
import { RCMpageRead } from 'utils/counters/atdRecommender/client';

type ClusterWasReadPropsType = {
  clusterId: Card['id'];
};

// Событие прочтения кластера
const intersectionCallbackNew = (
  observer: IntersectionObserver,
  _projectId: ProjectType['id'],
  clusterId: Card['id'],
) => {
  observer.disconnect();
  // Отправка события об прочтении кластера в топ100
  RCMpageRead(clusterId);
};

/**
 * Наблюдатель прочтения текста кластера, событие отправляется в топ100 в конце кластера
 * @param props - пропсы
 * @param props.clusterId - id кластера прочтения;
 */
const ClusterWasReadComponent = function ClusterWasRead({
  clusterId,
}: ClusterWasReadPropsType) {
  const projectId = useSelector(selectProjectId);

  const [messageRead, setMessageRead] = useState(false);

  const callback = useCallback(
    (
      _entrie: IntersectionObserverEntry,
      observerLink: IntersectionObserver,
    ) => {
      intersectionCallbackNew(observerLink, projectId, clusterId);
      setMessageRead(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clusterId],
  );

  const intersectionRef = useObserver<HTMLDivElement>({ callback });

  return messageRead ? null : <div ref={intersectionRef} />;
};

export const ClusterWasRead = memo(ClusterWasReadComponent);
