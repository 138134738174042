import React, { memo } from 'react';

import { BorderedQuote } from 'common/components/BorderedQuote';

import { ExpertInfo } from '../ExpertInfo';

type ExpertBasePropsType = {
  expertId: string | number;
  children: React.ReactNode;
};

/**
 * Базовый компонент верстки цитаты эксперта.
 * @param props - пропсы
 * @param props.expertId - id эксперта, для которого рисуется цитата;
 * @param props.children - дочерний элемент цитаты.
 */
const ExpertBaseComponent = function ExpertBase({
  expertId,
  children,
}: ExpertBasePropsType) {
  return (
    <BorderedQuote>
      {children}
      {expertId && <ExpertInfo expertId={Number(expertId)} />}
    </BorderedQuote>
  );
};

export const ExpertBase = memo(ExpertBaseComponent);
