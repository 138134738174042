import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { PROJECT_ALIASES } from 'config/common/project/typings';
import { PAGE_TYPE } from 'config/common/router/typings';

import { fetchTagClusters, fetchTagInfo } from './asyncs';

type StateTagType = {
  puids: PuidsType;
  info: {
    alias: string;
    text: string;
    title: string;
  };
  clustersIds: Card['id'][];
  currentPage: number;
  hasNextPage: boolean;
} & InitialState;

const tagSlice = createSlice({
  name: PAGE_TYPE.tag,
  initialState: {
    puids: {},
    info: {} as StateTagType['info'],
    clustersIds: [],
    currentPage: 1,
    hasNextPage: false,
    fetching: false,
    error: '',
  } as StateTagType,
  reducers: {
    /**
     * Генерация puids для страницы.
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload - alias проекта, на котором запускается приложение.
     */
    setTagPuids: (state, { payload }: PayloadAction<PROJECT_ALIASES>) => {
      const puidName = getPuidName(payload);

      state.puids = {
        puid6: `${puidName}_feed`.toUpperCase(),
        puid18: `${puidName}_feed_tag`.toUpperCase(),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTagInfo.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchTagInfo.fulfilled, (state, { payload }) => {
        const { text } = payload;

        state.info = {
          ...payload,
          title: text ? `${text} \u2014 все новости` : 'Последние новости',
        };
        state.fetching = false;
      })
      .addCase(fetchTagInfo.rejected, (state, error) => {
        state.fetching = false;
        state.error = error.error.message;
      });
    builder
      .addCase(fetchTagClusters.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchTagClusters.fulfilled, (state, { payload }) => {
        const { clusterIds, currentPage, hasNextPage } = payload;

        state.clustersIds = [...state.clustersIds, ...clusterIds];
        state.currentPage = currentPage;
        state.hasNextPage = hasNextPage;
        state.fetching = false;
      })
      .addCase(fetchTagClusters.rejected, (state, error) => {
        state.fetching = false;
        state.error = error.error.message;
      });
  },
});

export const tagReducer = tagSlice.reducer;

export const { setTagPuids } = tagSlice.actions;
