import { createAsyncThunk } from '@reduxjs/toolkit';

import { getTopsByProject } from 'api';
import { fetchCommentsForEntries } from 'common/redux/asyncs';
import { upsertEntries } from 'common/redux/commonData/entries';
import { selectCardById } from 'common/redux/commonData/entries/selectors';
import { addRecommends } from 'common/redux/commonData/recommendedClusters';
import { selectRecommendedClustersSessionID } from 'common/redux/commonData/recommendedClusters/selectors';
import { fetchRecommendedClustersWrapper } from 'common/redux/commonData/recommendedClusters/utils';
import {
  selectApiConfig,
  selectIsMobile,
  selectVariables,
  selectProjectId,
  selectDomainConfig,
} from 'common/redux/runtime/selectors';
import { FetchRecsType } from 'common/redux/typings';
import { RCM_BLOCK_TYPE } from 'config/common/rcm/typings';
import { refetchFailedRequest } from 'server/collectors/prometheus/utils/metricsBatch/utils';
import { adaptClustersToCards, adaptClusterToCardRcm } from 'utils/adapters';
import { sentryClientSend } from 'utils/sentry/sentry.client';

import { DUPLICATE_ERROR } from '../constants';

import { selectHomePageClusterIds } from './selectors';
import { FetchRecs } from './typings';

interface FetchTop {
  // Данные о топе.
  topID: TopType;
  // Количество загружаемых новостей.
  length: 10 | 15 | 20 | 30 | 40 | 50;
  // Обрезание новостей до определенного количества.
  limitBy: number;
}

/**
 * Функция получения данных о топе.
 * @see FetchTop
 */
export const fetchTop = createAsyncThunk(
  'fetchTop',
  async ({ topID, length, limitBy }: FetchTop, { dispatch, getState }) => {
    const state = getState() as AppState;

    const apiConfig = selectApiConfig(state);
    const domainConfig = selectDomainConfig(state);
    const variables = selectVariables(state);
    const projectId = selectProjectId(state);

    const { data, error } = await refetchFailedRequest(() =>
      getTopsByProject(apiConfig, projectId, topID, length),
    );

    if (error || !Array.isArray(data)) {
      throw (
        error ||
        new Error(`Ошибка при получении кластеров вертикали: ${projectId}`)
      );
    }

    const cards = adaptClustersToCards({
      clusters: data.slice(0, limitBy),
      domainConfig,
      variables,
    });

    dispatch(upsertEntries(cards));

    return cards.map(({ id }) => id);
  },
);

/**
 * Функция получения данных рекоммендов о топе.
 * Этот экшн ВЛИЯЕТ на стейт страницы вертикали.
 * Как только новости рекомендов загрузились, они сразу же добавляются в список.
 * @see FetchRecs
 */
export const fetchRecs = createAsyncThunk(
  'fetchRecs',
  async (
    { length, excludedClustersIds = [] }: FetchRecs,
    { dispatch, getState },
  ) => {
    const state = getState() as AppState;

    const isMobile = selectIsMobile(state);
    const rcmBlockType = isMobile
      ? RCM_BLOCK_TYPE.homeMobile
      : RCM_BLOCK_TYPE.homeDesktop;
    // Вырезаем все элементы из текущего топа
    const excludedItems = selectHomePageClusterIds(state);
    const sessionID = selectRecommendedClustersSessionID(rcmBlockType)(state);
    const variables = selectVariables(state);
    const domainConfig = selectDomainConfig(state);
    const projectId = selectProjectId(state);

    const existingClustersIds = [...excludedClustersIds, ...excludedItems];

    const { data, error } = await dispatch(
      // @ts-expect-error: ¯\_(ツ)_/¯
      fetchRecommendedClustersWrapper({
        rcmBlockType,
        recCount: length,
        sessionID,
        clientTimeout: 12000,
        excludedItems: [...excludedClustersIds, ...excludedItems],
      }),
    );

    if (error || !data) {
      throw (
        error ||
        new Error('Ошибка при получении рекомендательных кластеров вертикали')
      );
    }

    dispatch(addRecommends({ data, rcmBlockType }));

    /**
     * Проверяем наличие дубликатов кластеров из топа среди рекомендаций
     */
    const duplicates = data.recommendations.filter(({ itemID }) =>
      excludedItems.includes(itemID),
    );

    if (duplicates.length) {
      const duplicatesError = new Error(DUPLICATE_ERROR);
      const incomingClustersIds = [
        ...data.recommendations.map(({ itemID }) => itemID),
      ];

      sentryClientSend({
        error: duplicatesError,
        level: 'info',
        extra: {
          existingClustersIds: existingClustersIds.toString(),
          incomingClustersIds: incomingClustersIds.toString(),
        },
      });
    }

    const cards = data.recommendations.map((cluster) =>
      adaptClusterToCardRcm({
        cluster,
        projectId,
        domainConfig,
        variables,
        commentsCount: 0,
      }),
    );

    dispatch(upsertEntries(cards));

    return cards.map(({ id }) => id);
  },
);

interface FetchRecsСomments {
  // id кластеров, для которых получаются комментарии.
  cardIds: Card['id'][];
}

/**
 * Функция получения данных комментариев для указанных кластеров.
 * Этот экшн НЕ ВЛИЯЕТ на стейт страницы вертикали.
 * Никто не должен ждать загрузки комментариев для уже загруженных новостей.
 * @see FetchRecСomments
 */
const fetchRecsComments = createAsyncThunk(
  'fetchRecsComments',
  async ({ cardIds }: FetchRecsСomments, { dispatch, getState }) => {
    await dispatch(fetchCommentsForEntries({ cardIds }));

    const state = getState() as AppState;

    const cards = cardIds
      .map((id) => selectCardById(id)(state))
      .filter(Boolean);

    dispatch(upsertEntries(cards as (Card | Cluster)[]));
  },
);

/**
 * Функция получения данных рекоммендов о топе.
 * Совмещает в себе загрузку новостей из рекомендаций с последующим размещением их в топе
 * и дозагрузку остальных новостей.
 * @see FetchRecs
 */
export const fetchFullRecsData = createAsyncThunk(
  'fetchFullRecsData',
  async (props: FetchRecsType, { dispatch }) => {
    const cardIds = (await dispatch(fetchRecs(props as FetchRecs)))
      .payload as unknown[];

    if (cardIds?.length) {
      dispatch(fetchRecsComments({ cardIds: cardIds as Card['id'][] }));
    }
  },
);
