import { EntityPropsType } from '@media-components/draft-parser';
import React, { memo, useRef } from 'react';

import s from './styles.module.css';

type RamblerQuizDataPropsType = {
  color: string;
  id: string;
  theme: string;
  type: string;
};

/**
 * Используем принудительное выставление общей цветовой темы.
 * Иногда в data.color может приходить старый цвет женского, нам необходимо его игнорировать.
 *
 * Если нужно будет добавить поддержку большего колва цветов, то читайте переменную
 *  data.color и игнорируйте женский цвет.
 */
const NEWS_COLOR = '#315efb';

/**
 * Формирование эмбеда Рамблер/Опросов
 * @param props - параметры компонента;
 * @param props.data.color - цвет шрифта квиза;
 * @param props.data.theme - цветная тема компонента квиза;
 * @param props.data.type - тип квиза.
 */
export const RamblerQuiz = memo(
  ({ data }: EntityPropsType<RamblerQuizDataPropsType>) => {
    const ref = useRef<HTMLDivElement | null>(null);

    if (!data.id) return null;

    return (
      <div ref={ref} className={!ref?.current ? s.placeholder : ''}>
        <div
          data-quiz-id={data.id}
          data-quiz-widget={data.type}
          data-quiz-color={NEWS_COLOR}
          data-quiz-theme={data.theme}
          className={s.root}
        />

        <script src="https://quiz.rambler.ru/widget/sdk.js" async />
      </div>
    );
  },
);
