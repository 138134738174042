import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { EditorInfo } from 'common/components/SourceBlock/components/EditorInfo';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { selectClusterEditor } from 'common/redux/commonData/editors/selectors';
import {
  selectClusterById,
  selectClusterResource,
} from 'common/redux/commonData/entries/selectors';
import {
  selectProjectAlias,
  selectDomainConfig,
} from 'common/redux/runtime/selectors';
import { selectTopicByClusterId } from 'common/redux/selectors';
import { RAMBLER_RESOURCES_IDS } from 'config/common/ramblerResourcesIds';
import { generateClusterUrl } from 'utils/urlGenerator';

import { SourceInfo } from './SourceInfo';

type ClusterSourcesPropsType = {
  clusterId: Cluster['id'] | null;
  customStyles?: StylesType;
};

const DEFAULT_STYLE = {} as StylesType;

/**
 * Блок с информацией об авторе или источнике
 * @param props - пропсы
 * @param props.clusterId - id кластера;
 * @param props.customStyles - кастомные стили.
 */
const ClusterSourcesComponent = function ClusterSources({
  clusterId,
  customStyles = DEFAULT_STYLE,
}: ClusterSourcesPropsType) {
  const domainConfig = useSelector(selectDomainConfig, shallowEqual);
  const cluster = useSelector(selectClusterById(clusterId), shallowEqual);
  const topic = useSelector(selectTopicByClusterId(clusterId), shallowEqual);
  const alias = useSelector(selectProjectAlias);
  const resource = useSelector(selectClusterResource(clusterId), shallowEqual);
  const editor = useSelector(selectClusterEditor(clusterId), shallowEqual);

  const isRamblerResource =
    typeof resource.id === 'number' &&
    RAMBLER_RESOURCES_IDS.includes(resource.id);
  const utm = isRamblerResource
    ? `utm_source=m${alias}_media&utm_medium=source&utm_campaign=self_promo&utm_content=m${alias}_media`
    : `utm_medium=source&utm_source=r${alias}`;

  const urlMoreLink = `${generateClusterUrl({
    clusterId,
    normalizedTitle: cluster?.normalizedTitle || '',
    domainConfig,
    topic,
    addDomain: false,
  })}items/`;

  const moreSourceCount = cluster?.resourcesCount
    ? cluster.resourcesCount - 1
    : 0;

  return (
    <div>
      {editor && isRamblerResource && (
        <EditorInfo
          utm={utm}
          editor={editor}
          withIcon={false}
          customStyles={customStyles}
        />
      )}
      {/* Чтобы нарисовать источник кластер должен либо не иметь редактора (который приоритетный), либо не быть платным. В последнем случае кластер не принадлежит редактору. */}
      {resource && (!editor || cluster?.isCommercial || cluster?.isPaid) && (
        <SourceInfo
          utm={utm}
          resource={resource}
          urlMoreLink={urlMoreLink}
          moreSourceCount={moreSourceCount}
          styles={customStyles}
        />
      )}
    </div>
  );
};

export const ClusterSources = withErrorBoundary(ClusterSourcesComponent);
