export enum LinkType {
  default,
  reactRouter,
}

/**
 * Функция проверки домена в ссылке на переход внутри проекта
 * @param domainConfig - объект данных о домене
 * @param link - ссылка для проверки
 */
export const checkDomain = (
  domainConfig: DomainConfigType,
  link: string,
): LinkType => {
  const { prefix, projectAlias, postfix, protocol, port } = domainConfig;

  const urlPart = `${protocol}://${prefix}${projectAlias}${postfix}`;
  const portPart = port ? `:${port}` : '';
  const pattern = `${urlPart}${portPart}`;

  const regExp = new RegExp(`^${pattern}\\.*`);
  const result = link.match(regExp);

  if (result) {
    return LinkType.reactRouter;
  }

  return LinkType.default;
};

/**
 * Вырезает домен из ссылки
 * @param domainConfig - объект данных о домене
 * @param link - ссылка для проверки
 */
export const replaceDomain = (domainConfig: DomainConfigType, link: string) => {
  const { prefix, projectAlias, postfix, protocol, port } = domainConfig;

  if (!link) {
    return '/';
  }

  const urlPart = `${protocol}://${prefix}${projectAlias}${postfix}`;
  const portPart = port ? `:${port}` : '';
  const pattern = `${urlPart}${portPart}`;

  const regExp = new RegExp(`^${pattern}`);

  const result = link.replace(regExp, '');

  if (!result) {
    return '/';
  }

  return result;
};
