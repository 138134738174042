import cn from 'classnames';
import React, { useEffect, useRef } from 'react';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { selectIsHumanCenteredStrategyStateActivated } from 'common/redux/runtime/selectors';
import { useAppSelector } from 'store/hooks';

import { useLoadRamblerPlayer } from '../../hooks/useLoadRamblerPlayer';

type VideoPlayerBodyPropsType = {
  clusterUrl: string;
  recordId: number | string;
  playerTemplateId: number | undefined;
  sspJparams: PuidsType | undefined;
  multiplayerFilterId: number | undefined;
  // Неизвестно, что это за параметр и для чего он
  previewComment?: unknown;
  isLive: boolean;
  isCurrentCluster: boolean;
  height?: number;
  styles?: StylesType;
};

/**
 * Тело видеоплеера. Принимает обобщенные данные, чтобы плеер можно было запускать в разных окружениях.
 * @param props - пропсы
 * @param props.clusterUrl - url кластера, внутри которого рисуется плеер;
 * @param props.recordId - id видеозаписи;
 * @param props.playerTemplateId - id шаблона для отображения плеера (например, с рекоммендациями или нет);
 * @param props.sspJparams - рекламные настройки для плеера;
 * @param props.multiplayerFilterId - id шаблона для отображения рекоммендаций и их поведения;
 * @param props.previewComment - неизвестный параметр;
 * @param props.isLive - флаг, что это видеотрансляция;
 * @param props.height - фиксированная высота видео;
 * @param props.isCurrentCluster - флаг, что видео расположено в первом кластере;
 * @param props.styles - дополнительные стили.
 */
const VideoPlayerBodyComponent = function VideoPlayerBody({
  clusterUrl,
  recordId,
  playerTemplateId,
  sspJparams,
  multiplayerFilterId,
  previewComment,
  isLive,
  height,
  isCurrentCluster,
  styles,
}: VideoPlayerBodyPropsType) {
  const playerRef = useRef<HTMLDivElement | null>(null);
  const forceRedesign = useAppSelector(
    selectIsHumanCenteredStrategyStateActivated,
  );

  useLoadRamblerPlayer({
    clusterUrl,
    recordId,
    playerRef,
    playerTemplateId,
    sspJparams,
    multiplayerFilterId,
    isLive,
    height,
    shouldDrawPlayer: Boolean(isCurrentCluster && recordId),
  });

  const className = styles
    ? cn(
        styles.player,
        forceRedesign && styles.playerRedesign,
        { [styles['player--loading']]: !playerRef.current },
        { [styles.multiplayer]: multiplayerFilterId && styles.multiplayer },
      )
    : '';

  const previewCommentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (previewCommentRef.current && previewComment) {
      previewCommentRef.current.outerHTML = previewComment as string;
    }
  }, [previewComment]);

  if (!recordId) return null;

  return (
    <>
      <div ref={playerRef} className={className} />
      <div ref={previewCommentRef} />
    </>
  );
};

export const VideoPlayerBody = withErrorBoundary(VideoPlayerBodyComponent);
