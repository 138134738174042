import { shallowEqual, useSelector } from 'react-redux';

import {
  selectClusterAutotags,
  selectClusterManualTags,
  selectClusterTopics,
  selectClusterThemeIds,
} from 'common/redux/commonData/entries/selectors';
import { selectThemesByIds } from 'common/redux/commonData/themes/selectors';
import { selectProjectAlias } from 'common/redux/runtime/selectors';
import { FILTER_AUTOTAG_TYPE } from 'config/common/autotag/constants';
import { AUTOTAG_TYPE } from 'config/common/autotag/typings';
import { PROJECT_ALIASES } from 'config/common/project/typings';
import { useAppSelector } from 'store/hooks';

import { getTagsByPriority } from './getTagsByPriority';

/**
 * Хук для получения отфильтрованного списка тегов из стейта редизайна.
 * @param clusterId - id кластера, для которого получаем теги.
 * @returns отфильтрованный массив тегов.
 */
export const useTagList = (clusterId: Card['id']) => {
  const projectAlias = useSelector(selectProjectAlias);
  const autotags = useSelector(selectClusterAutotags(clusterId), shallowEqual);
  const manualTags = useSelector(
    selectClusterManualTags(clusterId),
    shallowEqual,
  );
  const clusterTopics = useSelector(
    selectClusterTopics(clusterId),
    shallowEqual,
  );
  const clusterThemeIds = useSelector(
    selectClusterThemeIds(clusterId),
    shallowEqual,
  );
  const clusterThemes =
    useAppSelector(
      (state) => selectThemesByIds(clusterThemeIds)(state),
      shallowEqual,
    ) || [];

  if (!manualTags?.length && !autotags?.length) return [];

  const formattedClusterTopics =
    clusterTopics?.map((topic) => ({
      ...topic,
      type: 'topic',
    })) || [];

  const filteredAutotags =
    PROJECT_ALIASES.News !== projectAlias
      ? autotags?.filter((tag) => !FILTER_AUTOTAG_TYPE[tag.autotagType])
      : autotags;

  const formattedAutotags =
    filteredAutotags?.map((autotag) => ({
      id: autotag.id,
      alias: autotag.alias,
      type: autotag.autotagType,
      name: autotag.displayName,
    })) || [];

  const formattedManualTags = manualTags.map((manualTag) => ({
    id: manualTag.id,
    alias: manualTag.alias,
    type: 'tag',
    name: manualTag.title,
  }));

  const sortedTags = getTagsByPriority(
    [...formattedAutotags, ...formattedManualTags],
    projectAlias,
  ).filter((tag) => tag.type !== AUTOTAG_TYPE.category);

  return [
    ...formattedClusterTopics,
    ...sortedTags,
    ...clusterThemes,
  ] as MarkerType[];
};
