import _uniqueid from 'lodash.uniqueid';

/** Тип данных для изображения  */
type ImageEntityGeneratorPropsType = {
  src: string;
};

/**
 * Блок изображения.
 * @param props - пропсы
 * @param props.src - ссылка на изображение.
 */
export const imageEntityGenerator = ({
  src,
}: ImageEntityGeneratorPropsType) => ({
  data: {
    alt: null,
    description: null,
    sourceName: null,
    sourceUrl: null,
    src,
  },
  mutability: 'MUTABLE',
  type: 'IMAGE',
});
