import { ENTITY_TYPES } from '@media-components/draft-parser';
import { RawDraftEntity } from 'draft-js';

import {
  EntityModifierGeneratorType,
  LegacyDataPropsType,
} from 'common/components/ClusterContentOnDraft/typings';

import { imageEntityGenerator } from '../../generators/imageEntityGenerator';

/**
 * Вырезатель легаси-изображений, превращающий их в нормальные изображения.
 */
export const updateImageFromLegacy: EntityModifierGeneratorType<{}> =
  () => (entityMap) => {
    return Object.fromEntries(
      Object.entries(entityMap).map(([key, entity]) => {
        if (entity.type !== ENTITY_TYPES.Legacy) {
          return [key, entity];
        }

        const html = (entity as RawDraftEntity<LegacyDataPropsType>)?.data?.data
          ?.embed?.htmlCode;

        if (!html?.includes('<img')) {
          return [key, entity];
        }

        const src = html.match(/src="(.*?)"/)?.[1];

        if (!src) {
          return [key, entity];
        }

        return [
          key,
          imageEntityGenerator({
            src,
          }),
        ];
      }),
    );
  };
