import { EntityPropsType } from '@media-components/draft-parser';
import cn from 'classnames';
import React, { memo } from 'react';

import { getHrefWithoutTransition } from 'common/utils/clusterContent/getHrefWithoutTransition';

/**
 * Тип ссылки "Читайте по теме", ведущей на другой кластер.
 * Когда бэк вынесет этот тип в другое энтити - удалить.
 */
export const RELINK_TYPE = 'R_RELINK';

/** Данны, которые получает компонент ссылки */
type LinkDataPropsType = {
  href: string;
  rtype: string | typeof RELINK_TYPE;
  target: '_self' | '_target' | '_parent' | '_top';
};

/**
 * Компонент ссылки.
 * @param props - пропсы
 * @param props.children - компонент содержимого энтити;
 * @param props.data - объект со специфичными данными для блока:
 * - href - ссылка, на которую указывает компонент;
 * - rtype - легаси параметр, оставленный для обратной совместимости. ВНИМАНИЕ. Использовать его не желательно, но пока он несет данные, важные для отображения.
 * - target - значение для атрибута ссылки target;
 * @param props.styles - дополнительные стили;
 * @param props.outerProps - дополнительные пропсы для компонента, которые можно передать из вне:
 * @param props.outerProps.isMobile - флаг, что это мобильная версия.
 *   Принимает отдельное свойство relink, которое используется
 *    для стилизации энтити с data.rtype R_RELINK, блока Читайте по теме.
 */
const LinkComponent = function Link({
  children,
  data,
  styles,
  outerProps: { isMobile },
}: EntityPropsType<LinkDataPropsType>) {
  const isRelink = data.rtype === RELINK_TYPE;

  if (!data.href) {
    return <span>{children}</span>;
  }

  const clearHref = getHrefWithoutTransition(data.href);

  // href нужен для обработки случаев, когда у юзера не включен javascript
  return (
    <a
      className={cn(
        cn(
          styles?.root,
          isRelink && styles?.relink,
          isMobile && styles?.mobile,
        ),
      )}
      href={clearHref}
      target={data.target || undefined}
    >
      {children}
    </a>
  );
};

export const Link = memo(
  LinkComponent,
  (prev, next) => prev.data.href === next.data.href,
);
