import React, { useCallback, memo } from 'react';

import { OnSwipedDesktopType } from 'common/components/Carousel/typings';
import { Top100AttributeType } from 'common/hooks/useTop100Attribute';
import { ArrowUpIcon } from 'icons';

type CarouselButtonPropsType = {
  top100Attribute: Top100AttributeType;
  direction: OnSwipedDesktopType['direction'];
  callbackSlide: OnSwipedDesktopType['callbackSlide'];
  onSwiped: ({ direction, callbackSlide }: OnSwipedDesktopType) => void;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

/**
 * Кнопка карусели.
 * @param props - пропсы
 * @param props.top100Attribute - топ100 атрибут;
 * @param props.direction - направление листания галереи;
 * @param props.callbackSlide - колбек для выполнения после свайпа;
 * @param props.onSwiped - обработчик свайпа.
 */
const CarouselButtonComponent = function CarouselButton({
  top100Attribute,
  direction,
  callbackSlide,
  onSwiped,
  ...buttonProps
}: CarouselButtonPropsType) {
  const handleClick = useCallback(
    () => onSwiped({ direction, callbackSlide }),
    [callbackSlide, direction, onSwiped],
  );

  return (
    <button
      type="button"
      onClick={handleClick}
      {...buttonProps}
      {...top100Attribute}
    >
      <ArrowUpIcon />
    </button>
  );
};

export const CarouselButton = memo(CarouselButtonComponent);
