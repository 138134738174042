import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { RecommendedClustersType } from 'common/components/Carousel/typings';
import { Top100AttributeType } from 'common/hooks/useTop100Attribute';
import { selectDomainConfig } from 'common/redux/runtime/selectors';
import { ArrowUpIcon } from 'icons';
import { generateClusterUrl } from 'utils/urlGenerator';

type CarouselLinkPropsType = {
  recommendedClusters: RecommendedClustersType;
  top100Attribute: Top100AttributeType;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

/**
 * Ссылка карусели на первый рекомендуемый кластер.
 * @param props - пропсы
 * @param props.recommendedClusters - рекомендательные кластера;
 * @param props.top100Attribute - топ100 атрибут.
 */
const CarouselLinkComponent = function CarouselLink({
  recommendedClusters,
  top100Attribute,
  ...linkProps
}: CarouselLinkPropsType) {
  const domainConfig = useSelector(selectDomainConfig, shallowEqual);

  const cluster = recommendedClusters[0];

  const clusterUrl = generateClusterUrl({
    domainConfig,
    clusterId: cluster?.id,
    normalizedTitle: cluster?.normalizedTitle,
    topic: cluster?.topicInfo,
  });

  return (
    <a {...linkProps} href={clusterUrl} {...top100Attribute}>
      Ещё фото
      <ArrowUpIcon />
    </a>
  );
};

export const CarouselLink = memo(CarouselLinkComponent);
