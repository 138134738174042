import _difference from 'lodash.difference';
import _groupBy from 'lodash.groupby';
import _mapValues from 'lodash.mapvalues';

import { AUTOTAG_TYPE } from 'config/common/autotag/typings';
import { PROJECT_ALIASES } from 'config/common/project/typings';

/**
 * Функция, возвращающая отсортированные маркеры в зависимости от приоритета
 * @param tags - массив маркеров(теги и автотеги)
 * @param projectAlias - alias проекта
 */
export const getTagsByPriority = (
  tags: MarkerType[],
  projectAlias: ProjectType['alias'],
) => {
  const tagsPriority = [
    AUTOTAG_TYPE.person,
    AUTOTAG_TYPE.organization,
    AUTOTAG_TYPE.media,
    'tag',
  ];
  if (PROJECT_ALIASES.News === projectAlias) tagsPriority.push('region');

  const groupedByType = _mapValues(_groupBy(tags, 'type'));
  const sortedTags = [];

  if (PROJECT_ALIASES.Auto === projectAlias && groupedByType.auto) {
    sortedTags.push(...groupedByType.auto);
  }

  if (PROJECT_ALIASES.Kino === projectAlias && groupedByType.movie) {
    sortedTags.push(...groupedByType.movie);
  }

  tagsPriority.forEach((key) => {
    if (groupedByType[key]) sortedTags.push(...groupedByType[key]);
  });

  const otherTags = _difference(tags, sortedTags);

  return [...sortedTags, ...otherTags];
};
