import cn from 'classnames';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { getCounter } from 'common/components/Carousel/utils';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { selectProjectAlias } from 'common/redux/runtime/selectors';
import { getUrlWithUtmParams } from 'utils/getUrlWithUtmParams';
import { addInstBanComment } from 'utils/socialsBan/addBanComment';

import { ExpandableDescription } from './ExpandableDescription';

type CaptionPropsType = {
  slide: ClusterGallerySlideType;
  slideIndex: number;
  gallery: Cluster['gallery'];
  styles: StylesType;
};

/**
 * Компонент с описанием для слайда с изображением в галерее.
 * @param props - пропсы
 * @param props.slide - слайд;
 * @param props.slideIndex - индекс слайда;
 * @param props.gallery - галлерея;
 * @param props.styles - стили компонента.
 */
const CaptionComponent = function Caption({
  slide,
  slideIndex,
  gallery,
  styles,
}: CaptionPropsType) {
  const { description = '', source } = slide;
  const { title, url } = source;

  const projectAlias = useSelector(selectProjectAlias);

  const top100Attribute = useTop100AttributeWithValue(
    `picture_${slideIndex}::text`,
  );

  const counter = getCounter({
    slideIndex,
    galleryLength: gallery.length,
  });

  const isRamblerImageSource = url?.includes('rambler.ru');

  const imageSourceSign = addInstBanComment(title || url);

  const sourceUrl = isRamblerImageSource
    ? getUrlWithUtmParams({
        url,
        params: { medium: 'source', source: `r${projectAlias}` },
      })
    : url;

  const sourceBlock = sourceUrl ? (
    <a
      className={cn(styles.source, styles.source_active)}
      aria-label={`Перейти на страницу источника ${imageSourceSign}`}
      href={sourceUrl}
      target="_blank"
      rel={`noopener noreferrer${isRamblerImageSource ? ' nofollow' : ''}`}
      {...top100Attribute}
    >
      {`© ${imageSourceSign}`}
    </a>
  ) : (
    <span className={styles.source}>{`© ${imageSourceSign}`}</span>
  );

  return (
    <div className={styles.caption}>
      <span className={styles.counter}>{counter}</span>
      <div className={styles.descriptionContainer}>
        <ExpandableDescription description={description} styles={styles} />
        {!!imageSourceSign && sourceBlock}
      </div>
    </div>
  );
};

export const Caption = memo(CaptionComponent);
