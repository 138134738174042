import { createAsyncThunk } from '@reduxjs/toolkit';

import { getNewsByTopic, getTagNewsByProject, getTops } from 'api';
import { selectClusterPageClusterFeed } from 'common/redux/pages/cluster/selectors';
import {
  selectApiConfig,
  selectDomainConfig,
  selectProjectId,
  selectVariables,
} from 'common/redux/runtime/selectors';
import { FINANCE_TAG_WIDGET } from 'config/common/finance/typings';
import { TOP_ID_COMMON } from 'config/common/top/constants';
import { SAVING_TOP_LIMIT } from 'desktop/components/FeedWidgetLayout/constants';
import { adaptClusterToCard } from 'utils/adapters';

import { upsertEntries } from '../../entries';
import { selectWidgetsTopProjectClusterIDs } from '../topProjectWidget/selectors';

const FETCHING_TOP_LIMIT = 30;
const FINANCE_FETCHING_LIMIT = 10;

type FetchTopMainNewsPropsType = {
  excludeIds: Card['id'][];
};

/**
 * Функция загрузки новостей для виджета главного топа.
 * @param props.excludeIds - исключаемые из отображения id.
 */
export const fetchTopMainNews = createAsyncThunk(
  'widgets/fetchTopMainNews',
  async ({ excludeIds }: FetchTopMainNewsPropsType, { getState, dispatch }) => {
    /* Использовать аккуратно - стейт перестает динамически обновляться */
    const state = getState() as AppState;

    const apiConfig = selectApiConfig(state);
    const domainConfig = selectDomainConfig(state);
    const variables = selectVariables(state);
    const projectId = selectProjectId(state);
    const clusterFeed = selectClusterPageClusterFeed(state);
    const topProjectClusters =
      selectWidgetsTopProjectClusterIDs(projectId)(state);

    const { data, error } = await getTops(
      apiConfig,
      TOP_ID_COMMON,
      FETCHING_TOP_LIMIT,
    );

    if (error || !data) {
      throw error;
    }

    const excludeClustersIds = [
      ...new Set([...clusterFeed, ...topProjectClusters, ...excludeIds]),
    ];

    const filteredCards = data
      .filter(({ id }) => !excludeClustersIds.includes(`${id}`))
      .slice(0, SAVING_TOP_LIMIT)
      .map((cluster) =>
        adaptClusterToCard({ cluster, domainConfig, variables }),
      );

    dispatch(upsertEntries(filteredCards));

    return filteredCards.map(({ id }) => id);
  },
);

type FetchTagFinanceNewsPropsType = {
  tagAlias: FINANCE_TAG_WIDGET;
};

/**
 * Функция загрузки новостей от тега для виджета финансов.
 * @param props.tagAlias - алиас тега
 */
export const fetchTagFinanceNews = createAsyncThunk(
  'widgets/fetchTagFinanceNews',
  async (
    { tagAlias }: FetchTagFinanceNewsPropsType,
    { getState, dispatch },
  ) => {
    const state = getState() as AppState;

    const apiConfig = selectApiConfig(state);
    const domainConfig = selectDomainConfig(state);
    const variables = selectVariables(state);
    const projectId = selectProjectId(state);

    const { data, error } = await getTagNewsByProject(
      apiConfig,
      projectId,
      tagAlias,
      1,
      FINANCE_FETCHING_LIMIT,
    );

    if (error || !data) {
      throw error;
    }

    const { clusters } = data;

    const filteredCards =
      clusters?.map((cluster) =>
        adaptClusterToCard({ cluster, domainConfig, variables }),
      ) || [];

    dispatch(upsertEntries(filteredCards));

    return filteredCards.map(({ id }) => id);
  },
);

type FetchTopicFinanceNewsPropsType = {
  topicId: number;
};

/**
 * Функция загрузки новостей от топика для виджета финансов.
 * @param props.topicId - id топика
 */
export const fetchTopicFinanceNews = createAsyncThunk(
  'widgets/fetchTopicFinanceNews',
  async (
    { topicId }: FetchTopicFinanceNewsPropsType,
    { getState, dispatch },
  ) => {
    const state = getState() as AppState;

    const apiConfig = selectApiConfig(state);
    const domainConfig = selectDomainConfig(state);
    const variables = selectVariables(state);

    const { data, error } = await getNewsByTopic({
      apiConfig,
      topicId,
      limit: FINANCE_FETCHING_LIMIT,
    });

    if (error || !data) {
      throw error;
    }

    const { clusters } = data;

    const filteredCards =
      clusters?.map((cluster) =>
        adaptClusterToCard({ cluster, domainConfig, variables }),
      ) || [];

    dispatch(upsertEntries(filteredCards));

    return filteredCards.map(({ id }) => id);
  },
);
