import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PAGE_TYPE } from 'config/common/router/typings';

type StateGuideCityPlacesType = {
  // Пуиды настройки для рекламы
  puids: PuidsType;
  // id списка мест отображаемых на текущей странице
  placesIds: number[];
  // Alias текущего города
  cityAlias: string;
  // Alias текущей страны
  countryAlias: string;
};

const initialState: StateGuideCityPlacesType = {
  puids: {
    puid6: 'RTRAVEL_CITY',
    puid18: 'RTRAVEL_CITY_PLACES',
  },
  placesIds: [],
  cityAlias: '',
  countryAlias: '',
};

const guideCityPlacesSlice = createSlice({
  name: PAGE_TYPE.guideCityPlaces,
  initialState,
  reducers: (create) => ({
    /**
     * Сохранение списка мест отображаемых на странице
     */
    setPageCityPlaces: create.reducer(
      (state, { payload }: PayloadAction<number[]>) => {
        // Тк нет загрузки по страницам то просто через присваивание
        state.placesIds = payload;
      },
    ),

    /**
     * Сохранение текущего города
     */
    setPageCityAlias: create.reducer(
      (state, { payload }: PayloadAction<string>) => {
        state.cityAlias = payload;
      },
    ),

    /**
     * Сохранение страны
     */
    setPageCountryAlias: create.reducer(
      (state, { payload }: PayloadAction<string>) => {
        state.countryAlias = payload;
      },
    ),
  }),
});

export const { setPageCityPlaces, setPageCityAlias, setPageCountryAlias } =
  guideCityPlacesSlice.actions;

export const guideCityPlacesReducer = guideCityPlacesSlice.reducer;
