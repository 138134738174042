// @ts-nocheck: ¯\_(ツ)_/¯
import _debounce from 'lodash.debounce';
import _throttle from 'lodash.throttle';
import { useEffect, useMemo } from 'react';

const RESIZE_EVENT = 'resize';
const SCROLL_EVENT = 'scroll';
const MS_DEFAULT = 100;

/** Тип декорирования */
enum DECORATOR_TYPE {
  throttle,
  debounce,
}

type ConfigType = {
  ms?: number;
  decoratorType?: DECORATOR_TYPE;
};

type WindowEvent = UIEvent & { target: Window };

type UseScrollResizeType = (
  callback?: ((event: WindowEvent) => void) | null,
  config?: ConfigType,
) => void;

type UseDecoratedFunctionType = (
  callback?: ((event: Event) => void) | null,
  config?: ConfigType,
) => ((event: Event) => void) | null;

/**
 * Метод декорирования функции
 * @param callback - обработчик на событие скролла
 * @param config - конфиг
 * @param config.decoratorType - тип декорирования
 * @param config.ms - время задержки колбэка
 */
const useDecoratedFunction: UseDecoratedFunctionType = (
  callback,
  config = {},
) =>
  // Почему useMemo вместо useCallback можно почитать здесь: https://dmitripavlutin.com/react-throttle-debounce/#2-debouncing-a-callback-the-first-attempt
  useMemo(() => {
    if (!callback) {
      return null;
    }

    const { ms = MS_DEFAULT, decoratorType = DECORATOR_TYPE.throttle } = config;

    if (ms) {
      const decoratorFunc =
        decoratorType === DECORATOR_TYPE.throttle ? _throttle : _debounce;

      return decoratorFunc(callback, ms);
    }

    return callback;
  }, [callback, config]);

/**
 * Хук обработки события в useEffect
 * @param eventName - имя события для наблюдения
 * @param callback - обработчик на событие ресайза
 */
const useEventEffect = (
  eventName: typeof RESIZE_EVENT | typeof SCROLL_EVENT,
  callback: ((event: WindowEvent) => void) | null,
) => {
  useEffect(() => {
    if (!callback) return undefined;

    window.addEventListener(eventName, callback);

    return () => {
      window.removeEventListener(eventName, callback);

      if (callback.cancel) {
        callback.cancel();
      }
    };
  }, [callback, eventName]);
};

/**
 * Хук обработки скролла с дебаунсингом
 * @param callback - обработчик на событие скролла
 * @param config - конфиг
 * @param config.decoratorType - тип декорирования (throttle)
 * @param config.ms - время задержки колбэка (100мс)
 */
export const useScroll: UseScrollResizeType = (callback, config) => {
  const localCallback = useDecoratedFunction(callback, config);

  useEventEffect(SCROLL_EVENT, localCallback);
};

/**
 * Хук обработки ресайза с дебаунсингом
 * @param callback - обработчик на событие ресайза
 * @param config - конфиг
 * @param config.decoratorType - тип декорирования (throttle)
 * @param config.ms - время задержки колбэка (100мс)
 */
export const useResize: UseScrollResizeType = (callback, config) => {
  const localCallback = useDecoratedFunction(callback, config);

  useEventEffect(RESIZE_EVENT, localCallback);
};

/**
 * Хук обработки скролла и ресайза с дебаунсингом
 * @param callback - обработчик на событие скролла и ресайза
 * @param config - конфиг
 * @param config.decoratorType - тип декорирования (throttle)
 * @param config.ms - время задержки колбэка (100мс)
 */
export const useScrollResize: UseScrollResizeType = (callback, config) => {
  const localCallback = useDecoratedFunction(callback, config);

  useEventEffect(SCROLL_EVENT, localCallback);
  useEventEffect(RESIZE_EVENT, localCallback);
};
