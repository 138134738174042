const TRANSITION_REGEXP =
  /https:\/\/www\.rambler\.ru\/transition\/\?redirect_url=/;

/**
 * Функция замены внешник ссылок, в которых есть редирект
 * @param href - внешняя ссылка, которую надо изменить.
 */
export const getHrefWithoutTransition = (href: string) => {
  const cleanHref = href.replace(TRANSITION_REGEXP, '');

  /*
   * В партнерских материалах могут встречаться такие ссылки:
   * https://bs.serving-sys.ru/Serving/adServer.bs?cn=trd&pli=1087734347&adid=1087734348&ord=%random%&pcp=$$%rclid%$$&LineID=$$rclid=%rclid%&erid=erid$$
   * которые decodeURIComponent не может проглотить поэтому через try
   * Для примера: https://finance.rambler.ru/money/53617508-osuschestvit-mechtu-i-poluchit-dohod-komu-prigoditsya-programma-dolgosrochnyh-sberezheniy/
   */
  try {
    return decodeURIComponent(cleanHref);
  } catch {
    return cleanHref;
  }
};
