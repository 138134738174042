import { selectClusterById } from 'common/redux/commonData/entries/selectors';
import { selectClusterPageCurrentClusterId } from 'common/redux/pages/cluster/selectors';
import { selectProjectId } from 'common/redux/runtime/selectors';
import { PROJECT_IDS } from 'config/common/project/typings';
import { TOPIC_ALIAS_MOVIES } from 'config/common/topic/constants';

/**
 * Функция дополнения шаблона html верстки дополнительными тегами специфично для страницы кластера.
 * @param props - пропсы
 * @param props.getState - функция получения актуального стейта.
 */
export const renderTemplate = ({ getState }: AppStore) => {
  const state = getState() as AppState;

  const projectId = selectProjectId(state);
  const clusterId = selectClusterPageCurrentClusterId(state);
  const firstCluster = selectClusterById(clusterId)(state);

  if (
    projectId !== PROJECT_IDS.Kino ||
    firstCluster?.topicAlias !== TOPIC_ALIAS_MOVIES
  )
    return '';

  return `
    <noscript>
      <img src="https://ad.doubleclick.net/ddm/activity/src=8739587;type=invmedia;cat=ezvgsnmt;u1=afisha.rambler.ru;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1?" width="1" height="1" alt=""/>
    </noscript>
  `;
};
