import { InjectorType } from '@media-components/draft-parser';

import { SHORT_BLOCK_SECRET_WORD } from 'config/common/cluster/constants';

import { shortSummaryBlockGenerator } from '../../generators/shortSummaryBlockGenerator';

type Props = {
  isMulticluster: boolean;
  forceRedesign: boolean;
  clusterFeedListIndex: number;
  position: number;
};

/**
 * Инжектор короткого описания кластера.
 * Короткое описание кластера – это первый параграф если он начинается с SHORT_BLOCK_SECRET_WORD
 * @param props - пропсы
 * @param props.blocks - блоки драфта;
 * @param props.forceRedesign - флаг редизайна;
 * @param props.clusterFeedListIndex - индекс кластера в бесконечке;
 * @param props.isMulticluster - флаг, что это мультикластер;
 * @param props.position - позиция блока в списке.
 */
export const injectShortSummary: InjectorType<Props> = ({
  blocks,
  isMulticluster,
  forceRedesign,
  clusterFeedListIndex,
  position,
}) => {
  if (!blocks[0].text?.trim().startsWith(SHORT_BLOCK_SECRET_WORD)) {
    return blocks;
  }

  const summary = shortSummaryBlockGenerator({
    block: {
      ...blocks[0],
      text: blocks[0].text.replace(SHORT_BLOCK_SECRET_WORD, ''),
    },
  });

  if (!forceRedesign || isMulticluster || clusterFeedListIndex !== 0) {
    // вырезаем блок с коротким описанием и вставляем его в никуда
    return [...blocks.slice(1)];
  }

  return [
    // переставляем первый блок на место после MediaSection
    ...blocks.slice(1, position + 1),
    summary,
    ...blocks.slice(position + 1),
  ];
};
