import { PayloadAction } from '@reduxjs/toolkit';

import { getIFramesList } from 'api';
import { PAGE_TYPE } from 'config/common/router/typings';
import { VIEWPORT_TYPE } from 'config/common/viewport';
import { brandingCleaner } from 'utils/brandingCleaner';

import { selectApiConfig } from '../runtime/selectors';
import { createSlice } from '../utils';

import { videoAdAdapter } from './adapter';

const initialState: AppController = {
  // Флаг, где происходит рендеринг. Нужен для исключения дублирования запроса данных на клиенте
  serverRender: true,
  // Ключ инкрементации баннеров для SPA
  reloadKey: 1,
  // Название текущей страницы
  pageName: '' as PAGE_TYPE,
  // Флаг AdBlock'а
  adblockEnabled: false,
  // Флаг отключения рекламы
  disableAdv: false,
  // Хранилище состояния баннеров
  bannersState: {},
  // Хранилище данных видео-реклам по вертикалям
  videoAd: videoAdAdapter.getInitialState({
    fetching: false,
    error: '',
  }),
  // Тип вьюпорта планшет или десктоп
  viewportType: VIEWPORT_TYPE.desktop,
};

/**
 * Стейт для управления SPA на клиенте.
 */
const appControllerSlice = createSlice({
  name: 'appController',
  initialState,
  reducers: (create) => ({
    /**
     * Получение видео-рекламы по вертикалям.
     */
    fetchVideoAd: create.asyncThunk(
      async (_, { getState }) => {
        const api = selectApiConfig(getState() as AppState);

        const { data, error } = await getIFramesList(api);

        if (error || !data) {
          throw error || new Error('Ошибка при получении видео-рекламы');
        }

        return data;
      },
      {
        pending: (state) => {
          state.videoAd.fetching = true;
        },
        fulfilled: (state, { payload }) => {
          videoAdAdapter.upsertMany(state.videoAd, payload);
          state.videoAd.error = '';
        },
        rejected: (state, { error }) => {
          state.videoAd.error = error.message;
        },
        settled: (state) => {
          state.videoAd.fetching = false;
        },
      },
    ),

    /**
     * Установка флага, что серверный рендеринг закончился
     */
    setServerLoaded: create.reducer((state) => {
      state.serverRender = false;
    }),

    /**
     * Увеличение счетчика для перезагрузки рекламы при SPA
     */
    incrementReloadKey: create.reducer((state) => {
      brandingCleaner();
      state.reloadKey += 1;
    }),

    /**
     * Установка типа страницы
     * @param action.payload - тип страницы из PAGE_TYPE
     */
    setPageName: create.reducer(
      (state, { payload }: PayloadAction<PAGE_TYPE>) => {
        state.pageName = payload;
      },
    ),

    /**
     * Установка флага AdBlock'а
     * @param action.payload - флага AdBlock'а
     */
    setAdblockEnabled: create.reducer(
      (state, { payload }: PayloadAction<boolean>) => {
        state.adblockEnabled = payload;
      },
    ),

    /**
     * Установка флага для отключения рекламы
     * @param action.payload - флага для отключения рекламы
     */
    setDisableAdv: create.reducer(
      (state, { payload }: PayloadAction<boolean>) => {
        state.disableAdv = payload;
      },
    ),

    /**
     * Устанавливает флаг, что это широкий или узкий (планшетный) экран.
     * @param action.payload – значение tablet или desktop
     */
    setViewportType: create.reducer(
      (state, { payload: isTablet }: PayloadAction<boolean>) => {
        state.viewportType = isTablet
          ? VIEWPORT_TYPE.tablet
          : VIEWPORT_TYPE.desktop;
      },
    ),

    /**
     * Установка флага что баннер был успешно загружен
     * @param action.payload.bannerName – название баннера
     * @param action.payload.isLoaded – флаг что баннер был загружен
     */
    setBannerIsLoaded: create.reducer(
      (
        state,
        { payload }: PayloadAction<{ bannerName: string; isLoaded: boolean }>,
      ) => {
        const { bannerName, isLoaded } = payload;

        if (bannerName) {
          state.bannersState[bannerName] = {
            ...state.bannersState[bannerName],
            isLoaded,
          };
        }
      },
    ),

    /**
     * Установка флага что баннер брендированный (когда меняется задний фон страницы под рекламу)
     * @param action.payload.bannerName – название баннера
     * @param action.payload.isBranding – флаг что баннер брендированный
     */
    setBannerIsBranding: create.reducer(
      (
        state,
        { payload }: PayloadAction<{ bannerName: string; isBranding: boolean }>,
      ) => {
        const { bannerName, isBranding } = payload;

        if (bannerName) {
          state.bannersState[bannerName] = {
            ...state.bannersState[bannerName],
            isBranding,
          };
        }
      },
    ),

    /**
     * Сброс стора для баннеров
     */
    resetBannersState: create.reducer((state) => {
      state.bannersState = initialState.bannersState;
    }),
  }),
  selectors: {
    selectServerRender: (state) => state.serverRender,
    selectReloadKey: (state) => state.reloadKey,
    selectPageName: (state) => state.pageName,
    selectAdblockEnabled: (state) => state.adblockEnabled,
    selectDisableAdv: (state) => state.disableAdv,
    selectViewportType: (state) => state.viewportType,
  },
});

export const appControllerReducer = appControllerSlice.reducer;

export const {
  fetchVideoAd,
  setServerLoaded,
  incrementReloadKey,
  setPageName,
  setAdblockEnabled,
  setDisableAdv,
  setViewportType,
  setBannerIsLoaded,
  setBannerIsBranding,
  resetBannersState,
} = appControllerSlice.actions;

export const {
  selectServerRender,
  selectReloadKey,
  selectPageName,
  selectAdblockEnabled,
  selectDisableAdv,
  selectViewportType,
} = appControllerSlice.selectors;
