import loadable from '@loadable/component';
import React from 'react';

import { fetchCurrencyInfo } from 'common/redux/commonData/currencies';
import { selectCurrencyByCharCode } from 'common/redux/commonData/currencies/selectors';
import { setCurrentCurrency } from 'common/redux/commonData/widgets/banksRatesWidget';
import { fetchBanksRates } from 'common/redux/commonData/widgets/banksRatesWidget/asyncs';
import {
  BANKS_RATES_CURRENCY,
  BANKS_RATES_CURRENCY_ACTION_BUY,
  BANKS_RATES_CURRENCY_ACTION_SELL,
} from 'common/redux/commonData/widgets/banksRatesWidget/typings';
import { loadDataForSingleCourseChart } from 'common/redux/commonData/widgets/chartWidget/asyncs';
import { resetDisabledPeriod } from 'common/redux/commonData/widgets/chartWidgetPeriods';
import { setConsensusCurrency } from 'common/redux/commonData/widgets/consensusWidget';
import { setActiveTab } from 'common/redux/commonData/widgets/tabsWidget';
import { TABS_TYPE } from 'common/redux/commonData/widgets/tabsWidget/typings';
import {
  fetchTagFinanceNews,
  fetchTopicFinanceNews,
} from 'common/redux/commonData/widgets/topMainWidget/asyncs';
import {
  setFirstCurrencyConverter,
  setSecondCurrencyConverter,
} from 'common/redux/pages/converter';
import {
  setCurrencyPuids,
  setCurrentCurrencyId,
} from 'common/redux/pages/currency';
import { selectCurrencyPageSource } from 'common/redux/pages/currency/selectors';
import {
  CURRENCY_CHAR_CODE,
  FINANCE_TAG_WIDGET,
} from 'config/common/finance/typings';
import { PAGE_TYPE } from 'config/common/router/typings';
import { ECONOMICS_ID } from 'config/common/topic/constants';

import { appendPageDownloader } from '../pageLoadable';
import { IAppRoute } from '../typings';
import { getFinancePromiseList } from '../utils';

const CurrencyDesktop = loadable(() => import('desktop/pages/Currency'));
const CurrencyMobile = loadable(() => import('mobile/pages/Currency'));

type CurrencyMatchProps = {
  charCode: CurrencyType['charCode'];
};

/**
 * Роут для конкретной валюты.
 * @see https://finance.rambler.ru/currencies/USD/
 * @see https://www.figma.com/file/vboo3WIcMAD7qk7BgafOEl/Finance?type=design&node-id=2186-25834&mode=design&t=uAZVDSc1sxxW29fV-0
 * @param isMobile - флаг мобильной версии
 */
export const currencyRoutes = (
  isMobile: SettingsType['isMobile'],
): IAppRoute<CurrencyMatchProps> => ({
  name: PAGE_TYPE.currency,
  exact: true,
  path: '/currencies/:charCode([A-Z]{3})/',
  render: isMobile ? () => <CurrencyMobile /> : () => <CurrencyDesktop />,
  fetchData: async ({ dispatch, getState }, { params: { charCode } }) => {
    dispatch(setConsensusCurrency(charCode));
    await dispatch(fetchCurrencyInfo({ charCode }));

    const currencyId = selectCurrencyByCharCode(charCode)(getState())?.id;
    const source = selectCurrencyPageSource(getState());

    dispatch(setCurrentCurrencyId(currencyId));
    dispatch(setCurrencyPuids());
    dispatch(setActiveTab(TABS_TYPE.dynamics));
    dispatch(resetDisabledPeriod());

    const promiseList: PromiseListType = [
      ...getFinancePromiseList(dispatch, true),
      dispatch(
        loadDataForSingleCourseChart({
          currencyCharCode: charCode,
          source,
        }),
      ),
    ];

    // Для виджета с новостями
    const isUSD = charCode === CURRENCY_CHAR_CODE.USD;
    const isEUR = charCode === CURRENCY_CHAR_CODE.EUR;

    if (isUSD || isEUR) {
      const tagAlias = isUSD
        ? FINANCE_TAG_WIDGET.Dollar
        : FINANCE_TAG_WIDGET.Evro;

      promiseList.push(dispatch(fetchTagFinanceNews({ tagAlias })));
    } else {
      promiseList.push(
        dispatch(fetchTopicFinanceNews({ topicId: ECONOMICS_ID })),
      );
    }

    // Для виджет курса обмена
    if (
      charCode === CURRENCY_CHAR_CODE.USD ||
      charCode === CURRENCY_CHAR_CODE.EUR
    ) {
      dispatch(setCurrentCurrency(charCode));

      promiseList.push(
        dispatch(
          fetchBanksRates({
            charCode: BANKS_RATES_CURRENCY[charCode],
            sort: BANKS_RATES_CURRENCY_ACTION_BUY[charCode],
          }),
        ),
        dispatch(
          fetchBanksRates({
            charCode: BANKS_RATES_CURRENCY[charCode],
            sort: BANKS_RATES_CURRENCY_ACTION_SELL[charCode],
          }),
        ),
      );
    }

    await Promise.all(
      appendPageDownloader({
        promiseList,
        loadableComponent: isMobile ? CurrencyMobile : CurrencyDesktop,
        chunkName: Object.keys({ CurrencyDesktop })[0],
      }),
    );

    // Для конвертера валют
    dispatch(setFirstCurrencyConverter(CURRENCY_CHAR_CODE.RUB));
    dispatch(setSecondCurrencyConverter(charCode || CURRENCY_CHAR_CODE.USD));
  },
});
