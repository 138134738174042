import { BlockElementPropsType } from '@media-components/draft-parser';
import React, { memo } from 'react';

import { ShortSummaryDataType } from 'common/components/ClusterContentOnDraft/draftModifier/generators/shortSummaryBlockGenerator';
import { ShortSummary } from 'common/components/ShortSummary';

/**
 * Компонент блока с коротким описанием кластера.
 * @param props - пропсы
 * @param props.children - дочерний компонент.
 */
const ShortSummaryBlockComponent = function ShortSummaryBlock({
  children,
}: BlockElementPropsType<ShortSummaryDataType, unknown>) {
  return <ShortSummary>{children}</ShortSummary>;
};

export const ShortSummaryBlock = memo(ShortSummaryBlockComponent);
