import { createSlice } from '@reduxjs/toolkit';

import { themesAdapter } from './adapter';
import { fetchThemesByCluster } from './asyncs';

/**
 * Стейт сюжетов.
 * @param entries - объект с ключ: id сюжета - значение: информация о сюжете;
 * @param fetching - флаг, что происходит загрузка хотя бы одного сюжета;
 * @param error - сообщение последней ошибки, произошедшей при загрузке сюжета.
 */
const themesSlice = createSlice({
  name: 'themes',
  initialState: themesAdapter.getInitialState({
    fetching: false,
    error: '',
  } as InitialState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchThemesByCluster.pending, (state) => {
      state.fetching = true;
    });

    builder.addCase(fetchThemesByCluster.fulfilled, (state, { payload }) => {
      // Если появится новая информация - перезаписывается старая
      themesAdapter.upsertMany(state, payload);
      state.fetching = false;
    });

    builder.addCase(fetchThemesByCluster.rejected, (state, error) => {
      state.fetching = false;
      state.error = error.error.message;
    });
  },
});

export const themesReducer = themesSlice.reducer;
