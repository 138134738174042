import { selectCardById } from 'common/redux/commonData/entries/selectors';

import { selectPages } from '../selectors';

export const selectLatest = (state: AppState) => selectPages(state).latest;

export const selectLatestPuids = (state: AppState) => selectLatest(state).puids;

export const selectLatestClustersIds = (state: AppState) =>
  selectLatest(state).clustersIds;

export const selectLatestCurrentPage = (state: AppState) =>
  selectLatest(state).currentPage;

export const selectLatestHasNextPage = (state: AppState) =>
  selectLatest(state).hasNextPage;

export const selectLatestFetchingState = (state: AppState) =>
  selectLatest(state).fetching;

export const selectLatestErrorState = (state: AppState) =>
  selectLatest(state).error;

export const selectLatestClusters = (state: AppState) =>
  selectLatestClustersIds(state).map((clusterId) =>
    selectCardById(clusterId)(state),
  ) || [];
