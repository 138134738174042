import { CURRENCY_CHAR_CODE } from 'config/common/finance/typings';

export const CONSENSUS_CURRENCIES = [
  CURRENCY_CHAR_CODE.USD,
  CURRENCY_CHAR_CODE.EUR,
  CURRENCY_CHAR_CODE.CNY,
  CURRENCY_CHAR_CODE.GBP,
  CURRENCY_CHAR_CODE.JPY,
];

export const DEFAULT_CONSENSUS_CURRENCY = CURRENCY_CHAR_CODE.USD;
