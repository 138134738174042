import { getGigaChatData } from 'api';
import { RamblerGigaChatData } from 'common/components/RamblerComponents/RamblerGigaChat';
import { selectApiConfig } from 'common/redux/runtime/selectors';
import { createSlice } from 'common/redux/utils';

import { selectCommonData } from '../selectors';

type GigaChatState = {
  // Данные для виджета
  data: RamblerGigaChatData | null;
} & InitialState;

const initialState: GigaChatState = {
  data: null,
  error: undefined,
  fetching: false,
};

const gigaChatSlice = createSlice({
  name: 'gigaChat',
  initialState,
  reducers: (create) => ({
    fetchGigaChatData: create.asyncThunk(
      async (_, { getState }) => {
        const apiConfig = selectApiConfig(getState() as AppState);
        const response = await getGigaChatData(apiConfig);

        if (!response?.data || response.error) {
          throw new Error(
            `Ошибка при получении данных гигачата: ${response?.error}`,
          );
        }

        return response.data;
      },
      {
        pending: (state) => {
          state.fetching = true;
        },
        fulfilled: (state, { payload }) => {
          state.data = payload;
        },
        rejected: (state, { error }) => {
          state.error = error.message;
        },
        settled: (state) => {
          state.fetching = false;
        },
      },
    ),
  }),
  selectors: {
    selectGigaChatData: (state) => state.data,
  },
});

export const gigaChatReducer = gigaChatSlice.reducer;

export const selectGigaChatData = (state: AppState) =>
  gigaChatSlice.selectors.selectGigaChatData(selectCommonData(state));

export const { fetchGigaChatData } = gigaChatSlice.actions;
