import { selectTravelCityByAlias } from 'common/redux/commonData/travelCities/selectors';
import { selectTravelRegionPlacesByIds } from 'common/redux/commonData/travelRegionPlaces/selectors';

import { selectPages } from '../selectors';

export const selectGuideCityPlaces = (state: AppState) =>
  selectPages(state).guideCityPlaces;

export const selectGuideCityPlacesPuids = (state: AppState) =>
  selectGuideCityPlaces(state).puids;

export const selectGuideCityPlacesIds = (state: AppState) =>
  selectGuideCityPlaces(state).placesIds;

export const selectGuideCityPlacesData = (state: AppState) => {
  const ids = selectGuideCityPlacesIds(state);

  return selectTravelRegionPlacesByIds(ids)(state);
};

export const selectGuideCityPlacesCountryAlias = (state: AppState) =>
  selectGuideCityPlaces(state).countryAlias;

export const selectGuideCityPlacesCityAlias = (state: AppState) =>
  selectGuideCityPlaces(state).cityAlias;

export const selectGuideCityPlacesCityData = (state: AppState) =>
  selectTravelCityByAlias(selectGuideCityPlacesCityAlias(state))(state);

export const selectGuideCityPlacesCityShort = (state: AppState) =>
  selectGuideCityPlacesCityData(state).short;

export const selectGuideCityPlacesCityName = (state: AppState) =>
  selectGuideCityPlacesCityData(state).name;
