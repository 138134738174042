import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { CURRENCY_CHAR_CODE } from 'config/common/finance/typings';
import { PROJECT_ALIASES } from 'config/common/project/typings';
import { PAGE_TYPE } from 'config/common/router/typings';

type CurrenciesState = {
  firstCurrencyCharCode: CurrencyType['charCode'];
  secondCurrencyCharCode: CurrencyType['charCode'];
  puids: PuidsType;
  isLoading: boolean;
};

const initialState: CurrenciesState = {
  // Символьный код первой валюты (по умолчанию доллар).
  firstCurrencyCharCode: CURRENCY_CHAR_CODE.USD,
  // Символьный код второй валюты (по умолчанию евро).
  secondCurrencyCharCode: CURRENCY_CHAR_CODE.EUR,
  // Рекламные пуиды
  puids: {},
  // Флаг загрузки
  isLoading: false,
};

const currenciesSlice = createSlice({
  name: PAGE_TYPE.currencies,
  initialState,
  reducers: {
    /**
     * Установка первой валюты.
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload - символьный код валюты.
     */
    setFirstCurrencyCharCode(
      state,
      { payload }: PayloadAction<CurrencyType['charCode']>,
    ) {
      state.firstCurrencyCharCode = payload;
    },

    /**
     * Установка второй валюты.
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload - символьный код валюты.
     */
    setSecondCurrencyCharCode(
      state,
      { payload }: PayloadAction<CurrencyType['charCode']>,
    ) {
      state.secondCurrencyCharCode = payload;
    },

    /**
     * Генерация puids для страницы курса валют
     * @param state - состояние слайса
     */
    setCurrenciesPuids(state) {
      const puidName = getPuidName(PROJECT_ALIASES.Finance);

      state.puids = {
        puid6: `${puidName}_currencies`.toUpperCase(),
        puid18: `${puidName}_currencies_main`.toUpperCase(),
      };
    },

    /**
     * Установка страницы курса валюты в состояние загрузки. Нужно для отображения лодера при spa переходов
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload - флаг загрузки.
     */
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
  },
});

export const currenciesReducer = currenciesSlice.reducer;
export const {
  setFirstCurrencyCharCode,
  setSecondCurrencyCharCode,
  setCurrenciesPuids,
  setIsLoading,
} = currenciesSlice.actions;
