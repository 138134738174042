import { PAGE_TYPE } from 'config/common/router/typings';

/**
 * Мапка из массивов, в которых
 *  - На первом месте находится страница ОТКУДА юзер переходит
 *  - На втором месте находится страница КУДА юзер переходит
 *
 * Возможно это можно как-то почистить для читаемости,
 *  но я пока хз.
 */
const PAGES_WITH_DISABLED_SCROLL = [
  [PAGE_TYPE.banks, PAGE_TYPE.banks],
  [PAGE_TYPE.currency, PAGE_TYPE.currencies],
  [PAGE_TYPE.currencies, PAGE_TYPE.currencies],
  [PAGE_TYPE.currency, PAGE_TYPE.currency],
  [PAGE_TYPE.currency, PAGE_TYPE.consensus],
  [PAGE_TYPE.currency, PAGE_TYPE.cross],
  [PAGE_TYPE.cross, PAGE_TYPE.cross],
  [PAGE_TYPE.cross, PAGE_TYPE.currency],
  [PAGE_TYPE.cross, PAGE_TYPE.consensus],
  [PAGE_TYPE.consensus, PAGE_TYPE.consensus],
  [PAGE_TYPE.consensus, PAGE_TYPE.currency],
  [PAGE_TYPE.consensus, PAGE_TYPE.cross],
  [PAGE_TYPE.converter, PAGE_TYPE.converter],
  [PAGE_TYPE.cluster, PAGE_TYPE.cluster],
  [PAGE_TYPE.guideCity, PAGE_TYPE.guideCity],
  [PAGE_TYPE.guideCountry, PAGE_TYPE.guideCountry],
  [PAGE_TYPE.guidePlace, PAGE_TYPE.guidePlace],
];

/**
 * Тулза, которая определяет, можно ли оставить юзера на месте
 * при переходе между двумя страницами.
 * @param prevPage - страница, на которой юзер был до этого;
 * @param nextPage - страница, на которую юзер переходит.
 * @returns флаг, нужно ли оставить юзера на месте.
 */
export const checkShouldPreventScroll = (
  prevPage: PAGE_TYPE,
  nextPage: PAGE_TYPE,
) =>
  PAGES_WITH_DISABLED_SCROLL.some(
    ([prev, next]) => prev === prevPage && next === nextPage,
  );
