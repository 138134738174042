import loadable from '@loadable/component';
import React from 'react';

import { fetchVideoAd } from 'common/redux/appController';
import { selectPageName } from 'common/redux/appController/selectors';
import { fetchClustersByTopicForEntries } from 'common/redux/asyncs';
import {
  addPuidsToEntry,
  fetchFullCluster,
} from 'common/redux/commonData/entries';
import {
  selectClusterById,
  selectClusterIsArticle,
} from 'common/redux/commonData/entries/selectors';
import { selectTopicIdByAlias } from 'common/redux/commonData/topics/selectors';
import { fetchBreakingNewsCluster } from 'common/redux/commonData/widgets/breakingNewsWidget/asyncs';
import { fetchTopProjectNews } from 'common/redux/commonData/widgets/topProjectWidget/asyncs';
import { setClusterTopic, setCurrentCluster } from 'common/redux/pages/cluster';
import { fetchTopicNews, setTopicInfo } from 'common/redux/pages/topic';
import { selectProjectId } from 'common/redux/runtime/selectors';
import { renderTemplate } from 'common/routes/cluster/renderTemplate';
import {
  LIMIT_BY_CLUSTERS_FETCHING,
  LIMIT_BY_TOP_DESKTOP,
  LIMIT_BY_TOP_MOBILE,
} from 'common/routes/constants';
import { appendPageDownloader } from 'common/routes/pageLoadable';
import { CLUSTER_TYPE } from 'config/common/cluster/typings';
import { PAGE_TYPE } from 'config/common/router/typings';
import {
  TOP_ID_PROJECT,
  TOP_PROJECT_TEASER_NEWS_COUNT,
  TOP_PROJECT_WIDGET_NEWS_COUNT,
} from 'config/common/top/constants';
import { TOPIC_ALIAS_PHOTO } from 'config/common/topic/constants';

import { IAppRoute } from '../typings';

const ClusterDesktop = loadable(() => import('desktop/pages/Cluster'));
const ClusterMobile = loadable(() => import('mobile/pages/Cluster'));

type ClusterMatchRoute = {
  clusterId: string;
  topicAlias: TopicType['alias'];
};

/**
 * Страница кластера
 * @see https://news.rambler.ru/world/51042514-glava-frantsuzskogo-regiona-prizvala-omolodit-otvetstvennost-za-besporyadki/
 * @param isMobile - флаг мобильной версии
 */
export const clusterRoutes = (
  isMobile: SettingsType['isMobile'],
): IAppRoute<ClusterMatchRoute> => ({
  name: PAGE_TYPE.cluster,
  exact: true,
  path: '/:topicAlias/:clusterId(\\d{6,10})\\-:clusterInfo?/',
  render: isMobile ? () => <ClusterMobile /> : () => <ClusterDesktop />,
  fetchData: async ({ dispatch, getState }, { params }) => {
    const pageName = selectPageName(getState());

    if (!__SERVER__ && pageName === PAGE_TYPE.cluster) return;

    const { clusterId, topicAlias } = params;

    const projectId = selectProjectId(getState());
    const topicId = selectTopicIdByAlias(topicAlias, projectId)(getState());

    dispatch(setClusterTopic(topicId));
    dispatch(setCurrentCluster(clusterId));

    const promiseList: PromiseListType = [
      // Данные по кластеру
      dispatch(fetchFullCluster({ clusterId })),
    ];

    if (!isMobile) {
      promiseList.push(
        // Данные по рубрике кластера
        dispatch(fetchClustersByTopicForEntries({ topicId })),
        // Данные о видео-рекламе по вертикалям
        dispatch(fetchVideoAd()),
        // Данные о блоке "Срочной новости"
        dispatch(fetchBreakingNewsCluster({ projectId })),
      );
    }

    await Promise.all(
      appendPageDownloader({
        promiseList,
        loadableComponent: isMobile ? ClusterMobile : ClusterDesktop,
        chunkName: Object.keys({ ClusterDesktop })[0],
      }),
    );

    const isArticle = selectClusterIsArticle(clusterId)(getState());

    if (isArticle) {
      await dispatch(
        fetchTopProjectNews({
          projectId,
          topId: TOP_ID_PROJECT,
          limit: isMobile
            ? TOP_PROJECT_TEASER_NEWS_COUNT
            : TOP_PROJECT_WIDGET_NEWS_COUNT,
        }),
      );
    }

    if (!isMobile) {
      // Подгрузка id кластеров форматного топика фото для рекомендательного банера в карусели кластера (src/desktop/pages/Cluster/components/ArticleMediaSection/components/Gallery/index.tsx)
      const cluster = selectClusterById(clusterId)(getState());
      const topicPhotoId = selectTopicIdByAlias(
        TOPIC_ALIAS_PHOTO,
        projectId,
      )(getState());

      const isTopicPhoto = topicPhotoId
        ? cluster?.topicIds.includes(topicPhotoId)
        : false;
      const hasGallery =
        cluster?.type === CLUSTER_TYPE.photo && !!cluster.gallery;

      if (isTopicPhoto && hasGallery) {
        dispatch(setTopicInfo({ topicId: topicPhotoId, isFormatTopic: true }));

        await dispatch(
          fetchTopicNews({
            topicId: topicPhotoId,
            length: LIMIT_BY_CLUSTERS_FETCHING,
            limitBy: isMobile ? LIMIT_BY_TOP_MOBILE : LIMIT_BY_TOP_DESKTOP,
          }),
        );
      }
    }

    dispatch(addPuidsToEntry({ clusterId, appState: getState() }));
  },
  renderTemplate,
});
