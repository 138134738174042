import { createAsyncThunk } from '@reduxjs/toolkit';

import { getBankRegions } from 'api';
import { getApiBankRegionsData } from 'api/adapters';
import { selectApiConfig } from 'common/redux/runtime/selectors';

/**
 * Функция загрузки списка регионов.
 */
export const fetchBankRegions = createAsyncThunk(
  'widgets/fetchBankRegions',
  async (_props, { getState }) => {
    const apiConfig = selectApiConfig(getState() as AppState);

    const { data, error } = await getBankRegions(apiConfig);

    if (error || !data) {
      throw error || new Error('Ошибка при получении регионов для банков');
    }

    const bankRegions = getApiBankRegionsData(data);

    return bankRegions.sort((regionA, regionB) => {
      if (regionA.name > regionB.name) {
        return 1;
      }

      if (regionA.name < regionB.name) {
        return -1;
      }

      return 0;
    });
  },
);
