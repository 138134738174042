import React, { useEffect, useRef } from 'react';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { GigachatIcon, HandEmojiIcon } from 'icons';

import s from './styles.module.css';

type ShortSummaryPropsType = {
  children: React.ReactNode;
};

/**
 * Компонент с коротким описанием кластера
 * @param props - пропсы
 * @param props.children - дочерний элемент.
 */
const ShortSummaryComponent = function ShortSummary({
  children,
}: ShortSummaryPropsType) {
  const summaryRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const linkElements = summaryRef.current?.getElementsByTagName('a')
      ? Array.from(summaryRef.current?.getElementsByTagName('a'))
      : [];

    // через scrollIntoView тк scroll-behavior: smooth не должно быть на странице, иначе при закрытии попапов страница каждый раз скролится NEWS-12023
    const scrollToElement = (event: Event) => {
      event.preventDefault();

      const id = (event.target as HTMLAnchorElement).hash.slice(1);
      const element = document.getElementById(id);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.error(`Элемент с id ${id} не найден.`);
      }
    };

    linkElements.forEach((linkElement) => {
      linkElement.addEventListener('click', scrollToElement);
    });

    return () => {
      linkElements.forEach((linkElement) => {
        linkElement.removeEventListener('click', scrollToElement);
      });
    };
  }, []);

  return (
    <div className={s.root} ref={summaryRef}>
      <h3 className={s.title}>
        <HandEmojiIcon className={s.emoji} />
        Коротко
        <GigachatIcon className={s.gigachat} />
      </h3>
      <p className={s.text}>{children}</p>
    </div>
  );
};

export const ShortSummary = withErrorBoundary(ShortSummaryComponent);
