import { useEffect, useRef } from 'react';
import { shallowEqual } from 'react-redux';

import {
  selectDomainConfig,
  selectIsMobile,
  selectProjectAlias,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import { useAppSelector } from 'store/hooks';
import { addSourceLinkToClipboard } from 'utils/modifyClipboardOnCopy';

/**
 * Вешает на ref copy-ивент и добавляет в буфер обмена ссылку на источник новости при копировании текста больше 30 символов
 * @param cluster - объект данных о кластере
 * @param topicAlias - alias топика
 */
export const useCopyModifier = <T extends HTMLElement>(
  cluster: Cluster | undefined,
  topicAlias?: string,
) => {
  const ref = useRef<T | null>(null);

  const domainConfig = useAppSelector(selectDomainConfig, shallowEqual);
  const projectId = useAppSelector(selectProjectId);
  const projectAlias = useAppSelector(selectProjectAlias);
  const isMobile = useAppSelector(selectIsMobile);

  useEffect(() => {
    if (cluster) {
      addSourceLinkToClipboard({
        listenOn: ref.current,
        domainConfig,
        cluster,
        topicAlias,
        projectId,
        projectAlias,
        isMobile,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ref;
};
