import NukaCarousel, { ControlProps } from 'nuka-carousel';
import React, { memo, useCallback, useState } from 'react';

import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';

import { getSlidesConfigListDesktop } from '../getSlidesConfigListDesktop';
import { useSwipeDesktop } from '../hooks';
import { RecommendedClustersType } from '../typings';
import { getInitialIndex } from '../utils';

import { CarouselButton } from './components/CarouselButton';
import { CarouselLink } from './components/CarouselLink';

import s from './styles.module.css';

const nukaCarouselConfig = {
  dragging: false,
  enableKeyboardControls: true,
  speed: 200,
};

type CarouselDesktopPropsType = {
  cluster: Cluster;
  clusterIndex: number;
  clusterPuids: PuidsType | undefined;
  recommendedClusters: RecommendedClustersType;
  shouldDisableAds?: boolean;
};

/**
 * Десктопная карусель.
 * @param props - пропсы
 * @param props.cluster - кластер;
 * @param props.clusterIndex - индекс кластера;
 * @param props.clusterPuids - рекламные пуиды кластер;
 * @param props.recommendedClusters - рекомендательные кластера;
 * @param props.shouldDisableAds - флаг для отключения рекламы в галере.
 */
const CarouselDesktopComponent = function CarouselDesktop({
  cluster,
  clusterIndex,
  clusterPuids,
  recommendedClusters,
  shouldDisableAds,
}: CarouselDesktopPropsType) {
  // Генерация слайдов для карусели
  const slidesConfigList = getSlidesConfigListDesktop({
    cluster,
    clusterPuids,
    recommendedClusters,
    bannersVisible: !shouldDisableAds,
  });

  // Вычисление начального слайда для отображения по хэшу в урле
  const initialIndex = getInitialIndex({ clusterIndex, slidesConfigList });

  const [slideIndex, setSlideIndex] = useState<number>(initialIndex);

  const top100AttributeBackButton = useTop100AttributeWithValue(
    `picture_${slideIndex - 1}::before`,
  );
  const top100AttributeForwardButton = useTop100AttributeWithValue(
    `picture_${slideIndex + 1}::next`,
  );
  const top100AttributeMoreLink = useTop100AttributeWithValue('more_link');

  // Получение обработчика свайпа
  const { onSwiped } = useSwipeDesktop({ slidesConfigList, setSlideIndex });

  const renderCenterLeftControls = useCallback(
    (controlProps: ControlProps) => {
      const { currentSlide, previousSlide } = controlProps;

      if (currentSlide === 0) {
        return null;
      }

      return (
        <CarouselButton
          className={s.backButton}
          direction="back"
          callbackSlide={previousSlide}
          onSwiped={onSwiped}
          aria-label="Предыдущий слайд"
          top100Attribute={top100AttributeBackButton}
        />
      );
    },
    [onSwiped, top100AttributeBackButton],
  );

  const renderCenterRightControls = useCallback(
    (controlProps: ControlProps) => {
      const { currentSlide, nextSlide, slideCount } = controlProps;

      const lastSlide = slideCount - 1;

      if (currentSlide === lastSlide) {
        return (
          <CarouselLink
            className={s.moreLink}
            recommendedClusters={recommendedClusters}
            top100Attribute={top100AttributeMoreLink}
          />
        );
      }

      return (
        <CarouselButton
          className={s.forwardButton}
          direction="forward"
          callbackSlide={nextSlide}
          onSwiped={onSwiped}
          aria-label="Следующий слайд"
          top100Attribute={top100AttributeForwardButton}
        />
      );
    },
    [
      onSwiped,
      top100AttributeForwardButton,
      recommendedClusters,
      top100AttributeMoreLink,
    ],
  );

  return (
    <NukaCarousel
      slideIndex={slideIndex}
      renderCenterLeftControls={renderCenterLeftControls}
      renderCenterRightControls={renderCenterRightControls}
      renderBottomCenterControls={null}
      {...nukaCarouselConfig}
    >
      {slidesConfigList?.map(({ component }) => component)}
    </NukaCarousel>
  );
};

export const CarouselDesktop = memo(CarouselDesktopComponent);
