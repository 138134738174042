import { createAsyncThunk } from '@reduxjs/toolkit';

import { getIndicesAndQuotes } from 'api';
import { selectApiConfig } from 'common/redux/runtime/selectors';

import { generateDateConfig, ONE_DAY } from '../../currencies/config';
import {
  IndicesAndQuotesMarketsName,
  IndicesAndQuotesPeriod,
} from '../indicesAndQuotesWidget/typings';

import { ExchangeRateDynamicsCurrency } from './typings';

const TIME_END = 60 * 60 * 23 + 60 * 60 * 59;

/**
 * Функция загрузки данных для виджета Динамика курса EUR/USD.
 */
export const fetchExchangeRateDynamics = createAsyncThunk(
  'widgets/fetchExchangeRateDynamics',
  async (_props, { getState }) => {
    const apiConfig = selectApiConfig(getState() as AppState);
    const src = IndicesAndQuotesMarketsName.forex;
    const period = IndicesAndQuotesPeriod.D1;
    const charCode = ExchangeRateDynamicsCurrency.EURUSD;
    const { todayStart } = generateDateConfig();
    const end = todayStart / 1000 + TIME_END;
    const start = (todayStart - ONE_DAY * 30) / 1000;

    const { data, error } = await getIndicesAndQuotes({
      apiConfig,
      src,
      period,
      charCode,
      limit: 30,
      start,
      end,
    });

    if (error || !data) {
      throw error;
    }

    const currentValue = data[data.length - 1].rate;
    const previousValue = data[data.length - 2].rate;
    const diff = currentValue - previousValue;

    const { minimumOfRate, maximumOfRate } = (
      data as APICurrencyRateType[]
    ).reduce(
      (acc, item) => {
        const extremum = { ...acc };

        if (typeof item.rate !== 'number') {
          return extremum;
        }

        if (item.rate < extremum.minimumOfRate.rate) {
          extremum.minimumOfRate = item;
        }

        if (item.rate > extremum.maximumOfRate.rate) {
          extremum.maximumOfRate = item;
        }

        return extremum;
      },
      { minimumOfRate: data[0], maximumOfRate: data[0] },
    );

    return { data, diff, minimumOfRate, maximumOfRate };
  },
);
