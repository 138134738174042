import cn from 'classnames';
import React, {
  MutableRefObject,
  SyntheticEvent,
  useCallback,
  useState,
} from 'react';

import { TagLink } from 'common/components/TagList/components/TagLink';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { selectIsHumanCenteredStrategyStateActivated } from 'common/redux/runtime/selectors';
import { ArrowDownIcon } from 'icons';
import { useAppSelector } from 'store/hooks';

import s from './styles.module.css';

type TagListLayoutPropsType = {
  markers: MarkerType[];
  outerTagWrapperRef: MutableRefObject<HTMLDivElement | null>;
  isTagsButtonVisible: boolean;
  innerTagWrapperRef: MutableRefObject<HTMLDivElement | null>;
  clusterId: Cluster['id'];
  styles?: StylesType;
};

enum LABEL_FOR_INPUT {
  unchecked = 'Развернуть список тегов',
  checked = 'Свернуть список тегов',
}

/**
 * Шаблон тег-листа с разворачиванием/сворачиванием тегов, содержит только вёрстку, без функциональной части
 * @param props - пропсы
 * @param props.markers - массив тегов;
 * @param props.styles - стили;
 * @param props.outerTagWrapperRef - ref внешней обертки над тегами, нужен для хука снаружи;
 * @param props.isTagsButtonVisible - видна ли кнопка разворота тегов;
 * @param props.innerTagWrapperRef - ref внутренней обертки обертки над тегами, нужен для хука снаружи;
 * @param props.clusterId - id кластера для передачи в label и input.
 */

const TagListLayoutComponent = function TagListLayout({
  markers,
  styles = {},
  outerTagWrapperRef,
  isTagsButtonVisible,
  innerTagWrapperRef,
  clusterId,
}: TagListLayoutPropsType) {
  const [ariaLabel, setAriaLabel] = useState(LABEL_FOR_INPUT.unchecked);
  const top100Attribute = useTop100AttributeWithValue('tags_type');
  const forceRedesign = useAppSelector(
    selectIsHumanCenteredStrategyStateActivated,
  );

  const onToggleAriaLabel = useCallback((event: SyntheticEvent) => {
    if ((event.target as HTMLInputElement).checked) {
      setAriaLabel(LABEL_FOR_INPUT.checked);

      return;
    }

    setAriaLabel(LABEL_FOR_INPUT.unchecked);
  }, []);

  return (
    <div
      className={cn(s.container, styles.container, forceRedesign && s.redesign)}
    >
      {isTagsButtonVisible && (
        <>
          <input
            id={clusterId}
            onClick={onToggleAriaLabel}
            name="tagsButton"
            type="checkbox"
            className={cn(s.tagsButton, styles.tagsButton)}
          />
          <label
            htmlFor={clusterId}
            aria-label={ariaLabel}
            className={cn(
              s.tagsButtonIconWrapper,
              styles.tagsButtonIconWrapper,
            )}
          >
            <ArrowDownIcon className={cn(s.icon, styles.icon)} />
          </label>
        </>
      )}
      <div
        ref={outerTagWrapperRef}
        className={cn(s.tagsContainer, styles.tagsContainer)}
      >
        <div ref={innerTagWrapperRef} className={s.tagInner}>
          {markers
            .filter((item) => item.name)
            .map(({ type, id, alias, name }, index) => (
              <div
                key={`${id}-${alias}`}
                className={cn(s.tagWrapper, styles.tagWrapper)}
                {...top100Attribute}
              >
                <TagLink
                  type={type}
                  id={id}
                  alias={alias}
                  name={name}
                  position={index + 1}
                  styles={styles}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export const TagListLayout = withErrorBoundary(TagListLayoutComponent);
