import { Organization, Distance, ImageObject, Person } from 'schema-dts';

import {
  IMAGE_WIDTH,
  IMAGE_HEIGHTS,
} from 'common/components/schemaOrg/constants';
import { LOGOS_WIDTH } from 'config/common/project/constants';
import { RAMBLER_RESOURCES_IDS } from 'config/common/ramblerResourcesIds';

type GetOrganizationType = (props: {
  domain: string;
  projectId: ProjectType['id'];
  projectName: ProjectType['name'];
  projectAlias: ProjectType['alias'];
}) => Organization;

/**
 * Получение publisher кластера для schema.org
 * @param props - пропсы
 * @param props.domain - доменное имя
 * @param props.projectId - id проекта
 * @param props.projectName - имя проекта ("Рамблер/новости", "Рамблер/авто", ...)
 * @param props.projectAlias - alias проекта
 * @returns блок schema.org описывающий автора кластера
 */
export const getOrganization: GetOrganizationType = ({
  domain,
  projectId,
  projectName,
  projectAlias,
}) => ({
  '@type': 'Organization',
  name: projectName,
  logo: {
    '@type': 'ImageObject',
    url: `${domain}/static/mobile/i/schema-logo/${projectAlias}.png`,
    // Distance has incorrect type in 'schema-dts'
    width: LOGOS_WIDTH[projectId] as unknown as Distance,
    height: 60 as unknown as Distance,
  },
});

type GetAuthorType = (props: {
  domain: string;
  projectName: ProjectType['name'];
  resource: Optional<ClusterResourceType>;
  editor: Optional<EditorType> | undefined;
}) => Organization | Person;

/**
 * Получение автора кластера для schema.org
 * @param props - пропсы
 * @param props.domain - доменное имя
 * @param props.projectName - название проекта
 * @param props.resource - для определения источника кластера
 * @param props.editor - инфо об авторах, чтобы найти автора нужного кластера
 * @returns блок schema.org описывающий автора кластера, включает в себя блок для удобства разделения аналитики (агрегатор/рамблер)
 */
export const getAuthor: GetAuthorType = ({
  domain,
  projectName,
  resource,
  editor,
}) => {
  const isRamblerResource = RAMBLER_RESOURCES_IDS.includes(resource?.id ?? -1);

  if (isRamblerResource && editor && editor.name) {
    return {
      '@type': 'Person',
      name: editor.name,
      url: `${domain}/editors/${editor.alias}/`,
    };
  }

  return {
    '@type': 'Organization',
    name: isRamblerResource ? projectName : resource?.title || '',
  };
};

/**
 * Получение ImageObject для schema.org
 * @param props - пропсы
 * @param props.imgUrls - массив url изображений
 * @param props.name - длинный заголовок кластера
 * @param props.description - описание слайда или длинный заголовок кластера
 * @param props.authorThing - автор с типом Thing
 * @param props.isMain - указывает что слайд главный на странице
 * @param props.imageSource - ресурс изображения
 * @returns блок schema.org описывающий ImageObject
 */
export const getImageObject = ({
  imgUrls,
  name,
  description,
  authorThing,
  isMain = true,
  imageSource,
}: {
  imgUrls: string[];
  name?: string;
  description?: string;
  authorThing?: string;
  isMain?: boolean;
  imageSource?: ClusterImageType['source']['title'];
}): ImageObject[] =>
  IMAGE_HEIGHTS.map(
    // @ts-expect-error: ¯\_(ツ)_/¯
    (cropHeight: number, index: number): ImageObject => ({
      '@type': 'ImageObject',
      name,
      representativeOfPage: isMain,
      url: imgUrls?.[index] || '',
      width: `${IMAGE_WIDTH}`,
      height: `${cropHeight}`,
      contentUrl: imgUrls?.[index] || '',
      description,
      ...(authorThing
        ? {
            author: {
              '@type': 'Thing',
              name: authorThing,
            },
          }
        : {}),
      ...(imageSource && !authorThing
        ? {
            author: {
              '@type': 'Organization',
              name: imageSource,
            },
          }
        : {}),
    }),
  );
