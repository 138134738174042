import cn from 'classnames';
import React from 'react';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';

import s from './styles.module.css';

type ScreenshotPropsType = {
  src: string;
  alt?: string;
  style?: React.CSSProperties;
  className?: string;
};

/**
 * Компонент-обертка для вставки скриншота в s2s
 * @param props - пропсы
 * @param props.src - ссылка на картинку;
 * @param props.alt - подпись для картинки;
 * @param props.style - кастомные стили для картинки;
 * @param props.className - кастомный класс для картинки.
 */
const ScreenshotComponent = function Screenshot({
  src,
  alt = '',
  style,
  className = '',
}: ScreenshotPropsType) {
  return (
    <img
      className={cn(s.img, className)}
      style={style}
      src={src}
      alt={alt}
      data-test="s2s-screenshot"
    />
  );
};

export const Screenshot = withErrorBoundary(ScreenshotComponent);
