import { selectClusterById } from 'common/redux/commonData/entries/selectors';
import { selectEditorsIds } from 'common/redux/pages/editors/selectors';

import { selectCommonData } from '../selectors';

import { editorsAdapter } from './adapter';

const selectors = editorsAdapter.getSelectors(
  (state: AppState) => selectCommonData(state).editors,
);

export const selectEditors = (state: AppState) =>
  selectCommonData(state).editors;

export const selectEditorsInfo = (state: AppState) =>
  Object.values(selectEditors(state).entities).sort((a, b) => {
    if (!a || !b) return 0;

    const nameA = a.name.toLocaleLowerCase();
    const nameB = b.name.toLocaleLowerCase();

    if (nameA > nameB) return 1;
    if (nameA < nameB) return -1;

    return 0;
  });

export const selectEditorsAliasToId = (state: AppState) =>
  selectEditors(state).aliasToId;

export const selectEditorById =
  (editorId: ClusterResourceType['id'] | undefined | null) =>
  (state: AppState) =>
    typeof editorId === 'number'
      ? selectors.selectById(state, editorId)
      : undefined;

export const selectEditorsActive = (state: AppState) => {
  const editorsIds = selectEditorsIds(state);

  return editorsIds
    .map((editorId) => selectors.selectById(state, editorId))
    .filter(Boolean) as FullEditorType[];
};

// Редактора у кластера может и не быть
export const selectClusterEditor =
  (clusterId: Cluster['id'] | null) => (state: AppState) =>
    selectEditorById(selectClusterById(clusterId)(state)?.editorId)(state);

export const selectEditorIdByAlias =
  (editorAlias: EditorType['alias']) => (state: AppState) =>
    selectEditorsAliasToId(state)[editorAlias];

export const selectEditorByAlias =
  (editorAlias: EditorType['alias']) => (state: AppState) =>
    selectEditorById(selectEditorIdByAlias(editorAlias)(state))(state);

export const selectEditorId =
  (editorAlias: EditorType['alias']) => (state: AppState) =>
    selectEditorByAlias(editorAlias)(state)?.id;

export const selectEditorsLoadingState = (state: AppState) =>
  selectEditors(state).fetching;
