import { CURRENCY_CHAR_CODE } from 'config/common/finance/typings';

export type DefaultCrossDataType = {
  char_code: CROSS_RATE;
  descr: string;
  id: number;
  instrument_type: 'crossrate';
};

export type CrossDataType = {
  charCode: CROSS_RATE;
  description: string;
};

export type CrossRateType = {
  [key in CURRENCY_CHAR_CODE]: CurrencyType['charCode'][];
};

export type CrossDescriptionType = {
  [key in CROSS_RATE]?: { description: string };
};

export enum CROSS_RATE {
  AUDCAD = 'AUDCAD',
  AUDCHF = 'AUDCHF',
  AUDJPY = 'AUDJPY',
  AUDNZD = 'AUDNZD',
  AUDUSD = 'AUDUSD',
  CADCHF = 'CADCHF',
  CADJPY = 'CADJPY',
  CHFJPY = 'CHFJPY',
  CHFSGD = 'CHFSGD',
  EURAUD = 'EURAUD',
  EURCAD = 'EURCAD',
  EURCHF = 'EURCHF',
  EURGBP = 'EURGBP',
  EURJPY = 'EURJPY',
  EURMXN = 'EURMXN',
  EURNOK = 'EURNOK',
  EURNZD = 'EURNZD',
  EURSEK = 'EURSEK',
  EURSGD = 'EURSGD',
  EURUSD = 'EURUSD',
  GBPAUD = 'GBPAUD',
  GBPCAD = 'GBPCAD',
  GBPCHF = 'GBPCHF',
  GBPJPY = 'GBPJPY',
  GBPNZD = 'GBPNZD',
  GBPSEK = 'GBPSEK',
  GBPUSD = 'GBPUSD',
  NOKJPY = 'NOKJPY',
  NZDCAD = 'NZDCAD',
  NZDCHF = 'NZDCHF',
  NZDJPY = 'NZDJPY',
  NZDUSD = 'NZDUSD',
  SGDJPY = 'SGDJPY',
  USDCAD = 'USDCAD',
  USDCHF = 'USDCHF',
  USDDKK = 'USDDKK',
  USDJPY = 'USDJPY',
  USDMXN = 'USDMXN',
  USDNOK = 'USDNOK',
  USDSEK = 'USDSEK',
  USDSGD = 'USDSGD',
}
