import React from 'react';
import { ImageObject } from 'schema-dts';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { JsonLd } from 'utils/jsond';

import { getGetImageSrc } from '../../utils';
import { getItemForImageObject } from '../utils';

type ClusterImageObjectPropsType = {
  cluster: Cluster;
};

/**
 * Получение ImageObject schema.org для картинки кластера
 * @param props - пропсы
 * @param props.cluster - инфо о кластере.
 * @returns блок schema.org описывающий ImageObject
 */
const ClusterImageObjectComponent = function ClusterImageObject({
  cluster,
}: ClusterImageObjectPropsType) {
  const { image, longTitle, publicationTime } = cluster;
  const url = image?.url || '';
  const s3 = image?.s3 || false;
  const imageSource = image?.source?.title || '';
  const pageImages = getGetImageSrc(url, s3);

  const item = getItemForImageObject({
    imgUrls: pageImages,
    name: longTitle,
    description: longTitle,
    imageSource,
  });

  if (!!publicationTime && new Date(publicationTime) > new Date()) return null;

  return <JsonLd<ImageObject> item={item} />;
};

export const ClusterImageObject = withErrorBoundary(
  ClusterImageObjectComponent,
);
