import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { CURRENCY_CHAR_CODE } from 'config/common/finance/typings';
import { PROJECT_ALIASES } from 'config/common/project/typings';
import { PAGE_TYPE } from 'config/common/router/typings';

type ConverterState = {
  firstCurrency: CurrencyType['charCode'];
  firstCurrencyConverter: CurrencyType['charCode'];
  secondCurrency: CurrencyType['charCode'];
  secondCurrencyConverter: CurrencyType['charCode'];
  amount: number | undefined;
  crossCourse: number | undefined;
  fixedValue: number | undefined;
  puids: PuidsType;
  isLoading: boolean;
};

const initialState: ConverterState = {
  // Первая валюта, если она уже указана на странице.
  firstCurrency: CURRENCY_CHAR_CODE.RUB,
  // Первая валюта конвертера для расчета в форме.
  firstCurrencyConverter: CURRENCY_CHAR_CODE.RUB,
  // Вторая валюта, если она уже указана на странице.
  secondCurrency: CURRENCY_CHAR_CODE.USD,
  // Вторая валюта конвертера для расчета в форме.
  secondCurrencyConverter: CURRENCY_CHAR_CODE.USD,
  // Количество конвертируемых денег.
  amount: undefined,
  // Рассчитанный кросс-курс для валют.
  crossCourse: undefined,
  // Рассчитанное по кросс-курсу количество знаков после запятой.
  fixedValue: undefined,
  // Рекламные пуиды
  puids: {},
  // Флаг загрузки
  isLoading: false,
};

type ActionCurrenciesType = PayloadAction<{
  // Первая валюта из пары конвертера
  firstCurrency: CurrencyType['charCode'];
  // Вторая валюта из пары конвертера
  secondCurrency: CurrencyType['charCode'];
  // Количество денег для конвертации
  amount: number | undefined;
  // Кросс-курс
  crossCourse: number | undefined;
  // Расчитанное количество знаков после запятой
  fixedValue: number | undefined;
  firstCurrencyConverter?: CurrencyType['charCode'];
  secondCurrencyConverter?: CurrencyType['charCode'];
}>;

const converterSlice = createSlice({
  name: PAGE_TYPE.converter,
  initialState,
  reducers: {
    /**
     * Установка валют для данной страницы.
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload - данные для инициализации страницы;
     */
    setCurrencies(state, { payload }: ActionCurrenciesType) {
      if (!payload) return;

      const {
        amount,
        firstCurrency,
        secondCurrency,
        crossCourse,
        fixedValue,
        firstCurrencyConverter,
        secondCurrencyConverter,
      } = payload;

      state.amount = amount;
      state.firstCurrency = firstCurrency;
      state.firstCurrencyConverter = firstCurrencyConverter || firstCurrency;
      state.secondCurrency = secondCurrency;
      state.secondCurrencyConverter = secondCurrencyConverter || secondCurrency;
      state.crossCourse = crossCourse;
      state.fixedValue = fixedValue;
    },

    /**
     * Установка первой валюты.
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload - первая валюта из пары конвертера.
     */
    setFirstCurrency(
      state,
      { payload }: PayloadAction<CurrencyType['charCode']>,
    ) {
      state.firstCurrency = payload;
    },

    /**
     * Установка первой валюты конвертера для расчета в форме.
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload - первая валюта из пары конвертера.
     */
    setFirstCurrencyConverter(
      state,
      { payload }: PayloadAction<CurrencyType['charCode']>,
    ) {
      state.firstCurrencyConverter = payload;
    },

    /**
     * Установка второй валюты.
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload - вторая валюта из пары конвертера.
     */
    setSecondCurrency(
      state,
      { payload }: PayloadAction<CurrencyType['charCode']>,
    ) {
      state.secondCurrency = payload;
    },

    /**
     * Установка второй валюты конвертера для расчета в форме.
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload - вторая валюта из пары конвертера.
     */
    setSecondCurrencyConverter(
      state,
      { payload }: PayloadAction<CurrencyType['charCode']>,
    ) {
      state.secondCurrencyConverter = payload;
    },

    /**
     * Установка кросс-курса
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload - кросс-курс.
     */
    setCrossCourse(state, { payload }: PayloadAction<number>) {
      state.crossCourse = payload;
    },

    /**
     * Генерация puids для страницы конвертера
     * @param state - состояние слайса
     */
    setConverterPuids(state) {
      const puidName = getPuidName(PROJECT_ALIASES.Finance);

      state.puids = {
        puid6: `${puidName}_currencies`.toUpperCase(),
        puid18: `${puidName}_currencies_converter`.toUpperCase(),
      };
    },

    /**
     * Установка страницы конвертера в состояние загрузки. Нужно для отображения лодера при spa переходов
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload - флаг загрузки.
     */
    setIsLoading(state, { payload: isLoading }) {
      state.isLoading = isLoading;
    },
  },
});

export const converterReducer = converterSlice.reducer;
export const {
  setCurrencies,
  setFirstCurrency,
  setFirstCurrencyConverter,
  setSecondCurrency,
  setSecondCurrencyConverter,
  setCrossCourse,
  setConverterPuids,
  setIsLoading,
} = converterSlice.actions;
