import { useCallback, useEffect, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { incrementReloadKey } from 'common/redux/appController';
import { selectPageName } from 'common/redux/appController/selectors';
import { selectRuntime } from 'common/redux/runtime/selectors';
import { incrementCounters } from 'utils/counters/init/client';

const TIME_FOR_RELOAD_AD_AND_COUNTER = 120;
const TIME_FOR_RELOAD_ALL_PAGE_NEW = 1800;

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

/**
 * Функция чтобы при смене страницы позиция скролла менялась на начало
 */
const handleRefreshAllPage = () => {
  const { origin, pathname, search, hash } = document.location;
  const isSlashEnd = pathname[pathname.length - 1] === '/';
  const link = origin + (isSlashEnd ? pathname : `${pathname}/`) + search;

  if (window.history && window.history.replaceState) {
    const url = `${link}${hash}`;
    const stateData = { path: url, scrollTop: 0 };

    window.history.replaceState(stateData, 'title', url);
    scrollToTop();
    document.location.reload();
  } else {
    scrollToTop();
    document.location.href = `${link}${hash}`;
  }
};

/**
 * Хук для авторефреша
 */
export const useAutoRefresh = () => {
  const dispatch = useDispatch();

  const runtime = useSelector(selectRuntime, shallowEqual);
  const pageName = useSelector(selectPageName);

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const leaveTimerRef = useRef(0);

  const startTimer = useCallback(() => {
    timerRef.current = setInterval(() => {
      leaveTimerRef.current += 1;
    }, 1000);
  }, []);

  const handleRefreshAdAndCounter = useCallback(() => {
    dispatch(incrementReloadKey());
    incrementCounters({ runtime, pageName });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runtime, pageName]);

  const handleAutoRefresh = useCallback(() => {
    if (document.visibilityState === 'hidden') {
      startTimer();

      return;
    }

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    if (
      leaveTimerRef.current >= TIME_FOR_RELOAD_AD_AND_COUNTER &&
      leaveTimerRef.current < TIME_FOR_RELOAD_ALL_PAGE_NEW
    ) {
      handleRefreshAdAndCounter();
    }

    if (leaveTimerRef.current >= TIME_FOR_RELOAD_ALL_PAGE_NEW) {
      handleRefreshAllPage();
    }

    leaveTimerRef.current = 0;
  }, [startTimer, handleRefreshAdAndCounter]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleAutoRefresh);

    return () => {
      document.removeEventListener('visibilitychange', handleAutoRefresh);
    };
  }, [handleAutoRefresh]);
};
