import { selectCardById } from 'common/redux/commonData/entries/selectors';

import { selectPages } from '../selectors';

export const selectClusterItems = (state: AppState) =>
  selectPages(state).clusterItems;

export const selectClusterItemsPuids = (state: AppState) =>
  selectClusterItems(state).puids;

export const selectClusterItemsCluster = (state: AppState) =>
  selectClusterItems(state).cluster;

export const selectClusterItemsClustersIds = (state: AppState) =>
  selectClusterItems(state).clustersIds;

export const selectClusterItemsFetchingState = (state: AppState) =>
  selectClusterItems(state).fetching;

export const selectClusterItemsErrorState = (state: AppState) =>
  selectClusterItems(state).error;

export const selectClusterItemsClusters = (state: AppState) =>
  selectClusterItemsClustersIds(state).map((clusterId) =>
    selectCardById(clusterId)(state),
  ) || [];
