import { createSlice } from '@reduxjs/toolkit';

import { fetchHoroscopeName } from './asyncs';

export enum STATUS_TYPE {
  pending = 'pending',
  fulfilled = 'fulfilled',
  rejected = 'rejected',
}

type HoroscopeNameWidgetType = {
  status: STATUS_TYPE;
  title: string;
  text: string;
  urlPath: string;
} & InitialState;

/**
 * Слайс виджета Что значит имя.
 */
const horoscopeNameSlice = createSlice({
  name: 'horoscopeName',
  initialState: {
    status: STATUS_TYPE.pending,
    title: '',
    text: '',
    urlPath: '',
    fetching: false,
    error: '',
  } as HoroscopeNameWidgetType,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchHoroscopeName.pending, (state) => {
      state.fetching = true;
      state.error = '';
    });
    builder.addCase(fetchHoroscopeName.fulfilled, (state, { payload }) => {
      state.status = STATUS_TYPE.fulfilled;
      state.title = payload.title;
      state.text = payload.text;
      state.urlPath = payload.url_path;
      state.fetching = false;
      state.error = '';
    });
    builder.addCase(fetchHoroscopeName.rejected, (state, { error }) => {
      state.fetching = false;
      state.error = error.message;
    });
  },
});

export const horoscopeNameReducer = horoscopeNameSlice.reducer;
