import cn from 'classnames';
import React from 'react';

import { MetaMaxVideoPreview } from 'common/components/MetaMaxVideoPreview';
import { VideoPlayer } from 'common/components/VideoPlayer';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';

import s from './styles.module.css';

type VideoAdIframePropsType = {
  embedCode: string;
  className?: string;
  puids: PuidsType | undefined;
  clusterId: Card['id'];
  clusterUrl: string;
};

/**
 * Компонент вставки рекламного видео
 * @param props - пропсы
 * @param props.embedCode - код вставки рекламного видео;
 * @param props.className - кастомные классы;
 * @param props.puids - рекламные пуиды;
 * @param props.clusterId - id кластера;
 * @param props.clusterUrl - URL кластера, в который встроен плеер.
 */
const VideoAdIframeComponent = function VideoAdIframe({
  embedCode,
  className,
  puids,
  clusterId,
  clusterUrl,
}: VideoAdIframePropsType) {
  return (
    <div className={cn(s.container, className)}>
      <MetaMaxVideoPreview />
      <div className={s.title} data-test="day-video-title">
        Видео дня
      </div>
      <VideoPlayer
        embedCode={embedCode}
        isBroadcast={false}
        puids={puids}
        clusterId={clusterId}
        clusterUrl={clusterUrl}
        isAd
      />
    </div>
  );
};

export const VideoAdIframe = withErrorBoundary(VideoAdIframeComponent);
