import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { PROJECT_ALIASES } from 'config/common/project/typings';
import { PAGE_TYPE } from 'config/common/router/typings';

import { fetchRegionRecs } from './asyncs';
import { RegionPageType } from './typings';

const similarActions = {
  pending: fetchRegionRecs.pending.type,
  fullfilled: fetchRegionRecs.fulfilled.type,
  rejected: fetchRegionRecs.rejected.type,
};

type StatusTypes = 'pending' | 'fullfilled' | 'rejected';

const matchType = (type: StatusTypes) => (action: PayloadAction) =>
  similarActions[type].includes(action.type);

type ActionRegionIdType = PayloadAction<ATRegion['id'] | undefined>;

const initialState: RegionPageType = {
  regionId: undefined,
  recommendClusterIds: [],
  puids: {},
  hasNextPage: true,
  fetching: false,
  error: '',
};

/**
 * Слайс данных по кластерам
 */
export const regionSlice = createSlice({
  name: PAGE_TYPE.region,
  initialState,
  reducers: {
    /**
     * Экшн для добавления alias региона.
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload - инфо региона с alias
     */
    setRegionId: (state, { payload }: ActionRegionIdType) => {
      state.regionId = payload;
    },

    /**
     * Экшн для добавления пуидов региона.
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload - alias проекта, на котором запускается приложение.
     */
    setRegionPuids: (state, { payload }: PayloadAction<PROJECT_ALIASES>) => {
      const puidName = getPuidName(payload);

      state.puids = {
        puid6: `${puidName}_regions`.toUpperCase(),
        puid18: `${puidName}_regions_all`.toUpperCase(),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchRegionRecs.fulfilled.type,
        (state, { payload }: PayloadAction<Card['id'][]>) => {
          state.fetching = false;

          /**
           * Если уже есть что-нибудь в стейте, то проверяем, пришло ли что-либо от сервера.
           * Если ничего не пришло, то помечаем, что грузить больше нечего.
           */
          state.hasNextPage =
            state.recommendClusterIds.length === 0 || payload.length !== 0;

          state.recommendClusterIds = [
            ...state.recommendClusterIds,
            ...payload,
          ];
        },
      )
      .addMatcher(matchType('pending'), (state) => {
        state.fetching = true;
      })
      .addMatcher(
        matchType('rejected'),
        (state, { error }: { error: Error }) => {
          state.fetching = false;
          state.error = error.message;
        },
      );
  },
});

export const { setRegionId, setRegionPuids } = regionSlice.actions;

export const regionReducer = regionSlice.reducer;

export { fetchRegionRecs };
