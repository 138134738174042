import loadable from '@loadable/component';
import React from 'react';

import { fetchTravelCountries } from 'common/redux/commonData/travelCountries';
import { fetchTravelRegionPlaces } from 'common/redux/commonData/travelRegionPlaces';
import {
  setPageCountryAlias,
  setPageCountryPlaces,
} from 'common/redux/pages/guideCountryPlaces';
import { appendPageDownloader } from 'common/routes/pageLoadable';
import { PAGE_TYPE } from 'config/common/router/typings';

import { IAppRoute } from '../typings';

const GuideCountryPlacesDesktop = loadable(
  () => import('desktop/pages/GuideCountryPlaces'),
);
const GuideCountryPlacesMobile = loadable(
  () => import('mobile/pages/GuideCountryPlaces'),
);

/**
 * Роут Путеводителя по местам одной страны.
 * @see https://travel.rambler.ru/guide/Russia/places/
 * @param isMobile - флаг мобильной версии
 */
export const guideCountryPlacesRoutes = (
  isMobile: Runtime['settings']['isMobile'],
): IAppRoute<{ countryAlias: string }> => ({
  name: PAGE_TYPE.guideCountryPlaces,
  exact: true,
  path: '/guide/:countryAlias([-A-Za-z]+)/places/',
  render: isMobile
    ? () => <GuideCountryPlacesMobile />
    : () => <GuideCountryPlacesDesktop />,
  fetchData: async ({ dispatch }, { params: { countryAlias } }) => {
    const callback = (data: TravelPlaceType[]) => {
      const placesIds = data.map(({ id }) => id);

      dispatch(setPageCountryPlaces(placesIds));
    };

    dispatch(setPageCountryAlias(countryAlias));

    const promiseList: PromiseListType = [
      // Список стран для селекта в виджете
      dispatch(fetchTravelCountries()),
      // Карточки мест текущей страны
      dispatch(
        fetchTravelRegionPlaces({ regionAlias: countryAlias, callback }),
      ),
    ];

    const GuideCountryPlaces = isMobile
      ? GuideCountryPlacesMobile
      : GuideCountryPlacesDesktop;

    await Promise.all(
      appendPageDownloader({
        promiseList,
        loadableComponent: GuideCountryPlaces,
        chunkName: Object.keys({ GuideCountryPlaces })[0],
      }),
    );
  },
});
