export type ExchangeRateDynamicsWidgetType = {
  data: APICurrencyRateType[];
  diff: number | null;
  minimumOfRate: APICurrencyRateType;
  maximumOfRate: APICurrencyRateType;
} & InitialState;

export enum ExchangeRateDynamicsCurrency {
  EURUSD = 'EURUSD',
}
