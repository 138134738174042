import { selectCommonData } from '../selectors';

import { bankRegionCharToIdAdapter, bankRegionsAdapter } from './adapter';
import { BankRegionType } from './typings';

export const selectBankRegionsState = (state: AppState) =>
  selectCommonData(state).bankRegions;

const selector = bankRegionsAdapter.getSelectors();

export const selectBankRegionsData = (state: AppState) =>
  selector.selectAll(selectBankRegionsState(state));

export const selectBankRegionsCount = (state: AppState) =>
  selector.selectTotal(selectBankRegionsState(state));

export const selectBankRegionsDataByRegionId =
  (id: BankRegionType['id']) => (state: AppState) =>
    selector.selectById(selectBankRegionsState(state), id);

export const selectBankRegionsIdByChar =
  bankRegionCharToIdAdapter.createAliasToIdSelector(selectBankRegionsState);

export const selectBankRegionsDataByRegionChar =
  (alias: BankRegionType['alias']) => (state: AppState) =>
    selectBankRegionsDataByRegionId(selectBankRegionsIdByChar(alias)(state))(
      state,
    );
