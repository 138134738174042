import { useEffect, useState } from 'react';

/**
 * Небольшой хук, проверяющий на то, что компонент успешно отрендерился на клиенте.
 */
export const useMounted = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
    setIsMounted(true);
  }, []);

  return isMounted;
};
