import React from 'react';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { StateTopicType } from 'common/redux/commonData/topics/typings';

import { MenuLinks } from '../MenuLinks';

import { useMenuItems } from './useTopicsMenu';

type TopicsMenuPropsType = {
  projectId: ProjectType['id'] | undefined;
  activeTopicId: StateTopicType['id'] | undefined | null;
  top100?: string;
};

/**
 * Компонент с ссылками на разные топики.
 * @param props - пропсы
 * @param props.projectId - id вертикали;
 * @param props.activeTopicId - id топика к которому относится страница;
 * @param props.top100 - значение для топ 100.
 */
const TopicsMenuComponent = function TopicsMenu({
  projectId,
  activeTopicId,
  top100,
}: TopicsMenuPropsType) {
  const top100Value = top100 || 'topics_menu';
  const menuItems = useMenuItems(projectId, activeTopicId);

  return <MenuLinks menuItems={menuItems} top100Value={top100Value} />;
};

export const TopicsMenu = withErrorBoundary(TopicsMenuComponent);
