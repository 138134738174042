import React from 'react';
import { Organization } from 'schema-dts';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { JsonLd } from 'utils/jsond';

import { useItemForOrganization } from './hooks';

/**
 * Инфо о организации для schemaOrg
 */
const OrganizationInfoSchemaComponent = function OrganizationInfoSchema() {
  const item = useItemForOrganization();

  return <JsonLd<Organization> item={item} />;
};

export const OrganizationInfoSchema = withErrorBoundary(
  OrganizationInfoSchemaComponent,
);
