import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { BreadcrumbList, NewsArticle } from 'schema-dts';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { selectTopicById } from 'common/redux/commonData/topics/selectors';
import { JsonLd } from 'utils/jsond';

import { useItemForBreadcrumbList, useItemForNewsArticle } from './hooks';

type NewsArticleSchemaPropsType = {
  cluster: Cluster | undefined;
};

/**
 * Разметка schemaOrg для кластера.
 * @param props - пропсы
 * @param props.cluster - объект кластера.
 */
const NewsArticleSchemaComponent = function NewsArticleSchema({
  cluster,
}: NewsArticleSchemaPropsType) {
  const topic = useSelector(
    selectTopicById(cluster?.mainTopicId),
    shallowEqual,
  );

  const itemForNewsArticle = useItemForNewsArticle(cluster);
  const itemForBreadcrumbList = useItemForBreadcrumbList(cluster?.mainTopicId);

  return (
    <>
      <JsonLd<NewsArticle> item={itemForNewsArticle} />
      {!!topic && <JsonLd<BreadcrumbList> item={itemForBreadcrumbList} />}
    </>
  );
};

export const NewsArticleSchema = withErrorBoundary(NewsArticleSchemaComponent);
