import cn from 'classnames';
import React, { memo } from 'react';

import { Ad } from 'common/components/Ad';
import { selectIsHumanCenteredStrategyStateActivated } from 'common/redux/runtime/selectors';
import { BANNERS } from 'config/common/banners/typings';
import { useAppSelector } from 'store/hooks';

type CarouselSlideBannerPropsType = {
  puids: PuidsType | undefined;
  styles: StylesType;
};

/**
 * Слайд с баннером.
 * @param props - пропсы
 * @param props.puids - объект рекламных пуидов;
 * @param props.styles - стили компонента.
 */
const CarouselSlideBannerComponent = function CarouselSlideBanner({
  puids,
  styles,
}: CarouselSlideBannerPropsType) {
  const forceRedesign = useAppSelector(
    selectIsHumanCenteredStrategyStateActivated,
  );

  return (
    <>
      <div
        className={cn(
          styles.bannerWrapper,
          forceRedesign && styles.bannerWrapperRedesign,
        )}
      >
        <Ad
          name={BANNERS.Ingallery}
          puids={puids}
          className={styles.ad}
          withoutMarginBottom
        />
      </div>
      <p className={styles.bannerText}>
        Листайте дальше, чтобы пропустить рекламу
      </p>
    </>
  );
};

export const CarouselSlideBanner = memo(CarouselSlideBannerComponent);
