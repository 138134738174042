import React, { useId } from 'react';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';

import { useLoadRamblerEmbed } from '../hooks/useLoadRamblerEmbed';

import s from './styles.module.css';

const PARENT_ID = 'rambler-info-banner-container';
const WIDGET_ID = 'rambler-info-banner';

type RamblerInfoBannerPropsType = {
  bannerId: string;
};

/**
 * Компонент с ембедом Рамблера - CPA-элемент.
 * @param props - пропсы
 * @param props.bannerId - id баннера
 */
const RamblerInfoBannerComponent = function RamblerInfoBanner({
  bannerId,
}: RamblerInfoBannerPropsType) {
  const id = useId();

  const { embedRef, isInited } = useLoadRamblerEmbed({
    parentId: `${PARENT_ID}${id}`,
    widgetId: `${WIDGET_ID}${id}`,
    url: 'https://embeds.rambler.ru/cpa-elements/info-banner/sdk.js',
    initOnReady: true,
  });

  return (
    <div id={`${PARENT_ID}${id}`} className={isInited ? '' : s.placeholder}>
      <rambler-info-banner ref={embedRef} banner-id={bannerId} />
    </div>
  );
};

export const RamblerInfoBanner = withErrorBoundary(RamblerInfoBannerComponent);
