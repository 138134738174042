import { FIRST_SLIDE_INDEX, MAX_RECOMMENDED_CLUSTERS_COUNT } from './constants';
import {
  FilteredClustersPropsType,
  InitialIndexPropsType,
  PageHashPropsType,
  CounterPropsType,
} from './typings';

/**
 * Получение отфильтрованных кластеров.
 * @param props - пропсы
 * @param props.clusterFeed – массив id кластеров;
 * @param props.topicClusterIds – массив id кластеров форматных топиков;
 * @param props.entries – массив всех полученнных кластеров.
 */
export const getFilteredClusters = ({
  clusterFeed,
  topicClusterIds,
  entries,
}: FilteredClustersPropsType): (Card | Cluster)[] => {
  const clusterIds = topicClusterIds.filter(
    (clusterId) => !clusterFeed.includes(clusterId),
  );

  return clusterIds
    .slice(0, MAX_RECOMMENDED_CLUSTERS_COUNT)
    .map((clusterId) =>
      entries.find(({ id: entrieId }) => clusterId === entrieId),
    ) as (Card | Cluster)[];
};

/**
 * Получение hash из url.
 */
const getPageHash = () => {
  if (!__BROWSER__) {
    return null;
  }

  return window.location.hash.slice(1);
};

/**
 * Вычисление начального слайда.
 * @param props - пропсы
 * @param props.clusterIndex - индекс кластера в бесконечном скролле;
 * @param props.slidesConfigList - массив конфигруций сплитов.
 */
export const getInitialIndex = ({
  clusterIndex = 0,
  slidesConfigList,
}: InitialIndexPropsType) => {
  const isFirstCluster = clusterIndex === 0;

  if (isFirstCluster) {
    const pageHash = getPageHash();

    if (pageHash) {
      const slideIndex = slidesConfigList.findIndex(
        (slideObj) => slideObj.hash === pageHash,
      );
      const isSlideExists = slideIndex !== -1;

      if (isSlideExists) {
        return slideIndex;
      }
    }
  }

  return FIRST_SLIDE_INDEX;
};

/**
 * Добавляет хэш слайда в url hash.
 * @param props - пропсы
 * @param props.hash - хэш слайда;
 * @param props.pathname - путь страницы;
 * @param props.search - параметры запроса;
 * @param props.replace - функция добавления хэша.
 */
export const setPageHash = ({
  hash,
  pathname,
  search,
  replace,
}: PageHashPropsType) => {
  replace(`${pathname}${search}#${hash}`);
};

/**
 * Получение дробного счетчика.
 * @param props - пропсы
 * @param props.slideIndex - индекс слайда;
 * @param props.galleryLength - длина галереи у кластера.
 */
export const getCounter = ({ slideIndex, galleryLength }: CounterPropsType) =>
  `${slideIndex}/${galleryLength}`;
