import { EntityPropsType } from '@media-components/draft-parser';
import React, { memo } from 'react';

import { VideoPlatform, VideoPlatformDataPropsType } from '../..';

/**
 * Небольшая обертка для видеотрансляций.
 * @param props - пропсы
 * @param props.children - дочерний компонент;
 * @param props.data - объект со специфичными данными для блока;
 * @param props.draftKey - ключ драфта;
 * @param props.outerProps - внешние пропсы.
 */
const VideoPlatformLiveComponent = function VideoPlatformLive({
  children,
  data,
  draftKey,
  outerProps,
}: EntityPropsType<VideoPlatformDataPropsType, unknown>) {
  return (
    <VideoPlatform
      data={data}
      draftKey={draftKey}
      isBroadcast
      outerProps={outerProps}
    >
      {children}
    </VideoPlatform>
  );
};

export const VideoPlatformLive = memo(VideoPlatformLiveComponent);
