import { blockGenerator, BlockPropsType } from '@media-components/draft-parser';
import _uniqueid from 'lodash.uniqueid';

import { CUSTOM_BLOCK_TYPE } from 'common/components/ClusterContentOnDraft/typings';

export type ShortSummaryDataType = {
  block: BlockPropsType;
};

/**
 * Блок с коротким описанием кластера.
 * @param props - пропсы
 * @param props.block – данные блока из драфта.
 */
export const shortSummaryBlockGenerator = ({ block }: ShortSummaryDataType) =>
  blockGenerator({
    ...block,
    key: `${CUSTOM_BLOCK_TYPE.ShortSummary}_${_uniqueid()}`,
    type: CUSTOM_BLOCK_TYPE.ShortSummary,
  });
