import { selectCommonData } from '../selectors';

import { travelCountriesAdapter } from './adapter';

const selectors = travelCountriesAdapter.getSelectors(
  (state: AppState) => selectCommonData(state).travelCountries,
);

export const selectTravelCountries = (state: AppState) =>
  selectCommonData(state).travelCountries;

export const selectTravelCountryByAlias =
  (alias: TravelRegionType['alias']) => (state: AppState) =>
    selectors.selectById(state, alias);

export const selectTravelCountryNameByAlias =
  (alias: TravelRegionType['alias']) => (state: AppState) =>
    selectTravelCountryByAlias(alias)(state)?.name;

export const selectAllTravelCountries = (state: AppState) =>
  selectors.selectAll(state);

export const selectTravelCountryAliases = (state: AppState) =>
  selectTravelCountries(state).ids;

export const selectTravelCountryShort =
  (alias: TravelRegionType['alias']) => (state: AppState) =>
    selectTravelCountryByAlias(alias)(state).short;

export const selectTravelCountryName =
  (alias: TravelRegionType['alias']) => (state: AppState) =>
    selectTravelCountryByAlias(alias)(state).name;
