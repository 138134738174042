import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { PROJECT_ALIASES } from 'config/common/project/typings';
import { PAGE_TYPE } from 'config/common/router/typings';

type StateEditorsType = {
  puids: PuidsType;
  editorsIds: FullEditorType['id'][];
} & InitialState;

const editorsSlice = createSlice({
  name: PAGE_TYPE.editors,
  initialState: {
    puids: {},
    editorsIds: [],
    fetching: false,
  } as StateEditorsType,
  reducers: {
    /**
     * Генерация puids для страницы.
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload - alias проекта, на котором запускается приложение.
     */
    setEditorsPuids: (state, { payload }: PayloadAction<PROJECT_ALIASES>) => {
      const puidName = getPuidName(payload);

      state.puids = {
        puid6: `${puidName}_feed`.toUpperCase(),
        puid18: `${puidName}_feed_main`.toUpperCase(),
      };
    },

    /**
     * Айдишники редакторов/авторов доступные для отображения.
     * @param state - состояние слайса
     * @param action - action сеттера
     * @param action.payload - массив айдишников редакторов/авторов.
     */
    setEditorsIds: (
      state,
      { payload }: PayloadAction<FullEditorType['id'][]>,
    ) => {
      state.editorsIds = payload;
    },
  },
});

export const editorsReducer = editorsSlice.reducer;

export const { setEditorsPuids, setEditorsIds } = editorsSlice.actions;
