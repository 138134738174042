import { selectCommonData } from '../selectors';

import { autotagsAdapter, DEFAULT_AUTOTAG } from './adapter';

const selectors = autotagsAdapter.getSelectors(
  (state: AppState) => selectCommonData(state).autotags,
);

export const selectAutotags = (state: AppState) =>
  selectCommonData(state).autotags;

export const selectAutotagById =
  (autotagId: ATAutotag['id']) => (state: AppState) =>
    selectors.selectById(state, autotagId) ?? DEFAULT_AUTOTAG;

export const selectAutotagIdByAlias =
  (autotagAlias: ATAutotag['alias']) => (state: AppState) =>
    selectAutotags(state).aliasToId[autotagAlias] ?? 0;

export const selectAutotagDataByAlias =
  (autotagAlias: ATAutotag['alias']) => (state: AppState) =>
    selectAutotagById(selectAutotagIdByAlias(autotagAlias)(state))(state);

export const selectAutotagDisplayNameById =
  (autotagId: ClusterResourceType['id'] | null) => (state: AppState) =>
    autotagId ? selectAutotagById(autotagId)(state).displayName : '';

export const selectAutotagsLoadingState = (state: AppState) =>
  selectAutotags(state).fetching;
