import { createSelector } from '@reduxjs/toolkit';

import {
  selectTopicById,
  selectTopicNewsIds,
} from 'common/redux/commonData/topics/selectors';
import { selectLoadedRecommendedClusters } from 'common/redux/commonData/widgets/recommendationWidget/selectors';
import { selectWidgetsTopMainClusterIDs } from 'common/redux/commonData/widgets/topMainWidget/selectors';
import { selectWidgetsTopProjectClusterIDs } from 'common/redux/commonData/widgets/topProjectWidget/selectors';
import {
  selectClusterPage,
  selectClusterPageClusterFeed,
  selectClusterPageCurrentClusterId,
  selectClusterPageTopicId,
} from 'common/redux/pages/cluster/selectors';
import { selectTopicPageTopicId } from 'common/redux/pages/topic/selectors';
import {
  selectProjectId,
  selectGigaEnabled,
  selectIsHumanCenteredStrategyStateActivated,
} from 'common/redux/runtime/selectors';
import { EUR_ID, USD_ID } from 'config/common/finance/constants';
import { PROJECT_IDS } from 'config/common/project/typings';
import { PAGE_TYPE } from 'config/common/router/typings';
import {
  CONVERTER_ID,
  CROSS_COURSE_ID,
  EXCHANGE_COURSE_ID,
  FORECAST_COURSE_ID,
  CURRENCIES_COURSES_ID,
  DOLLAR_COURSE_ID,
  EURO_COURSE_ID,
  GUIDE_ID,
  TOPIC_ALIAS_OTHER,
  IS_FORMAT_TOPIC,
} from 'config/common/topic/constants';

import { selectPageName, selectVideoAdById } from '../appController/selectors';
import {
  selectCardById,
  selectClusterById,
  selectClusterTopics,
} from '../commonData/entries/selectors';
import { selectItemsById } from '../commonData/items/selectors';
import { selectRegionName } from '../commonData/regions/selectors';
import { selectResourceById } from '../commonData/resources/selectors';
import { StateTopicType } from '../commonData/topics/typings';
import { selectCurrencyPageCurrentCurrencyId } from '../pages/currency/selectors';
import { selectRegionPageRegionId } from '../pages/region/selectors';

import { PUIDS_SELECTORS_BY_PAGENAME } from './config';

const PAGES_FOR_GUID_TOPIC_ID = [
  PAGE_TYPE.guide,
  PAGE_TYPE.guideCity,
  PAGE_TYPE.guideCityPlaces,
  PAGE_TYPE.guideCountry,
  PAGE_TYPE.guideCountryCities,
  PAGE_TYPE.guideCountryPlaces,
  PAGE_TYPE.guidePlace,
];

// Селектор, получающий id рубрики на текущей странице рубрики или возвращающий null если рубрики на данной странице нет. Работает и для форматного топика и для старой версии стейта.
export const selectPageTopicId = (state: AppState) => {
  const pageName = selectPageName(state);
  const currentCurrency = selectCurrencyPageCurrentCurrencyId(state);

  if (pageName === PAGE_TYPE.topic) {
    return selectTopicPageTopicId(state) ?? null;
  }

  if (pageName === PAGE_TYPE.converter) {
    return CONVERTER_ID;
  }

  if (pageName === PAGE_TYPE.exchange) {
    return EXCHANGE_COURSE_ID;
  }

  if (pageName === PAGE_TYPE.cross) {
    return CROSS_COURSE_ID;
  }

  if (pageName === PAGE_TYPE.consensus) {
    return FORECAST_COURSE_ID;
  }

  if (pageName === PAGE_TYPE.currencies) {
    return CURRENCIES_COURSES_ID;
  }

  if (pageName === PAGE_TYPE.currency && currentCurrency === USD_ID) {
    return DOLLAR_COURSE_ID;
  }

  if (pageName === PAGE_TYPE.currency && currentCurrency === EUR_ID) {
    return EURO_COURSE_ID;
  }

  if (PAGES_FOR_GUID_TOPIC_ID.includes(pageName)) {
    return GUIDE_ID;
  }

  if (pageName === PAGE_TYPE.cluster || pageName === PAGE_TYPE.longread) {
    return selectClusterPageTopicId(state);
  }

  return null;
};

// Селектор, получающий объект рубрики текущий страницы или возвращающий null, если рубрики нет.
export const selectPageTopic = (state: AppState) =>
  selectPageTopicId(state)
    ? selectTopicById(selectPageTopicId(state))(state)
    : null;

// Селектор для получения пуидов в зависимости от типа страницы
export const selectPagePuids = (state: AppState): PuidsType => {
  const pageName = selectPageName(state);
  const selector = PUIDS_SELECTORS_BY_PAGENAME[pageName];

  return selector ? selector(state) : {};
};

export const selectClusterTopic = (state: AppState) => {
  const clusterPage = selectClusterPage(state);

  return selectTopicById(clusterPage.topicId)(state);
};

export const selectPageTopicName = (state: AppState) => {
  const pageName = selectPageName(state);

  switch (pageName) {
    case PAGE_TYPE.cluster:
      return selectClusterTopic(state)?.name || '';

    case PAGE_TYPE.topic:
      return selectPageTopic(state)?.name || '';

    default:
      return '';
  }
};

export const selectItemExcludedIds = (state: AppState) => {
  const projectId = selectProjectId(state);
  const clusterFeed = selectClusterPageClusterFeed(state);
  const loadedRecommendsClusters = selectLoadedRecommendedClusters(state);
  // Кластера из виджета Главное вертикали
  const topProjectClusters =
    selectWidgetsTopProjectClusterIDs(projectId)(state);
  // Кластера из виджета Главное сейчас
  const topMainClusters = selectWidgetsTopMainClusterIDs(state);

  return [
    ...clusterFeed,
    ...topProjectClusters,
    ...topMainClusters,
    ...loadedRecommendsClusters,
  ];
};

export const selectIsGigaChatShow = (state: AppState) => {
  const projectId = selectProjectId(state);
  const pageName = selectPageName(state);
  const gigaEnabled = selectGigaEnabled(state);
  const forceRedesign = selectIsHumanCenteredStrategyStateActivated(state);

  return (
    forceRedesign &&
    projectId === PROJECT_IDS.News &&
    pageName === PAGE_TYPE.home &&
    gigaEnabled
  );
};

export const selectClusterMainTopic =
  (clusterId: Card['id'] | null) => (state: AppState) =>
    selectTopicById(selectClusterById(clusterId)(state)?.mainTopicId)(state);

export const selectClusterSortedTopics =
  (clusterId: Card['id']) => (state: AppState) => {
    const topics = selectClusterTopics(clusterId)(state) || [];
    let topicsData = topics || ([] as StateTopicType[]);

    if (topicsData.length <= 1) {
      return topicsData;
    }

    const cluster = selectClusterById(clusterId)(state);
    // Главная рубрика будет первой в списке
    const mainTopic = selectClusterMainTopic(clusterId)(state);

    /*
     * Не выводим рубрику "Другое" при наличии других рубрик
     * Удаляем главную рубрику чтобы отсортировать остальные
     */
    topicsData = topicsData.filter(
      (topic) =>
        topic?.alias !== TOPIC_ALIAS_OTHER &&
        topic?.alias !== cluster?.topicAlias,
    );

    // Сначала тематические, потом форматные
    topicsData.sort((a: StateTopicType, b: StateTopicType) => {
      const isAFormat = IS_FORMAT_TOPIC[a.alias];
      const isBFormat = IS_FORMAT_TOPIC[b.alias];

      if (isAFormat === isBFormat) {
        return 0;
      }

      if (isAFormat) {
        return 1;
      }

      return -1;
    });

    // Возвращаем главную рубрику в начало списка (кроме рубрики Другое)
    if (cluster?.topicAlias !== TOPIC_ALIAS_OTHER && mainTopic)
      topicsData.unshift(mainTopic);

    return topicsData;
  };

export const selectClusterMoreItems =
  (clusterId: Card['id']) => (state: AppState) => {
    const itemIds = selectClusterById(clusterId)(state)?.itemIds;

    return itemIds?.map((id) => {
      const itemData = selectItemsById(id)(state);
      const resource = selectResourceById(itemData?.resourceId)(state);

      return { ...itemData, resource } as ClusterItemType & {
        resource: ClusterResourceType;
      };
    });
  };

export const selectTopicByClusterId =
  (clusterId: Card['id'] | null) => (state: AppState) => {
    const cluster = selectCardById(clusterId)(state);
    // после полного переезда на новый стор можно будет удалить условие
    if (!cluster?.id) return {} as StateTopicType;

    return selectTopicById(
      // @ts-expect-error: ¯\_(ツ)_/¯
      cluster?.mainTopicId || (cluster as Cluster)?.topicIds?.[0] || '',
    )(state);
  };

export const selectTopicNews =
  (topicId: StateTopicType['id'] | undefined) => (state: AppState) =>
    selectTopicNewsIds(topicId)(state)
      .map((id) => selectCardById(id)(state))
      .filter(Boolean) as (Card | Cluster)[];

export const selectClustersWithTopics = createSelector(
  [
    (state: AppState) => state,
    (_state: AppState, clusterIds: Card['id'][]) => clusterIds,
  ],
  (state, clusterIds) => {
    const clusters = [] as (Cluster & { topicData: StateTopicType })[];

    clusterIds.forEach((clusterId) => {
      const cluster = selectClusterById(clusterId)(state);
      const topicId = cluster?.mainTopicId || cluster?.topicIds?.[0];
      const topicData = topicId ? selectTopicById(topicId)(state) : null;

      if (cluster && topicData) {
        clusters.push({ ...cluster, topicData });
      }
    });

    return clusters;
  },
);

export const selectClusterPageCurrentClusterCardData = (state: AppState) =>
  selectClusterById(selectClusterPageCurrentClusterId(state))(state);

export const selectVideoAd = (state: AppState) => {
  const projectId = selectProjectId(state);

  return selectVideoAdById(projectId)(state);
};

export const selectRegionNameByRegionPage = (state: AppState) => {
  const regionId = selectRegionPageRegionId(state);
  if (!regionId) return null;

  return selectRegionName(regionId)(state) ?? null;
};
