import cn from 'classnames';
import React, { ComponentProps, useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { RamblerAd } from 'common/components/RamblerComponents/RamblerAd';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { selectPageName, selectReloadKey } from 'common/redux/appController';
import { selectDisableAdv } from 'common/redux/appController/selectors';
import {
  selectBanners,
  selectIsDebug,
  selectIsHumanCenteredStrategyStateActivated,
  selectIsMobile,
  selectProjectAlias,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import { BANNERS } from 'config/common/banners/typings';
import { getDeviceType } from 'config/common/devices';
import { PROJECT_IDS } from 'config/common/project/typings';
import {
  PUID_54_DEFAULT,
  PUID_54_REDESIGN,
} from 'config/common/puids/constants';
import { PAGE_TYPE } from 'config/common/router/typings';
import { metricsBatch } from 'server/collectors/prometheus/utils/metricsBatch';
import { COUNTERS_NAMES } from 'server/typings';

import {
  BANNERS_REQUIRED_FINANCE_DESKTOP,
  BANNERS_REQUIRED_FINANCE_MOBILE,
  BANNERS_WITH_MARGIN_BOTTOM,
  BANNER_PRELOAD_HEIGHT,
} from './config';
import { getBannerPuids } from './hooks/useBannerPuids';
import { useMisc } from './hooks/useMisc';
import { useViewType } from './hooks/useViewType';
import { getMarginPercent } from './utils';

import s from './styles.module.css';

type AdPropsType = {
  puids: PuidsType | undefined;
  name: BANNERS;
  idPrefix?: string;
  preloadHeight?: number;
  loadedStyle?: React.CSSProperties;
  loadedClassName?: string;
  style?: React.CSSProperties;
  className?: string;
  withoutMarginBottom?: boolean;
  disablePlaceholder?: boolean;
  bannerReplacementSlot?: React.ReactNode;
  isLazy?: boolean;
  isSticky?: boolean;
  onComplete?: (result: AdResultType) => void;
  onClick?: ComponentProps<typeof RamblerAd>['onClick'];
};

/**
 * Обертка над рекламным компонентом
 * @param props - пропсы
 * @param props.puids - объект пуидов (настройкой рекламы), компонент мержит их с пуидами в конфиге баннеров;
 * @param props.name - alias баннера (listing1 и др.);
 * @param props.preloadHeight - высота плейсхолдера при старте отрисовки баннера;
 * @param props.idPrefix - Кастомный префикс для id;
 * @param props.style - стили компонента для рекламы;
 * @param props.className - дополнительные классы для рекламы;
 * @param props.loadedStyle - Стили, которые применятся к контейнеру баннера после загрузки;
 * @param props.loadedClassName - дополнительный класс для плейсхолдера после загрузки;
 * @param props.withoutMarginBottom - флаг, что у рекламы будет не будет рисоваться нижний отступ по-умолчанию;
 * @param props.disablePlaceholder - дополнительный флаг, что плейсхолдер надо отключить;
 * @param props.bannerReplacementSlot - слот для вывода при отсутствии рекламного баннера;
 * @param props.isLazy - флаг отрисовки баннера по доскроллу;
 * @param props.isSticky - залипание при обратном скролле;
 * @param props.onComplete - Колбек, будет вызван после загрузки баннера (после резолва промиса Adf.banner.*);
 * @param props.onClick - Колбек, будет вызван после клика на баннер (нужен для отправки целей в яндекс метрику).
 */
const AdComponent = function Ad({
  puids,
  name,
  preloadHeight,
  idPrefix,
  style,
  className = '',
  loadedStyle,
  loadedClassName,
  withoutMarginBottom = false,
  disablePlaceholder = false,
  bannerReplacementSlot = null,
  isLazy = true,
  isSticky,
  onComplete,
  onClick,
}: AdPropsType) {
  const isMobile = useSelector(selectIsMobile);
  const { banners } = useSelector(selectBanners, shallowEqual);
  const projectId = useSelector(selectProjectId);
  const projectAlias = useSelector(selectProjectAlias);
  const reloadKey = useSelector(selectReloadKey);
  const disableAdv = useSelector(selectDisableAdv);
  const isDebug = useSelector(selectIsDebug);
  const pageName = useSelector(selectPageName);
  const forceRedesign = useSelector(
    selectIsHumanCenteredStrategyStateActivated,
  );
  const isRedesignPuid54 =
    forceRedesign &&
    [PAGE_TYPE.cluster, PAGE_TYPE.home, PAGE_TYPE.topic].includes(pageName);

  const bannerBegun = banners?.[name]?.begun;

  const loadedCallback = useCallback(
    (result: AdResultType = {}) => {
      const deviceType = getDeviceType(isMobile);

      // Batch рекламы
      metricsBatch.pushToCounters<COUNTERS_NAMES.Ad>({
        counterName: COUNTERS_NAMES.Ad,
        params: {
          deviceType,
          adName: name,
          successful: `${result.status}`,
          reason: typeof result.reason !== 'string' ? undefined : result.reason,
        },
      });
    },
    [isMobile, name],
  );

  useViewType(name);

  const misc = useMisc();

  const isProjectFinance = projectId === PROJECT_IDS.Finance;

  const isBannerRequiredFinance = isMobile
    ? BANNERS_REQUIRED_FINANCE_MOBILE[name]
    : BANNERS_REQUIRED_FINANCE_DESKTOP[name];

  const handleComplete = useCallback(
    (result: AdResultType) => {
      loadedCallback(result);
      onComplete?.(result);
    },
    [loadedCallback, onComplete],
  );

  const placeholderHeight = preloadHeight ?? BANNER_PRELOAD_HEIGHT[name];

  // Ограничение на рендер, если страница ещё не отрендерилась
  if (!bannerBegun) return null;

  if ((!puids || !Object.values(puids)) && __DEV__) {
    console.error(`У баннера ${name} отсутствуют пуиды`);
  }

  return (
    <RamblerAd
      isDebug={isDebug}
      isLazy={isLazy}
      id={Number(bannerBegun)}
      reloadKey={reloadKey}
      style={{ ...style }}
      preloadHeight={disablePlaceholder ? 0 : placeholderHeight}
      preloadClassName={s.placeholder}
      loadedStyle={loadedStyle}
      loadedClassName={loadedClassName}
      className={cn(className, s.root, s[name], {
        [s.hidden]: disableAdv,
        [s.marginBottom]:
          BANNERS_WITH_MARGIN_BOTTOM[name] && !withoutMarginBottom,
        [s.isSticky]: isSticky,
      })}
      options={getBannerPuids({
        projectAlias,
        isMobile,
        ...(puids || {}),

        /** Насыщение специфическими для баннера пуидами */
        ...(banners?.[name]?.data || {}),

        /** Хардкод пуидов для директорского кластера */
        ...(banners?.[name]?.dir1 || {}),

        /** Пуиды для настройки рекламщиками дизайнов рекламы */
        ...(isRedesignPuid54 ? PUID_54_REDESIGN : PUID_54_DEFAULT),
      })}
      idPrefix={idPrefix}
      misc={misc}
      onComplete={handleComplete}
      customRootMarginPercent={
        isProjectFinance && isBannerRequiredFinance
          ? getMarginPercent(name)
          : undefined
      }
      isDeferred={isProjectFinance && isBannerRequiredFinance}
      onClick={onClick}
    >
      {!disableAdv ? bannerReplacementSlot : null}
    </RamblerAd>
  );
};

export const Ad = withErrorBoundary(AdComponent, true);
