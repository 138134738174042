import { createAsyncThunk } from '@reduxjs/toolkit';

import { getBankObjects } from 'api';
import { getApiBanksObjectsData } from 'api/adapters';
import { BankObjects } from 'api/typings/common';
import { selectApiConfig } from 'common/redux/runtime/selectors';

import {
  selectBanksPageBankCode,
  selectBanksPageCurrentPage,
  selectBanksPageRegion,
  selectBanksPageTab,
} from './selectors';
import { BankObjectsType } from './typings';
import { fixUnsafeSymbolsInData } from './utils';

const LIMIT = 15;

type FetchBankObjectsTypeProps = {
  bankCode: string;
  page?: number;
  limit?: 1 | 10 | 15 | 20 | 30 | 40 | 50;
  region?: string;
  objectType?: BankObjects;
};

/**
 * Асинхронная функция для получения данных об отделениях банка.
 * @param bankCode - имя банка для запроса;
 * @param page - страница запроса;
 * @param limit - колво данных, выдаваемых в одном запросе;
 * @param region - регион, по которому выдаются данные;
 * @param objectType - тип отделений банка, которые надо выдавать.
 *  Если не указан, то выдаются все отделения.
 */
const fetchBankObjects = createAsyncThunk(
  'fetchBankObjects',
  async (
    { bankCode, page, limit, region, objectType }: FetchBankObjectsTypeProps,
    { getState },
  ) => {
    const apiConfig = selectApiConfig(getState() as AppState);

    const {
      data: bankObjectsData,
      error,
      hasNextPage,
    } = await getBankObjects(apiConfig, bankCode, {
      page,
      limit,
      region,
      objectType,
    });

    if (!bankObjectsData || error) {
      throw new Error(
        `Не удалось получить данные при запросе по коду ${bankCode}`,
      );
    }

    return {
      data: getApiBanksObjectsData(bankObjectsData),
      hasNextPage,
    };
  },
);

type FetchBankObjectsForBanksPagePropsType = {
  appendNewData?: boolean;
  bankCode?: string;
  region?: string;
};

/**
 * Метод для запроса данных об отделениях банков
 * для страницы банка.
 * Этот метод - архитектурная граница, отделяющая сущность отделений банка
 * от страницы банка.
 * Если понадобится перенести код отделений банка куда-то ещё,
 * из изменений потребуется лишь перенести метод @see fetchBankObjects.
 * Пожалуйста, не загрязняйте метод @see fetchBankObjects информацией
 * о странице.
 * @param appendNewData - флаг, что данные не должны перетираться
 *  при каждой загрузке. Используется в рамках слайса, а не асинка;
 * @param forceBankCode - принудительный код банка.
 *  Помогает, если в сторе ещё нет данных о банке, но у вас есть его код.
 * @param forceRegion - принудительный регион.
 *  Помогает, если в сторе нет ещё данных о регионе.
 */
export const fetchBankObjectsForBanksPage = createAsyncThunk(
  'fetchBankObjectsForBanksPage',
  async (
    {
      bankCode: forceBankCode,
      region: forceRegion,
    }: FetchBankObjectsForBanksPagePropsType,
    { getState, dispatch },
  ) => {
    const state = getState() as AppState;

    const page = selectBanksPageCurrentPage(state);
    const tab = selectBanksPageTab(state);
    const region = forceRegion ?? selectBanksPageRegion(state);
    const bankCode = forceBankCode ?? selectBanksPageBankCode(state);

    if (!bankCode) {
      throw new Error(
        `Данные о банке ${bankCode} по региону ${region} нельзя получить`,
      );
    }

    const asyncResult = await dispatch(
      fetchBankObjects({
        bankCode,
        page,
        objectType: tab === 'all' ? undefined : tab,
        region,
        limit: LIMIT,
      }),
    );

    const bankObjectsData = asyncResult.payload as {
      data: BankObjectsType[];
      hasNextPage: boolean;
    };

    return {
      data: fixUnsafeSymbolsInData(bankObjectsData.data),
      hasNextPage: bankObjectsData.hasNextPage,
    };
  },
);
