import { BANNERS } from 'config/common/banners/typings';

/**
 * Функция высчитывания отступа для рекламы.
 * @param name - название баннера.
 */
export const getMarginPercent = (name: BANNERS) => {
  if (name === BANNERS['100x70']) {
    return 0.8;
  }

  return 0.25;
};
