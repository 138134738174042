import { selectCardById } from 'common/redux/commonData/entries/selectors';

import { selectPages } from '../selectors';

export const selectEditor = (state: AppState) => selectPages(state).editor;

export const selectEditorId = (state: AppState) => selectEditor(state).id;

export const selectEditorPuids = (state: AppState) => selectEditor(state).puids;

export const selectEditorClustersIds = (state: AppState) =>
  selectEditor(state).clustersIds;

export const selectEditorFetchingState = (state: AppState) =>
  selectEditor(state).fetching;

export const selectEditorErrorState = (state: AppState) =>
  selectEditor(state).error;

export const selectEditorClusters = (state: AppState) =>
  selectEditorClustersIds(state).map((clusterId) =>
    selectCardById(clusterId)(state),
  ) || [];
